import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import CopyToClipboard from '../CopyToClipboard';
import HighlightedElement from './HighlightedElement';

export const useStyles = makeStyles((theme: Theme) => ({
    header: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
}));

interface CardTitleHeaderProps {
    highlightData?: HighlightRecord;
    jobConfigName?: string;
    jobConfigVersion?: number;
    jobConfigId?: string;
    jobConfigDesc?: string;
    renderActions?: () => JSX.Element;
    extraElements?: JSX.Element;
    onClick: () => void;
}

const CardTitleHeader = (props: CardTitleHeaderProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={classes.header} display="flex" alignItems="flex-start">
            {props.renderActions && props.renderActions()}

            <Box className={classes.title} onClick={props.onClick}>
                <Box>
                    <Typography variant="h6" display="inline">
                        <HighlightedElement
                            value={props.jobConfigName}
                            fieldName="jobConfigName"
                            highlightData={props.highlightData}
                        />
                    </Typography>
                    &nbsp;
                    {props.jobConfigVersion && (
                        <Typography variant="body2" color="textSecondary" component="span">
                            (v.{props.jobConfigVersion})
                        </Typography>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant="body2" color="textSecondary" component="span">
                        <HighlightedElement
                            value={props.jobConfigId}
                            fieldName="jobConfigId"
                            highlightData={props.highlightData}
                        />
                        &nbsp;
                        {props.jobConfigId && (
                            <CopyToClipboard
                                tooltip="Copy Job Config Id"
                                text2Copy={props.jobConfigId}
                                size="inherit"
                            />
                        )}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    {props.extraElements}
                </Box>
                {props.jobConfigDesc && (
                    <Box fontStyle="italic">
                        <Typography variant="body2" color="textSecondary">
                            <HighlightedElement
                                value={props.jobConfigDesc}
                                fieldName="jobConfigDesc"
                                highlightData={props.highlightData}
                            />
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default CardTitleHeader;
