import React, { useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { JobFilteredDuplicateSearchDoc, JobFilteredDuplicateSearchDocRaw } from '@webacq/wa-shared-definitions';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import HumioLink from './HumioLink';
import CardTitle from './CardTitle';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import RunTreeLink from './RunTreeLink';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import { usePaginatedQuery } from '../PaginatedTable/usePaginatedQuery';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DocTypes, QueryStrings } from '../../shared/constants';
import { formatTimestamp } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface JobDuplicateDeliveriesCardProps {
    jobConfigId: string;
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    onUrlClick?: (url: string) => void;
}

const JobDuplicateDeliveriesCard = (props: JobDuplicateDeliveriesCardProps): JSX.Element => {
    const classes = useStyles();

    const [selectedJobMode] = useQueryParam(QueryStrings.JOB_MODE, withDefault(StringParam, ''));

    const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'bbdsPublish', desc: true }]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const query = `job_config_id_s:${props.jobConfigId} ${selectedJobMode ? 'AND job_mode_s:' + selectedJobMode : ''}`;

    const {
        data: rows,
        numFound,
        noDataAvailable,
        isBusy,
    } = usePaginatedQuery<JobFilteredDuplicateSearchDocRaw, JobFilteredDuplicateSearchDoc>(
        query,
        DocTypes.JOB_DUP,
        filters,
        sorting,
        pagination
    );

    const columns = [
        { header: 'Job Config Version', accessorKey: 'jobConfigVersion', size: 20 },
        { header: 'Job Mode', accessorKey: 'jobMode', size: 30 },
        { header: 'Run Location', accessorKey: 'jobRunLocation' },
        { header: 'BBDS Transaction Id', accessorKey: 'bbdsTranId' },
        {
            header: 'Published',
            accessorKey: 'bbdsPublish',
            filterVariant: 'date',
            accessorFn: (rowData) => formatTimestamp(rowData.bbdsPublish),
        },
        {
            header: 'Run Tree',
            id: 'runTree',
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: function runTreeRender(rowData) {
                return rowData.runId && <RunTreeLink id={rowData.runId} />;
            },
        },
        {
            header: 'Humio',
            id: 'humio',
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: function humioLinkRender(rowData) {
                return (
                    <HumioLink
                        logConfigName="crawlWorker"
                        query={rowData.runId || ''}
                        startTime={rowData.jobStartTime}
                        logRetentionInDays={30}
                    />
                );
            },
        },
    ] as PaginatedTableColumn<JobFilteredDuplicateSearchDoc>[];

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Duplicate Deliveries" recordCount={numFound} collapsedMode={props.collapsed} />}
        >
            <Box hidden={noDataAvailable}>
                <PaginatedTable
                    columns={columns}
                    rows={rows}
                    totalCount={numFound}
                    filters={filters}
                    sorting={sorting}
                    pagination={pagination}
                    onUpdateFilters={(filters) => {
                        setFilters(filters);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdateSorting={(sorting) => {
                        setSorting(sorting);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdatePagination={setPagination}
                    controlled={true}
                    isBusy={isBusy}
                    hideLastPageButton
                />
            </Box>

            <Box hidden={!noDataAvailable} className={classes.contentBox} fontStyle="italic">
                <Typography variant="body2">No duplicate deliveries found</Typography>
            </Box>
        </CollapsibleCard>
    );
};

export default JobDuplicateDeliveriesCard;
