import React from 'react';
import { Paper, Box } from '@mui/material';
import { PostProcessingSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatTimestamp, launchNSN, launchUrl } from '../../utils';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';
import HighlightedElement from './HighlightedElement';
import { useStyles } from './commonStyles';

const explicitKeys = [
    'jobConfigName',
    'jobConfigId',
    'jobMode',
    'runId',
    'storySuid',
    'storySourceUrl',
    'storyHeadline',
    'storyBody',
];

export type PostProcessingSearchDocCardProps = {
    postproc: PostProcessingSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const PostProcessingSearchDocCard = (props: PostProcessingSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} post-processing-search-card`}>
            <CardTypeHeader type={DocTypes.POST_PROCESSING} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.postproc.jobConfigName}
                jobConfigId={props.postproc.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu jobConfigId={props.postproc.jobConfigId} jobMode={props.postproc.jobMode} />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'SUID:',
                        value: (
                            <a
                                className={classes.link}
                                onClick={(): void => {
                                    launchNSN(props.postproc.storySuid);
                                }}
                            >
                                <HighlightedElement
                                    value={props.postproc.storySuid}
                                    fieldName="storySuid"
                                    highlightData={props.highlightData}
                                />
                            </a>
                        ),
                        searchKey: 'storySuid',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Source Url:',
                        value: (
                            <a
                                className={classes.link}
                                onClick={(): void => {
                                    launchUrl(props.postproc.storySourceUrl);
                                }}
                            >
                                <HighlightedElement
                                    value={props.postproc.storySourceUrl}
                                    fieldName="storySourceUrl"
                                    highlightData={props.highlightData}
                                    maxContentLen={300}
                                />
                            </a>
                        ),
                        searchKey: 'storySourceUrl',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Headline:',
                        value: props.postproc.storyHeadline,
                        searchKey: 'storyHeadline',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Body:',
                        value: props.postproc.storyBody,
                        searchKey: 'storyBody',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Acquisition Time:',
                        value: formatTimestamp(props.postproc.storyAcqTime),
                        searchKey: 'storyAcqTime',
                    }}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Job Mode:',
                        value: props.postproc.jobMode,
                        searchKey: 'jobMode',
                        nospace: false,
                    }}
                />

                <InfoField
                    content={{
                        label: 'Run Id:',
                        value: props.postproc.runId,
                        searchKey: 'runId',
                    }}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default PostProcessingSearchDocCard;
