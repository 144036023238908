import React from 'react';
import { MRT_ColumnDef, MRT_RowData, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { v4 as uuidv4 } from 'uuid';
import { SummaryArrayType } from '@webacq/wa-shared-definitions';
import { formatTimestamp } from '../../utils';

interface SummaryTableProps {
    summaries: SummaryArrayType;
    tableFilterChangedHandler: (visibleSummaries: SummaryArrayType) => void;
}

const fieldPropertyMap = {
    startTime: {
        columnName: 'Start Time',
        isNumber: false,
        titleLength: 3,
    },
    endTime: {
        columnName: 'End Time',
        isNumber: false,
        titleLength: 3,
    },
    jobRunLocation: {
        columnName: 'Location',
        isNumber: false,
        titleLength: 3,
    },
    numDeliveries: {
        columnName: '# of Deliveries',
        isNumber: true,
        titleLength: 3,
    },
    numErrors: {
        columnName: '# of Errors',
        isNumber: true,
        titleLength: 3,
    },
    numExtractionErrors: {
        columnName: '# of Extraction Errors',
        isNumber: true,
        titleLength: 3,
    },
    numHttpErrors: {
        columnName: '# of Http Errors',
        isNumber: true,
        titleLength: 3,
    },
    numJobs: {
        columnName: '# of Jobs',
        isNumber: true,
        titleLength: 3,
    },
    numRuns: {
        columnName: '# of Runs',
        isNumber: true,
        titleLength: 3,
    },
    numUrlFollowed: {
        columnName: '# of Urls Followed',
        isNumber: true,
        titleLength: 3,
    },
    numUrlSeen: {
        columnName: '# of Urls Seen',
        isNumber: true,
        titleLength: 3,
    },
    mode: {
        columnName: 'Mode',
        isNumber: false,
        titleLength: 2,
    },
    responseCode: {
        columnName: 'Response Code',
        isNumber: false,
        titleLength: 3,
    },
    bytesDownloaded: {
        columnName: 'Bytes Downloaded',
        isNumber: true,
        needAverage: true,
        titleLength: 3,
    },
    duration: {
        columnName: 'Duration',
        isNumber: true,
        needAverage: true,
        titleLength: 3,
    },
    bytesDownloadedAvg: {
        columnName: 'Avg Bytes Downloaded',
        isNumber: true,
        titleLength: 4,
    },
    durationAvg: {
        columnName: 'Avg Duration',
        isNumber: true,
        titleLength: 3,
    },
};

const includeJobRunLocation = ['zone', 'domain', 'agent', 'job'];
const includeMode = ['global', 'zone', 'domain', 'agent', 'job'];

export default function SummaryTable(props: SummaryTableProps): JSX.Element {
    const rows = React.useMemo(() => {
        if (props.summaries.length === 0) {
            return [];
        }

        return props.summaries.map((summary) => {
            const row = {
                id: uuidv4(),
                startTime: formatTimestamp(summary.startTime),
                endTime: formatTimestamp(summary.endTime),
                summary: summary,
            };
            for (const [field, value] of Object.entries(summary.metadata)) {
                let fieldValue = value;
                if (typeof fieldValue === 'object' && fieldValue != null) {
                    fieldValue = JSON.stringify(fieldValue);
                } else if (!fieldPropertyMap[field].isNumber) {
                    fieldValue = parseInt(value);
                }

                if (fieldPropertyMap[field].needAverage) {
                    row[field + 'Avg'] = Math.round(fieldValue / summary.metadata['numRuns']);
                }

                row[field] = fieldValue;
            }

            if (includeJobRunLocation.includes(summary.summaryType.type)) {
                row['jobRunLocation'] = summary.summaryType[summary.summaryType.type].jobRunLocation;
            }

            if (includeMode.includes(summary.summaryType.type)) {
                row['mode'] =
                    summary.summaryType[summary.summaryType.type] === undefined
                        ? 'unclassified'
                        : summary.summaryType[summary.summaryType.type].mode || 'unclassified';
            }

            return row;
        });
    }, [props.summaries]);

    const columns = [
        {
            header: 'Start Time',
            accessorKey: 'startTime',
        },
        {
            header: 'End Time',
            accessorKey: 'endTime',
        },
    ] as MRT_ColumnDef<MRT_RowData>[];

    const summaryType = props.summaries[0].summaryType.type;

    if (includeJobRunLocation.includes(summaryType)) {
        columns.push({
            header: 'Job Run Location',
            accessorKey: 'jobRunLocation',
        });
    }

    if (includeMode.includes(summaryType)) {
        columns.push({
            accessorKey: 'mode',
            header: fieldPropertyMap['mode'].columnName,
        });
    }

    for (const field in props.summaries[0].metadata) {
        const fieldProperty = fieldPropertyMap[field];

        if (fieldProperty.isNumber) {
            columns.push({
                header: fieldProperty.columnName,
                accessorKey: field,
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            });

            if (fieldProperty.needAverage) {
                const fieldAvg = `${field}Avg`;
                columns.push({
                    header: fieldPropertyMap[fieldAvg].columnName,
                    accessorKey: fieldAvg,
                    muiTableHeadCellProps: {
                        align: 'right',
                    },
                    muiTableBodyCellProps: {
                        align: 'right',
                    },
                });
            }
        } else {
            columns.push({
                header: fieldProperty.columnName,
                accessorKey: field,
            });
        }
    }

    const table = useMaterialReactTable({
        columns: columns,
        data: rows || [],
        layoutMode: 'semantic',
        enableTopToolbar: false,
        enableColumnResizing: true,
        columnFilterDisplayMode: 'popover',
        initialState: {
            density: 'compact',
            showColumnFilters: true,
            sorting: [
                {
                    id: 'startTime',
                    desc: true,
                },
            ],
        },
        enableTableFooter: false,
        enablePagination: false,
        enableStickyHeader: true,
        muiTableContainerProps: { sx: { height: '50vh' } },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MaterialReactTable table={table} />
        </LocalizationProvider>
    );
}
