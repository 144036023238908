import React from 'react';
import { Container, Link, Typography } from '@mui/material';

interface ICodeSample {
    display: 'initial' | 'block' | 'inline';
    text: string;
}

function CodeSample({ display, text }: ICodeSample) {
    return (
        <Typography variant="body1" display={display}>
            {text}
        </Typography>
    );
}

export default function Instructions(): JSX.Element {
    return (
        <>
            <Container>
                <Typography variant="h6">Using Hub Search</Typography>
            </Container>
            <Container>
                <Typography variant="body2">
                    Hub supports a subset of queries that are valid in{' '}
                    <Link href="https://solr.apache.org/guide/6_6/overview-of-searching-in-solr.html">
                        Apache Solr.
                    </Link>{' '}
                    This subset includes support for
                    <ul>
                        <li>Terms (searching for text) and</li>
                        <li>Tuning (combining terms)</li>
                    </ul>
                    <Typography variant="h6">Sample queries</Typography>
                    <CodeSample text={'Google'} display="block" />
                    Returns results with the term &quot;Google&quot;.
                    <CodeSample text={'Google OR Bloomberg'} display="block" />
                    Returns results with &quot;Google&quot; or &quot;Bloomberg&quot;. Other supported terms include{' '}
                    <CodeSample text={'NOT'} display="inline" /> and <CodeSample text={'AND'} display="inline" />.
                    <Typography variant="h6">Result types</Typography>
                    <ul>
                        <li>
                            <i>Job Configurations</i> are recipes that tell a crawler how to interact with a site, what
                            to deliver, and when to notify.
                        </li>
                        <li>
                            <i>Jobs</i> build on job configurations by adding a schedule, mode, and location. There
                            could be many jobs that use a given job configuration.
                        </li>
                    </ul>
                </Typography>
            </Container>
        </>
    );
}
