import React from 'react';

export interface SettingsDataType {
    theme: string;
}

const defaultSettingsData: SettingsDataType = {
    theme: 'light',
};

export interface SettingsContextType {
    settingsData: SettingsDataType;
    setSettingsData: (value: SettingsDataType) => void;
}

const SettingsContext = React.createContext<SettingsContextType>({
    settingsData: defaultSettingsData,
    setSettingsData: (data: SettingsDataType) => console.debug(data),
});

export const useSettings = (): SettingsContextType => React.useContext(SettingsContext);

interface SettingsProps {
    children: React.ReactNode;
}

const SettingsProvider = (props: SettingsProps): JSX.Element => {
    const [settingsData, setSettingsData] = React.useState<SettingsDataType>(defaultSettingsData);

    React.useEffect(() => {
        setSettingsData(defaultSettingsData);
    }, []);

    return (
        <SettingsContext.Provider value={{ settingsData, setSettingsData }}>{props.children}</SettingsContext.Provider>
    );
};

export default SettingsProvider;
