import React from 'react';
import { Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    overlay: {
        backgroundColor: `${theme.palette.background.paper}B3`,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const BusyIndicator = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.overlay}>
            <CircularProgress />
        </Box>
    );
};

export default BusyIndicator;
