import React from 'react';
import { Theme, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Content from '../components/Content';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        margin: theme.spacing(3),
    },
}));

interface ErrorProps {
    title?: string;
    message?: string;
}

const Error = (props: ErrorProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Content subtitle={props.title || 'Error'}>
            <Alert severity="error" className={classes.box}>
                {props.message}
            </Alert>
        </Content>
    );
};

export default Error;
