import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { AccountTree as RunTreeIcon } from '@mui/icons-material';
import { getJobRunRoute } from '../../utils';

interface RunTreeLinkProps {
    id: string;
}

const RunTreeLink = (props: RunTreeLinkProps): JSX.Element => {
    return (
        <Tooltip title={'View Run Tree'}>
            <RouterLink to={getJobRunRoute(props.id)} style={{ textDecoration: 'none' }}>
                <RunTreeIcon color="primary" fontSize="small" />
            </RouterLink>
        </Tooltip>
    );
};

export default RunTreeLink;
