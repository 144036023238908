import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocationSchedule, ScheduleStatusLocation } from '@webacq/wa-shared-definitions';
import { SelectedNodeData } from './RoutingHistoryGraphInterfaces';
import { formatTimestamp } from '../../../utils';
import { RunLocationMapping } from '../../../shared/constants';

const useStyles = makeStyles((theme: Theme) => ({
    popup: {
        padding: theme.spacing(1),
        borderRadius: 4,
        backgroundColor: theme.palette.grey[900],
        fontFamily: 'AvenirNextForBBG, Avenir, "Helvetica Neue", Arial, sans-serif',
        fontSize: '.8em',
    },
    table: {
        minWidth: 500,
    },
    title: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    version: {
        color: theme.palette.primary.light,
    },
    field: {
        display: 'flex',
        marginLeft: theme.spacing(1),
    },
    tableRow: {
        spacing: theme.spacing(20),
    },
}));

export default function RoutingHistoryNodeInfo({ version, type, mode, nodeData }: SelectedNodeData): JSX.Element {
    const classes = useStyles();

    const getScheduleTable = (schedules: ScheduleStatusLocation[]) => {
        const columns: Record<string, string> = {
            location: 'Run Location',
            status: 'Status',
            description: 'Schedule',
            actionOn: 'Updated',
            actionBy: 'Updated By',
        };
        const columnKeys = Object.keys(columns);
        return (
            <table>
                <thead>
                    <tr>
                        {columnKeys.map((columnKey: string) => (
                            <td key={columnKey}>
                                <b>{columns[columnKey]}</b>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {schedules.map((row: ScheduleStatusLocation, i: number) => (
                        <tr key={i}>
                            {columnKeys.map((columnKey: string, j: number) => (
                                <td key={j}>
                                    {columnKey === 'actionOn'
                                        ? formatTimestamp(row[columnKey])
                                        : columnKey === 'location'
                                        ? RunLocationMapping[row[columnKey] || ''] || row[columnKey]
                                        : row[columnKey]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const getLocations = (locationSchedule?: Record<string, LocationSchedule>) => {
        return locationSchedule
            ? Object.keys(locationSchedule).map((location, idx) => {
                  const schedule = locationSchedule[location];
                  const runLocation = RunLocationMapping[location || ''] || location;
                  return <div key={idx}>{`${runLocation} (${schedule.description}, Status: ${schedule.status})`}</div>;
              })
            : undefined;
    };

    return (
        <Box className={classes.popup}>
            {type == 'schedule' && nodeData.scheduleUpdates && nodeData.scheduleUpdates.length ? (
                <>
                    <div className={classes.title}>
                        <span className={classes.version}>
                            <b>
                                Version-{version} {mode}
                            </b>
                        </span>
                        &nbsp;
                        <i>({type} updates)</i>
                    </div>
                    <div className={classes.field}>{getScheduleTable(nodeData.scheduleUpdates || [])}</div>
                </>
            ) : (
                <>
                    <div className={classes.title}>
                        <span className={classes.version}>
                            <b>Version-{version}</b>
                        </span>
                    </div>
                    <div className={classes.field}>
                        <b>Created By:</b>&nbsp;
                        {nodeData.createdByName}
                    </div>
                    <div className={classes.field}>
                        <b>Created On:</b>&nbsp;
                        {formatTimestamp(nodeData.createdOn)}
                    </div>
                    <div className={classes.field}>
                        <b>Locations:</b>&nbsp;
                        <div>{getLocations(nodeData.locationSchedule)}</div>
                    </div>
                </>
            )}
        </Box>
    );
}

RoutingHistoryNodeInfo.propTypes = {};
