import React from 'react';
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import { HelpOutlined as HelpOutlinedIcon, Search as SearchIcon } from '@mui/icons-material';
import Instructions from '../Instructions';

interface OmniBarProps {
    initialValue?: string;
    grabFocus?: boolean;
    onEnter?: (searchterm: string) => void;
}

const OmniBar = (props: OmniBarProps): JSX.Element => {
    const [searchterm, setSearchterm] = React.useState(props.initialValue || '');
    const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(null);
    const isHelpOpen = Boolean(helpAnchorEl);

    React.useEffect(() => {
        setSearchterm(props.initialValue || '');
    }, [props.initialValue]);

    return (
        <TextField
            autoFocus={props.grabFocus}
            value={searchterm}
            placeholder="Search for..."
            variant="outlined"
            style={{ padding: '1em', width: '60%', alignSelf: 'center' }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setHelpAnchorEl(event.target as HTMLElement);
                            }}
                            size="large"
                        >
                            <HelpOutlinedIcon />
                        </IconButton>
                        <Popover
                            anchorEl={helpAnchorEl}
                            open={isHelpOpen}
                            onClose={() => setHelpAnchorEl(null)}
                            disableRestoreFocus
                        >
                            <Instructions />
                        </Popover>
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                setSearchterm(event.currentTarget.value);
            }}
            onKeyPress={(event) => {
                if (event.key === 'Enter' && props.onEnter) {
                    props.onEnter(searchterm);
                }
            }}
        />
    );
};

export default OmniBar;
