import React from 'react';
import { makeStyles } from '@mui/styles';

import {
    Ballot as BallotIcon,
    Schedule as ScheduleIcon,
    AllInbox as AllInboxIcon,
    Notifications as NotificationsIcon,
    ErrorOutline as ErrorOutlineIcon,
    FilterList as FilterListIcon,
    Error as ErrorIcon,
    DeveloperBoard as DeveloperBoardIcon,
} from '@mui/icons-material';

import { DocTypeColors, DocTypes } from '../shared/constants';

const useStyles = (color: string) =>
    makeStyles(() => ({
        icon: {
            verticalAlign: 'sub',
            color,
        },
    }));

export type DocTypeIconProps = {
    type: DocTypes;
};

const DocTypeIcon = (props: DocTypeIconProps): JSX.Element => {
    const classes = useStyles(DocTypeColors[props.type])();

    switch (props.type) {
        case DocTypes.JOB_CONFIG:
            return <BallotIcon fontSize="small" className={classes.icon} />;

        case DocTypes.JOB:
            return <ScheduleIcon fontSize="small" className={classes.icon} />;

        case DocTypes.JOB_DLV:
            return <AllInboxIcon fontSize="small" className={classes.icon} />;

        case DocTypes.ALERT_NOTIF:
            return <NotificationsIcon fontSize="small" className={classes.icon} />;

        case DocTypes.JOB_ERR:
            return <ErrorOutlineIcon fontSize="small" className={classes.icon} />;

        case DocTypes.JOB_DUP:
            return <FilterListIcon fontSize="small" className={classes.icon} />;

        case DocTypes.SYS_ERROR:
            return <ErrorIcon fontSize="small" className={classes.icon} />;

        case DocTypes.POST_PROCESSING:
            return <DeveloperBoardIcon fontSize="small" className={classes.icon} />;
    }

    return <></>;
};

export default DocTypeIcon;
