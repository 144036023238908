import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Button, Paper, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OpenIcon from '@mui/icons-material/OpenInNew';
import { config } from '../config';
import { colors } from '@webacq/wam-ui-components';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const GridLayout = WidthProvider(RGL);

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
    },
    frameWrapper: {
        background: theme.palette.background.paper,
        border: `1px solid ${colors.teal[400]}`,
        cursor: 'grab',
    },
    linkIcon: {
        height: 16,
    },
}));

const System = (): JSX.Element => {
    const classes = useStyles();

    const grafanaEnv =
        {
            LOCAL: 'sn2',
            DEV: 'sn2',
            BETA: 's0',
            PROD: 's2',
        }[config.env] || 's2';

    const iframeBase = 'https://grafana.prod.bloomberg.com/d-solo/';
    const frames = [
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=14`, // job duration (scheduled only)
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=20`, // completed jobs (scheduled only)
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=18`, // timeouts
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=22`, // started and pending jobs (scheduled + run once)
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=10`, // Ready check - number of requests
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=12`, // Ready check - Max request duration (s)
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=2`, // Health check - number of requests
        `wsYK4y_nk/crawl-worker?orgId=1&var-cluster=All&var-env=${grafanaEnv}&panelId=6`, // Health check - Max request duration (s)
    ];

    const initialLayoutConfig = [
        { w: 3, h: 4, x: 0, y: 0 },
        { w: 3, h: 4, x: 3, y: 0 },
        { w: 3, h: 4, x: 6, y: 0 },
        { w: 3, h: 4, x: 9, y: 0 },
        { w: 3, h: 4, x: 0, y: 4 },
        { w: 3, h: 4, x: 3, y: 4 },
        { w: 3, h: 4, x: 6, y: 4 },
        { w: 3, h: 4, x: 9, y: 4 },
    ];

    const initialLayout = frames.map((path, i) => ({
        i: iframeBase + path,
        ...initialLayoutConfig[i],
    }));
    const [layout, setLayout] = useState<Layout[]>(initialLayout);

    const storageKey = 'crwl-hub-system-panels';

    useEffect(() => {
        const panels = window.localStorage.getItem(storageKey);

        try {
            if (panels) {
                const parsed: Layout[] = JSON.parse(panels);
                if (panels.length !== layout.length) {
                    const loadedLayout = parsed.map((path) => {
                        const url = new URL(path.i);
                        if (url.searchParams.has('var-cluster')) {
                            url.searchParams.set('var-cluster', 'All');
                        }
                        if (url.searchParams.has('var-env')) {
                            url.searchParams.set('var-env', grafanaEnv);
                        }
                        return {
                            ...path,
                            i: url.toJSON(),
                        };
                    });
                    setLayout(loadedLayout);
                }
            }
        } catch (e) {
            console.error('Error loading layout config from localStorage', localStorage);
        }
    }, []);
    const iframeProps = { width: '100%', style: { height: 'calc(100% - 30px)' }, frameBorder: 0 };

    const handleLayoutChange = (updatedLayout: Layout[]): void => {
        setLayout(updatedLayout);
        console.debug('saving new layout', updatedLayout);
        window.localStorage.setItem(storageKey, JSON.stringify(updatedLayout));
    };

    const handleResetLayout = (): void => {
        setLayout(initialLayout);
        window.localStorage.setItem(storageKey, JSON.stringify(initialLayout));
    };

    const handleOpenPanel = (iframeUrl: string): void => {
        const url = new URL(iframeUrl);
        url.searchParams.set('viewPanel', url.searchParams.get('panelId') || '');
        url.pathname = url.pathname.replace(/d-solo/, 'd');
        window.open(url.href, '_blank');
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle="System" />
            <Box flexGrow={1} overflow="auto" height={'calc(100% - 80px)'} width={'100%'}>
                <Button style={{ marginLeft: 'auto' }} onClick={handleResetLayout}>
                    Reset Layout
                </Button>
                <GridLayout
                    className={classes.container}
                    layout={layout}
                    cols={12}
                    rowHeight={100}
                    width={2500}
                    onDragStop={handleLayoutChange}
                    onResizeStop={handleLayoutChange}
                >
                    {layout.map((data: Layout) => (
                        <Paper className={classes.frameWrapper} key={data.i}>
                            <iframe src={data.i} {...iframeProps} />
                            <IconButton size="small" color="primary" onClick={() => handleOpenPanel(data.i)}>
                                <OpenIcon className={classes.linkIcon} />
                            </IconButton>
                        </Paper>
                    ))}
                </GridLayout>
            </Box>
            <Footer year={2023} />
        </Box>
    );
};

export default withRouter(System);
