import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { darkTheme } from '@webacq/wam-ui-components';
import { QueryParamProvider } from 'use-query-params';
import Home from './pages/Home';
import Login from './pages/Login';
import Auth from './pages/Auth';
import JobConfigDetails from './pages/JobConfigDetails';
import JobInfo from './pages/JobInfo';
import JobRunInfo from './pages/JobRunInfo';
import PrivateRoute from './PrivateRoute';
import AuthProvider from './context/auth';
import SettingsProvider from './context/settings';
import AlertConfig from './pages/AlertConfig';
import HTMLDiff from './pages/HTMLDiff';
import Summary from './pages/Summary';
import System from './pages/System';
import Deployment from './pages/Deployment';
import ManualRunInfo from './pages/ManualRunInfo';
import ObjectViewer from './pages/ObjectViewer';
import NotFound from './pages/NotFound';

const App = (): JSX.Element => {
    const theme = createTheme(darkTheme);

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <SettingsProvider>
                    <CssBaseline>
                        <Router>
                            <QueryParamProvider ReactRouterRoute={Route}>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/auth" component={Auth} />
                                    <PrivateRoute exact path="/job-config/:jobConfigId" component={JobConfigDetails} />
                                    <PrivateRoute exact path="/job/:jobId" component={JobInfo} />
                                    <PrivateRoute exact path="/run/:id" component={JobRunInfo} />
                                    <PrivateRoute exact path="/manual-run/:runId" component={ManualRunInfo} />
                                    <PrivateRoute exact path="/alert-config/:jobConfigId" component={AlertConfig} />
                                    <PrivateRoute exact path="/htmldiff/:jobConfigId/:deliveryUrl" component={HTMLDiff} />
                                    <PrivateRoute exact path="/summary" component={Summary} />
                                    <PrivateRoute exact path="/system" component={System} />
                                    <PrivateRoute exact path="/deployments" component={Deployment} />
                                    <PrivateRoute exact path="/object" component={ObjectViewer} />
                                    <PrivateRoute exact path="/" component={Home} />
                                    <Route component={NotFound} />
                                </Switch>
                            </QueryParamProvider>
                        </Router>
                    </CssBaseline>
                </SettingsProvider>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
