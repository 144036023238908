import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import WAMService from './services/wamService';
import { useAuth } from './context/auth';
import Error from './pages/Error';
import { getPermissionInfo } from './utils';
import { GlobalPermissions } from './shared/constants';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: React.ElementType;
}

const PrivateRoute = ({ component: Component, ...routeProps }: PrivateRouteProps): JSX.Element | null => {
    const authentication = useAuth();

    if (!Component) {
        return null;
    }

    const authRedirect = () => {
        WAMService.authenticate(window.location.href);
        return null;
    };

    const isAuthenticated = () => {
        return authentication.authData.isAuthenticated;
    };

    const getHubAccessError = (): JSX.Element | undefined => {
        const hubAccess = getPermissionInfo(
            authentication.authData.permissions || [],
            GlobalPermissions.CAN_ACCESS_HUB
        );
        if (!hubAccess.hasAccess) {
            return <Error title="Insufficient Privileges" message={hubAccess.statusMessage} />;
        }

        return undefined;
    };

    return (
        <Route
            {...routeProps}
            render={(props) => (isAuthenticated() ? getHubAccessError() || <Component {...props} /> : authRedirect())}
        />
    );
};

export default PrivateRoute;
