import React, { useState, useEffect, useCallback } from 'react';
import { RunTree, BbgTerminalIcon } from '@webacq/wam-ui-components';
import {
    GeckoContextTree,
    GeckoModel,
    JobConfigSearchDoc,
    JobConfigSearchDocRaw,
    ManualRunSearchDoc,
    ManualRunSearchDocRaw,
    ProvenanceRecord,
} from '@webacq/wa-shared-definitions';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Alert, Box, BoxProps, LinearProgress, Tooltip } from '@mui/material';
import wamService from '../services/wamService';
import searchService from '../services/searchService';
import { getJobConfigRoute, wrapApiCall, launchMnemonic } from '../utils';
import { useAuth } from '../context/auth';
import Content from '../components/Content';
import { config } from '../config';

interface URLParams {
    runId: string;
}

const HeaderItem = (props: BoxProps) => (
    <>
        <Box flexItem component="span" {...props}>
            {props.children}
        </Box>
    </>
);

const ManualRunViewer = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [geckoGraph, setGeckoGraph] = useState<GeckoModel | null>(null);
    const [contextTree, setContextTree] = useState<GeckoContextTree | null>(null);
    const [provRecords, setProvRecords] = useState<ProvenanceRecord[]>([]);
    const [runDoc, setRunDoc] = useState<ManualRunSearchDoc | null>(null);

    const authContext = useAuth();

    const runId = props.match.params.runId.split(':')[0];

    const getProvenanceRecords = useCallback(
        async (rootStepId: string, jobStartTime: Date): Promise<ProvenanceRecord[]> => {
            try {
                const start = jobStartTime.toISOString();
                const resp = await wamService.queryProvenance({
                    rootStepId,
                    start,
                });
                return resp.records;
            } catch (e) {
                console.error(e);
            }
            return [];
        },
        [],
    );

    useEffect(() => {
        wrapApiCall(
            authContext,
            async () => {
                setIsLoading(true);

                const query = `run_id_s:${runId} AND type_s:MANUAL_RUN`;

                const result = await searchService.search<ManualRunSearchDocRaw, ManualRunSearchDoc>(
                    query,
                    'MANUAL_RUN',
                    1,
                );

                const doc = result?.data?.docs.length ? result.data.docs[0] : undefined;
                if (!doc || !doc.ctxTreeLoc) {
                    setErrorMessage('Could not find context tree.');
                    return;
                }
                setRunDoc(doc);

                const ctxTree = await wamService.fetchBcosContent(doc.ctxTreeLoc).then(res => res?.content as GeckoContextTree);
                if (!ctxTree) {
                    setErrorMessage(`Could not load context tree from BCOS, BCOS url: ${doc.ctxTreeLoc}`);
                    return;
                }
                if (!doc.jobConfigId) {
                    setErrorMessage('Could not load data fields.');
                    return;
                }

                try {
                    const children = [ctxTree];
                    while (children.length) {
                        const node = children.pop();
                        if (node?.body && typeof node.body === 'string') {
                            node.body = JSON.parse(node.body);
                        }
                        if (node?.children) {
                            children.push(...node.children);
                        }
                    }
                    setContextTree(ctxTree);
                } catch (e) {
                    console.error('Error parsing contextTree result', e);
                    setErrorMessage('Could not get context tree');
                    setContextTree(null);
                }

                const jobConfigResult = await searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                    `job_config_id_s:${doc.jobConfigId} AND job_config_version_i:${doc.jobConfigVersion}`,
                    'JOB_CFG',
                    1,
                );

                if (!jobConfigResult.data?.docs.length) {
                    setErrorMessage(`No data found for job config id ${doc.jobConfigId}`);
                    return;
                }

                try {
                    const parsed = JSON.parse(jobConfigResult.data.docs[0].graph);
                    setGeckoGraph(parsed as GeckoModel);
                } catch (e) {
                    console.error(`Error parsing gecko graph for ${doc.jobConfigId}`);
                    setGeckoGraph(null);
                }

                const records = await getProvenanceRecords(doc.provRootStepid, doc.startTime);
                setProvRecords(records);
                setErrorMessage('');
            },
            (e: Error) => {
                setErrorMessage('Error loading data');
                setContextTree(null);
                setGeckoGraph(null);
                setProvRecords([]);
                console.error(e);
            },
            () => setIsLoading(false),
        );
    }, [runId]);

    const subtitle = (
        <>
            Manual Run
            {runDoc && (
                <Box
                    display="inline-flex"
                    sx={{
                        fontSize: 16,
                        width: '100%',
                        paddingLeft: 3,
                        gap: 3,
                    }}
                >
                    <HeaderItem>
                        <b>Job Config ID: </b>
                        <Tooltip title="View job config details">
                            <Link to={getJobConfigRoute(runDoc.jobConfigId)}>{runDoc.jobConfigId}</Link>
                        </Tooltip>
                    </HeaderItem>
                    <HeaderItem>
                        <b>Version:</b> {runDoc.jobConfigVersion}
                    </HeaderItem>
                    <HeaderItem>
                        <b>Mode:</b> {runDoc.jobMode}
                    </HeaderItem>
                    <HeaderItem>
                        <b>Start Time:</b> {runDoc.startTime?.toISOString()}
                    </HeaderItem>
                    <HeaderItem>
                        <b>Triggerer: </b>
                        <a
                            onClick={() => launchMnemonic(`UUID`, runDoc.triggererBbgUuid)}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {runDoc.triggererName}{' '}
                            <BbgTerminalIcon
                                style={{
                                    fontSize: 14,
                                }}
                            />
                        </a>
                    </HeaderItem>
                </Box>
            )}
        </>
    );

    return (
        <Content subtitle={subtitle}>
            {isLoading && <LinearProgress />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {geckoGraph && contextTree && (
                <RunTree
                    contextTree={contextTree as GeckoContextTree}
                    geckoGraph={geckoGraph || {}}
                    provenanceRecords={provRecords}
                    onFetchContent={wamService.fetchBcosContent}
                    getBcosEndpoint={(bcosUrl: string) =>
                        `${config.apiUrl}/wam/ui/object?url=${window.encodeURIComponent(bcosUrl)}`
                    }
                />
            )}
        </Content>
    );
};

export default ManualRunViewer;
