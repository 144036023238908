import { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import { SearchResult } from '@webacq/wa-shared-servicewrappers';
import { createSolrFilter, createSolrSorting, wrapApiCall } from '../../utils';
import searchService from '../../services/searchService';
import { SearchDoc, SearchDocRaw } from '@webacq/wa-shared-definitions';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import { mrtToSolrFilters, mrtToSolrSort } from './paginatedTableUtils';

export interface QueryState {
    cursorMark?: Record<number, string>;
    noDataAvailable?: boolean;
    numTotalRecords?: number;
}

export function usePaginatedQuery<U extends SearchDocRaw, T extends SearchDoc>(
    query: string,
    dataset: string,
    filters: MRT_ColumnFiltersState,
    sorting: MRT_SortingState,
    pagination: MRT_PaginationState,
    extraQueryParams?: Record<string, string>
): {
    data: T[];
    numFound: number;
    noDataAvailable: boolean;
    response: SearchResult<T>;
    isBusy: boolean;
} {
    const [data, setData] = useState<T[]>([]);
    const [numFound, setNumFound] = useState(0);
    const [queryState, setQueryState] = useState<QueryState>({});
    const [response, setResponse] = useState<SearchResult<T>>({});
    const [isBusy, setIsBusy] = useState(false);
    const authContext = useAuth();

    useEffect(() => {
        (async () => {
            if (setIsBusy) {
                setIsBusy(true);
            }

            const solrFilters = mrtToSolrFilters(filters);
            const solrSorting = mrtToSolrSort(sorting);

            try {
                const apiRequest = () => {
                    return searchService.search<U, T>(
                        query + ' ' + createSolrFilter(solrFilters),
                        dataset,
                        pagination.pageSize,
                        pagination.pageIndex === 0 ? '*' : (queryState?.cursorMark || {})[pagination.pageIndex] || '*',
                        createSolrSorting(solrSorting),
                        undefined,
                        extraQueryParams
                    );
                };
                const resp = await wrapApiCall(authContext, apiRequest);

                if (resp?.error) {
                    if (queryState.noDataAvailable === undefined) {
                        // initial load
                        setQueryState({
                            noDataAvailable: false,
                        });
                    }
                    console.error(resp.error.msg);
                    return;
                }
                if (resp?.data) {
                    console.log(resp.data);
                    const newCursorMark = pagination.pageIndex === 0 ? {} : { ...queryState.cursorMark };
                    newCursorMark[pagination.pageIndex + 1] = resp.data.nextCursorMark || '';

                    setQueryState({
                        cursorMark: newCursorMark,
                        noDataAvailable:
                            queryState.noDataAvailable === undefined
                                ? resp.data.numFound === 0
                                : queryState.noDataAvailable,
                        numTotalRecords: resp.data.numFound,
                    });

                    setData(resp.data.docs || []);
                    setNumFound(resp.data.numFound || 0);
                    setResponse(resp);
                    console.debug({
                        data,
                        numFound,
                    });
                }
            } catch (e) {
                console.error(e);
            } finally {
                if (setIsBusy) {
                    setIsBusy(false);
                }
            }
        })();
    }, [pagination, filters, sorting]);

    return {
        data,
        numFound,
        noDataAvailable: !!queryState.noDataAvailable,
        response,
        isBusy,
    };
}
