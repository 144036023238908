import React from 'react';
import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors } from '@webacq/wam-ui-components';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';

export const useStyles = makeStyles((theme: Theme) => ({
    highlight: {
        backgroundColor: colors.yellow[300],
        color: theme.palette.background.paper,
        fontWeight: 'bold',
    },
}));

interface HighlightedElementProps {
    value: string | undefined;
    fieldName: string;
    highlightData?: HighlightRecord;
    maxContentLen?: number;
}

const HighlightedElement = (props: HighlightedElementProps): JSX.Element => {
    const classes = useStyles();

    let value = props.value;

    if (props.highlightData && props.highlightData[props.fieldName]) {
        const startTag = '<em>';
        const endTag = '</em>';
        const maxContentLen = Math.max(50, props.maxContentLen || 0);
        const ellipsis = ' ... ';

        const tokens: Array<{ token: string; highlight: boolean }> = [];

        value = props.highlightData[props.fieldName][0];
        //value = value.replace(/<https:~1~1bsm.bloomberg.com~1ontology~1/g, '');

        let ellipsisUsed = false;

        let startOffset = 0;
        while (startOffset < value.length) {
            const startPos = value.indexOf(startTag, startOffset);
            const endPos = value.indexOf(endTag, startPos + startTag.length);

            if (startPos !== -1 && endPos !== -1) {
                let preToken = value.substring(startOffset, startPos);
                if (startOffset == 0) {
                    if (preToken.length > maxContentLen - ellipsis.length) {
                        preToken = `${ellipsis}${preToken.substring(preToken.length - maxContentLen)}`;
                        ellipsisUsed = true;
                    }
                } else {
                    if (preToken.length > 2 * maxContentLen - ellipsis.length) {
                        preToken = `${preToken.substring(0, maxContentLen)}${ellipsis}${preToken.substring(
                            preToken.length - maxContentLen
                        )}`;
                        ellipsisUsed = true;
                    }
                }
                tokens.push({ token: preToken, highlight: false });

                tokens.push({ token: value.substring(startPos + startTag.length, endPos), highlight: true });
                startOffset = endPos + endTag.length;
            } else {
                let postToken = value.substring(startOffset);
                if (postToken.length > maxContentLen - ellipsis.length) {
                    postToken = `${postToken.substring(0, maxContentLen)}${ellipsis}`;
                    ellipsisUsed = true;
                }
                tokens.push({ token: postToken, highlight: false });
                startOffset = value.length;
            }
        }

        return (
            <Tooltip title={ellipsisUsed ? props.value : ''}>
                <span>
                    {tokens.map((token, idx) => (
                        <span key={idx} className={token.highlight ? classes.highlight : undefined}>
                            {token.token}
                        </span>
                    ))}
                </span>
            </Tooltip>
        );
    }

    return <>{value || ''}</>;
};

export default HighlightedElement;
