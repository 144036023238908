import React from 'react';
import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Drawer,
    Divider,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { Link as LinkRouter, useHistory } from 'react-router-dom';
import {
    AccountCircle,
    Menu as MenuIcon,
    Home as HomeIcon,
    Assessment as SystemIcon,
    TableChart as SummaryIcon,
} from '@mui/icons-material';
import WAMService from '../services/wamService';
import { useAuth } from '../context/auth';
import { config } from '../config';
import packageJson from '../../package.json';
import AppInfo from './AppInfo';
import CopyToClipboard from './CopyToClipboard';
import { createQuickLink } from '../utils';

interface HeaderProps {
    subtitle?: string | React.ReactElement;
    quickLinkTail?: string;
}

const Header = (props: HeaderProps): JSX.Element => {
    const authentication = useAuth();
    const history = useHistory();

    const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(profileAnchorEl);

    const [drawerAnchorEl, setDrawerAnchorEl] = React.useState<null | HTMLElement>(null);
    const isDrawerMenuOpen = Boolean(drawerAnchorEl);

    React.useEffect(() => {
        WAMService.checkAuth().then((authStatus) => {
            if (
                authentication.authData.isAuthenticated !== authStatus.isAuthenticated ||
                authentication.authData.userId !== authStatus.userId
            ) {
                authentication.setAuthData({
                    isAuthenticated: authStatus.isAuthenticated,
                    userId: authStatus.userId,
                    userName:
                        authStatus.firstName && authStatus.lastName
                            ? `${authStatus.firstName} ${authStatus.lastName}`
                            : undefined,
                    permissions: authStatus.permissions,
                });
            }
        });
    }, [authentication]);

    const handleDrawerOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setDrawerAnchorEl(event.currentTarget);
    };

    const handleDrawerClose = (): void => {
        setDrawerAnchorEl(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = (): void => {
        setProfileAnchorEl(null);
    };

    // const handleSwitchTheme = (): void => {
    //     settings.setSettingsData({
    //         theme: settings.settingsData.theme === 'dark' ? 'light' : 'dark',
    //     });
    //     handleProfileMenuClose();
    // };

    const handleLogout = (): void => {
        WAMService.logout().then(() => {
            authentication.setAuthData({ isAuthenticated: false });
        });
    };

    const renderProfileMenu = (
        <Menu
            anchorEl={profileAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
        >
            <MenuItem>{authentication.authData.userName || '<unknown user>'}</MenuItem>
            <Divider />

            {/* <MenuItem onClick={handleSwitchTheme}>Switch Theme</MenuItem> */}
            <Divider />
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );
    const renderDrawerMenu = (
        <Drawer anchor="left" open={isDrawerMenuOpen} onClose={handleDrawerClose}>
            <List style={{ width: 200 }}>
                <ListItem button component={LinkRouter} to="/">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem component={LinkRouter} to="/Summary" button>
                    <ListItemIcon>
                        <SummaryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Summary" />
                </ListItem>
                <ListItem button component={LinkRouter} to="/System">
                    <ListItemIcon>
                        <SystemIcon />
                    </ListItemIcon>
                    <ListItemText primary="System" />
                </ListItem>
            </List>
        </Drawer>
    );

    return (
        <>
            <AppBar
                position="relative"
                style={{
                    backgroundColor: '#000000',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>

                    <AppInfo
                        appTitle="CRWL HUB"
                        env={config.env !== 'PROD' ? config.env : undefined}
                        version={packageJson.version}
                        onClick={() => {
                            history.push('/');
                        }}
                    />

                    <Typography variant="h5" style={{ flexGrow: 1 }} color="textSecondary" noWrap>
                        <Box fontWeight="500" ml={4}>
                            {props.subtitle}
                        </Box>
                    </Typography>

                    {props.quickLinkTail && (
                        <CopyToClipboard
                            tooltip="Copy Quick Link to Clipboard"
                            text2Copy={createQuickLink(props.quickLinkTail)}
                        />
                    )}

                    {authentication.authData.isAuthenticated ? (
                        <>
                            <IconButton onClick={handleProfileMenuOpen} edge="end" color="inherit" size="large">
                                <AccountCircle />
                            </IconButton>
                            &nbsp;&nbsp;&nbsp;
                            <Link
                                color="inherit"
                                variant="body1"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.apiUrl}/wam/ui/artifacts/download-latest/win32/x64`}
                            >
                                Download CRWL
                            </Link>
                        </>
                    ) : (
                        <IconButton
                            onClick={() => window.location.replace('/Login')}
                            edge="end"
                            color="inherit"
                            size="large"
                        >
                            <AccountCircle />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            {renderProfileMenu}
            {renderDrawerMenu}
        </>
    );
};

export default Header;
