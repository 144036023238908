import React from 'react';
import { Paper, Box } from '@mui/material';
import { JobRunSummarySearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import { formatTimestamp, pluralSuffix } from '../../utils';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';

const explicitKeys = [
    'jobConfigName',
    'jobConfigId',
    'jobMode',
    'jobRunLocation',
    'bbdsTranId',
    'runId',
    'provRootStepid',
];

export type SystemErrorSearchDocCardProps = {
    error: JobRunSummarySearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
    renderActions?: (error: JobRunSummarySearchDoc) => JSX.Element;
};

const SystemErrorSearchDocCard = (props: SystemErrorSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} system-error-search-card`}>
            <CardTypeHeader type={DocTypes.SYS_ERROR} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.error.jobConfigName}
                jobConfigVersion={props.error.jobConfigVersion}
                jobConfigId={props.error.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu jobConfigId={props.error.jobConfigId} jobMode={props.error.jobMode} />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Mode:',
                        value: props.error.jobMode,
                        searchKey: 'jobMode',
                    }}
                    highlightData={props.highlightData}
                />

                {props.error.startTime && (
                    <InfoField
                        content={[
                            {
                                label: 'Job ran on',
                                value: formatTimestamp(props.error.startTime),
                            },
                            {
                                label: 'in',
                                value: props.error.jobRunLocation,
                                searchKey: 'jobRunLocation',
                            },
                        ]}
                        highlightData={props.highlightData}
                    />
                )}
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Run Id:',
                        value: props.error.runId,
                        searchKey: 'runId',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Agent ID:',
                        value: props.error.agentId,
                        searchKey: 'agentId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: `Error${pluralSuffix(props.error.errMessage?.length)}:`,
                        value: (
                            <>
                                {props.error.errMessage?.map((errMsg: string, idx: number) => (
                                    <>
                                        <div key={idx}>{errMsg}</div>
                                    </>
                                ))}
                            </>
                        ),
                    }}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default SystemErrorSearchDocCard;
