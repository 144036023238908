import React from 'react';
import { Tooltip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    clickableIcon: {
        verticalAlign: 'text-top',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

interface CopyToClipboardProps {
    tooltip: string;
    text2Copy: string;
    size?: 'default' | 'inherit' | 'large' | 'medium' | 'small';
}

const CopyToClipboard = (props: CopyToClipboardProps): JSX.Element => {
    const [isCopied, setIsCopied] = React.useState(false);

    const classes = useStyles();

    return (
        <Tooltip title={isCopied ? 'copied' : props.tooltip}>
            <FileCopyIcon
                className={classes.clickableIcon}
                fontSize={props.size}
                onClick={(event) => {
                    event.stopPropagation();

                    navigator.clipboard.writeText(props.text2Copy);
                    setIsCopied(true);

                    setTimeout(() => {
                        setIsCopied(false);
                    }, 1000);
                }}
            />
        </Tooltip>
    );
};

export default CopyToClipboard;
