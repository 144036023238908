import React from 'react';
import { Link as LinkRouter, RouteComponentProps } from 'react-router-dom';
import { Box, FormControlLabel, LinearProgress, Link, Paper, Switch, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Content from '../components/Content';
import { getJobConfigRoute, wrapApiCall } from '../utils';
import { useAuth } from '../context/auth';
import searchService from '../services/searchService';
import {
    JobConfigSearchDoc,
    JobConfigSearchDocRaw,
    JobDeliverySearchDoc,
    JobDeliverySearchDocRaw,
} from '@webacq/wa-shared-definitions';
import wamService from '../services/wamService';
import { DocTypes, QueryStrings } from '../shared/constants';
import DeliverySelector from '../components/HTMLDiff/DeliverySelector';
import { DeliveryInfo } from '../components/HTMLDiff/types';
import ErrorMessage from '../components/ErrorMessage';

const useStyles = makeStyles((theme: Theme) => ({
    controls: {
        padding: theme.spacing(2),
    },
    deliveryUrl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    deliverySelector: {
        marginRight: theme.spacing(2),
        width: '20em',
    },
    diffContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    horizSpacer: {
        marginLeft: theme.spacing(2),
    },
}));

interface URLParams {
    jobConfigId: string;
    deliveryUrl: string;
}

const HTMLDiff = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const authContext = useAuth();
    const classes = useStyles();

    const [jobConfigName, setJobConfigName] = React.useState('');
    const [isLoadingDeliveryInfo, setIsLoadingDeliveryInfo] = React.useState(false);
    const [deliveryInfo, setDeliveryInfo] = React.useState<DeliveryInfo[]>([]);

    const [selectedContentAIdx, setSelectedContentAIdx] = React.useState<number>();
    const [selectedContentBIdx, setSelectedContentBIdx] = React.useState<number>();

    const [diffUrl, setDiffUrl] = React.useState('');
    const [diffHtml, setDiffHtml] = React.useState('');
    const [showChangeMarker, setShowChangeMarker] = React.useState(false);
    const [strictMode, setStrictMode] = React.useState(false);

    const [errormsg, setErrormsg] = React.useState('');

    const jobConfigId = props.match.params.jobConfigId;
    const deliveryUrl = decodeURIComponent(props.match.params.deliveryUrl);

    const queryParms = new URLSearchParams(props.history.location.search.toLowerCase());
    const contentAcquisitionTime = queryParms.get(QueryStrings.ACQUISITION_TIME);

    // allow query param for debugging purposes
    const useDiffHtml = queryParms.get('usediffhtml');

    const loadDeliveryInfo = () => {
        setErrormsg('');
        setIsLoadingDeliveryInfo(true);

        wrapApiCall(
            authContext,
            async () => {
                if (jobConfigId && deliveryUrl) {
                    const [deliveries, jobConfig] = await Promise.all([
                        searchService.search<JobDeliverySearchDocRaw, JobDeliverySearchDoc>(
                            `job_config_id_s:${jobConfigId} is_complete_b:false urls_txt:(${deliveryUrl})`,
                            DocTypes.JOB_DLV,
                            100,
                            undefined,
                            'bbds_publish_dt desc, id desc'
                        ),
                        searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                            `${jobConfigId}`,
                            DocTypes.JOB_CONFIG,
                            1
                        ),
                    ]);

                    setJobConfigName(jobConfig.data?.docs[0]?.jobConfigName || '');

                    if (deliveries.data) {
                        const docs = deliveries.data.docs
                            .filter((doc) => doc.urls[0] === deliveryUrl && doc.jobAcqTime)
                            .sort((lhs, rhs) => (rhs.jobAcqTime?.getTime() || 0) - (lhs.jobAcqTime?.getTime() || 0));

                        const deliveryInfos = docs.map((doc) => ({
                            // we should never get to using the fallback values,
                            // since the respecfive records will get filtered out above
                            acquisitionTime: doc.jobAcqTime || new Date(),
                            bcosUrl: (doc.deliveries || [])[0] || '',
                        }));

                        let contentIdx: number | undefined = 0;
                        if (contentAcquisitionTime) {
                            const idx = deliveryInfos.findIndex(
                                (deliveryInfo) => `${deliveryInfo.acquisitionTime.getTime()}` === contentAcquisitionTime
                            );
                            contentIdx = idx >= 0 ? idx : undefined;
                        }

                        const baseContentIdx =
                            contentIdx !== undefined && contentIdx + 1 < deliveryInfos.length
                                ? contentIdx + 1 // they're sorted descending
                                : undefined;

                        setDeliveryInfo(deliveryInfos);
                        setSelectedContentAIdx(baseContentIdx);
                        setSelectedContentBIdx(contentIdx);
                    } else if (deliveries.error) {
                        console.log(deliveries.error);
                        setErrormsg(deliveries.error.msg);
                    }
                }
            },
            (e: Error) => {
                console.log(e.message);
                setErrormsg(e.message);
            },
            () => setIsLoadingDeliveryInfo(false)
        );
    };

    const loadDiff = () => {
        if (selectedContentAIdx !== undefined && selectedContentBIdx !== undefined) {
            setErrormsg('');

            wrapApiCall(
                authContext,
                async () => {
                    const contentABcosUrl = deliveryInfo[selectedContentAIdx].bcosUrl;
                    const contentBBcosUrl = deliveryInfo[selectedContentBIdx].bcosUrl;

                    if (useDiffHtml) {
                        setDiffUrl('');
                        const html = await wamService.getHTMLDiff(
                            contentABcosUrl,
                            contentBBcosUrl,
                            deliveryUrl,
                            showChangeMarker,
                            strictMode
                        );
                        setDiffHtml(html);
                    } else {
                        setDiffHtml('');
                        const url = wamService.getHTMLDiffUrl(
                            contentABcosUrl,
                            contentBBcosUrl,
                            deliveryUrl,
                            showChangeMarker,
                            strictMode
                        );
                        setDiffUrl(url);
                    }
                },
                (e: Error) => {
                    console.log(e.message);
                    setErrormsg(e.message);
                }
            );
        }
    };

    React.useEffect(() => {
        loadDeliveryInfo();
    }, [jobConfigId, deliveryUrl]);

    React.useEffect(() => {
        loadDiff();
    }, [selectedContentAIdx, selectedContentBIdx, useDiffHtml, showChangeMarker, strictMode]);

    const subtitle = (
        <>
            HTML Diff:
            <span className={classes.horizSpacer} />
            <Tooltip title="View job config details">
                <LinkRouter to={getJobConfigRoute(jobConfigId)}>{jobConfigName || jobConfigId}</LinkRouter>
            </Tooltip>
        </>
    );

    return (
        <Content subtitle={subtitle}>
            {isLoadingDeliveryInfo && <LinearProgress />}

            {!isLoadingDeliveryInfo && (
                <Box display="flex" flexDirection="column" height="100%">
                    <Paper className={classes.deliveryUrl}>
                        Delivery Url:&nbsp;&nbsp;
                        <Link target="_blank" rel="noopener noreferrer" href={deliveryUrl}>
                            {deliveryUrl}
                        </Link>
                    </Paper>
                    <Box className={classes.controls}>
                        <Box display="flex">
                            <Box className={classes.deliverySelector}>
                                <DeliverySelector
                                    label="Content A from"
                                    options={deliveryInfo}
                                    selectedOptionIdx={selectedContentAIdx}
                                    onChange={(selectedOptionIdx) => {
                                        setSelectedContentAIdx(selectedOptionIdx);
                                        setDiffUrl('');
                                    }}
                                />
                            </Box>
                            <Box className={classes.deliverySelector}>
                                <DeliverySelector
                                    label="Content B from"
                                    options={deliveryInfo}
                                    selectedOptionIdx={selectedContentBIdx}
                                    onChange={(selectedOptionIdx) => {
                                        setSelectedContentBIdx(selectedOptionIdx);
                                        setDiffUrl('');
                                    }}
                                />
                            </Box>

                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={strictMode}
                                        onChange={(event) => setStrictMode(event.currentTarget.checked)}
                                    />
                                }
                                label="Strict Mode"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={showChangeMarker}
                                        onChange={(event) => setShowChangeMarker(event.currentTarget.checked)}
                                    />
                                }
                                label="Show Markers"
                            />
                        </Box>
                    </Box>

                    {errormsg && <ErrorMessage message={errormsg} onClose={() => setErrormsg('')} />}

                    <Box flexGrow={1}>
                        {diffUrl ? (
                            <iframe className={classes.diffContainer} src={diffUrl} />
                        ) : (
                            <iframe className={classes.diffContainer} srcDoc={diffHtml} />
                        )}
                    </Box>
                </Box>
            )}
        </Content>
    );
};

export default HTMLDiff;
