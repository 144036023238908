/*
This import prevents an eval() call which would require our
content security policy to include 'unsafe-eval'.
*/
import 'regenerator-runtime/runtime.js';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
