import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import CardTitle from '../CardTitle';
import JobErrorsTable from './JobErrorsTable';
import SysErrorsTable from './SysErrorsTable';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
    vertSpacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

interface JobErrorsCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    jobConfigId: string;
    jobMode?: string;
}

const JobErrorsCard = (props: JobErrorsCardProps): JSX.Element => {
    const classes = useStyles();

    const [numJobErrors, setNumJobErrors] = React.useState(0);
    const [numSysErrors, setNumSysErrors] = React.useState(0);

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={
                <CardTitle title="Errors" recordCount={numJobErrors + numSysErrors} collapsedMode={props.collapsed} />
            }
        >
            <>
                <JobErrorsTable
                    jobConfigId={props.jobConfigId}
                    jobMode={props.jobMode}
                    getRecordCount={(count) => setNumJobErrors(count)}
                />
                <Box className={classes.vertSpacer} />
                <SysErrorsTable
                    jobConfigId={props.jobConfigId}
                    jobMode={props.jobMode}
                    getRecordCount={(count) => setNumSysErrors(count)}
                />
            </>
        </CollapsibleCard>
    );
};

export default JobErrorsCard;
