import { colors } from '@webacq/wam-ui-components';

export enum DocTypes {
    JOB_CONFIG = 'JOB_CFG',
    JOB = 'JOB_SCD',
    JOB_DLV = 'JOB_DLV',
    JOB_DUP = 'JOB_DUP',
    ALERT_NOTIF = 'ALERT_NOTIF',
    JOB_ERR = 'JOB_ERR',
    SYS_ERROR = 'SYS_ERROR',
    POST_PROCESSING = 'POST_PROCESSING',
}

const DocTypeNameMapping: Record<DocTypes, string> = {
    [DocTypes.JOB_CONFIG]: 'Job Configuration',
    [DocTypes.JOB]: 'Job',
    [DocTypes.JOB_DLV]: 'Delivery',
    [DocTypes.JOB_DUP]: 'FilteredDuplicate',
    [DocTypes.ALERT_NOTIF]: 'Alert Notification',
    [DocTypes.JOB_ERR]: 'Job Error',
    [DocTypes.SYS_ERROR]: 'System Error',
    [DocTypes.POST_PROCESSING]: 'Post-Processing',
};

export function DocTypeName(type: DocTypes): string {
    return DocTypeNameMapping[type] || '';
}

export enum QueryStrings {
    SEARCH = 'search',
    JOB_CONFIG_ID = 'jobconfigid',
    JOB_MODE = 'jobmode',
    ALERT_CONFIG_ID = 'alertconfigid',
    BBDS_TRANSACTION_ID = 'bbdsTransactionId',
    ACQUISITION_TIME = 'acquisitiontime',
}

export enum GlobalPermissions {
    CAN_ACCESS_HUB = 'can_access_hub',
}

export enum SummaryQueryStrings {
    SUMMART_TYPE = 'summary_type',
    FROM = 'from',
    TO = 'to',
    MODE = 'mode',
    RECORD_UNIT = 'record_unit',
    RECORD_QUANT = 'record_quant',
    JOB_CONFIG_ID = 'job_config_id',
    AGENT_ID = 'agent_id',
    DOMAIN = 'domain',
}

export enum SummaryType {
    GLOBAL = 'global',
    ZONE = 'zone',
    JOB = 'job',
    DOMAIN = 'domain',
    AGENT = 'agent',
}

export enum RecordUnit {
    MINUTE = 'minute',
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum Mode {
    LIVE = 'live',
    TEST = 'test',
    DRAFT = 'draft',
    ALL = 'all',
}

export const SummaryColors = {
    NumDeliveries: colors.green[300],
    NumErrors: colors.red[300],
    NumRuns: colors.blue[300],
    NumUrlsFollowed: colors.yellow[300],
    NumUrlsSeen: colors.magenta[300],
    BytesDownloaded: colors.purple[300],
    Duration: colors.teal[300],
    NumJobs: colors.orange[300],
};

export const DocTypeColors = {
    JOB_CFG: colors.blue[200],
    JOB_SCD: colors.purple[300],
    JOB_DLV: colors.green[200],
    ALERT_NOTIF: colors.teal[300],
    JOB_ERR: colors.red[200],
    SYS_ERROR: colors.orange[300],
    JOB_DUP: colors.yellow[300],
    POST_PROCESSING: colors.yellow[500],
};

export const RunLocationMapping = {
    'ny-nj': 'Bloomberg US-East',
};

export const SOLRFieldMapping: Record<string, string> = {
    jobConfigId: 'job_config_id_s',
    jobConfigVersion: 'job_config_version_i',
    jobConfigName: 'job_config_name_t',
    jobConfigDescription: 'job_config_desc_t',
    jobStatus: 'job_status_s',
    jobMode: 'job_mode_s',
    jobConfigTemplateName: 'job_config_template_name_t',
    jobRunLocation: 'job_run_location_s',
    locationName: 'location_name_s',
    agentId: 'agent_id_s',
    domains: 'domains_ss',
    bbdsTranId: 'bbds_tran_id_s',
    bbdsTransactionId: 'bbds_tran_id_s', // not an "officially" used field, but here for search translation convenience
    bbdsPublish: 'bbds_publish_dt',
    bbdsPublishTime: 'bbds_publish_dt', // not an "officially" used field, but here for search translation convenience
    isRouted: 'is_routed_b',
    isComplete: 'is_complete_b',
    alertNotifType: 'alert_notif_type_s',
    alertNotifAqStart: 'alert_notif_aq_start_dt',
    alertNotifAqEnd: 'alert_notif_aq_end_dt',
    errMsg: 'err_msg_txt',
    errMessage: 'err_message_txt',
    startTime: 'start_time_dt',
    jobStartTime: 'job_start_time_dt',
    urls: 'urls_ss',
    storySuid: 'story_suid_s',
    suid: 'story_suid_s', // not an "officially" used field, but here for search translation convenience
    storySourceUrl: 'story_source_url_t',
    storyHeadline: 'story_headline_t',
    storyBody: 'story_body_t',
    storyAcqTime: 'story_acq_time_dt',
};
