import React, { useEffect } from 'react';
import { JobSearchDoc, JobStatus as JobStatusValue } from '@webacq/wa-shared-definitions';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { jobSchedule2text } from '../../utils';
import JobStatus from '../JobStatus';
import { RunLocationMapping } from '../../shared/constants';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import CardTitle from './CardTitle';
import { PaginatedTableColumn } from '../PaginatedTable/types';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface JobSchedulesCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    jobs: JobSearchDoc[];
    jobVersionMapping: Record<string, number>;
}

interface ScheduleRecord {
    jobConfigVersion: number;
    jobMode: string;
    status: JobStatusValue | undefined;
    runLocation: string;
    description: string;
    rawDescription: string;
}

const JobSchedulesCard = (props: JobSchedulesCardProps): JSX.Element => {
    const classes = useStyles();

    const [schedules, setSchedules] = React.useState<ScheduleRecord[]>([]);

    const columns = [
        {
            header: 'Job Config Version',
            accessorKey: 'jobConfigVersion',
        },
        {
            header: 'Job Mode',
            accessorKey: 'jobMode',
        },
        {
            header: 'Run Location',
            accessorKey: 'runLocation',
        },
        {
            header: 'Status',
            accessorKey: 'status',
            accessorFn: function getStatusChip(rowData: ScheduleRecord) {
                return <JobStatus status={rowData.status} />;
            },
        },
        {
            header: 'Schedule',
            accessorKey: 'description',
            accessorFn: function getSchedule(rowData: ScheduleRecord) {
                return (
                    <Tooltip title={rowData.rawDescription}>
                        <span>{rowData.description}</span>
                    </Tooltip>
                );
            },
        },
    ] as PaginatedTableColumn<ScheduleRecord>[];

    useEffect(() => {
        const schedules: ScheduleRecord[] = [];

        props.jobs.forEach((job) => {
            if (job.schedule) {
                schedules.push({
                    jobConfigVersion: (props.jobVersionMapping && props.jobVersionMapping[job.jobMode || '']) || 0,
                    jobMode: job.jobMode || '<??>',
                    status: job.jobStatus,
                    runLocation: RunLocationMapping[job.locationName || ''] || job.locationName,
                    description: jobSchedule2text(job),
                    rawDescription: job.schedule,
                });
            }
        });

        setSchedules(schedules);
    }, [props.jobs]);

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Schedules" recordCount={schedules.length} collapsedMode={props.collapsed} />}
        >
            {schedules.length > 0 ? (
                <PaginatedTable columns={columns} rows={schedules} />
            ) : (
                <Box className={classes.contentBox} fontStyle="italic">
                    <Typography variant="body2">No schedules found</Typography>
                </Box>
            )}
        </CollapsibleCard>
    );
};

export default JobSchedulesCard;
