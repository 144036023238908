import React from 'react';
import { Paper, Box } from '@mui/material';
import { JobErrorSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatDuration, formatTimestamp, pluralSuffix } from '../../utils';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';

const explicitKeys = [
    'jobConfigName',
    'jobConfigId',
    'jobMode',
    'jobRunLocation',
    'bbdsTranId',
    'runId',
    'provRootStepid',
];

export type JobErrorSearchDocCardProps = {
    error: JobErrorSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const JobErrorSearchDocCard = (props: JobErrorSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} job-error-search-card`}>
            <CardTypeHeader type={DocTypes.JOB_ERR} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.error.jobConfigName}
                jobConfigVersion={props.error.jobConfigVersion}
                jobConfigId={props.error.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu jobConfigId={props.error.jobConfigId} jobMode={props.error.jobMode} />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Mode:',
                        value: props.error.jobMode,
                        searchKey: 'jobMode',
                    }}
                    highlightData={props.highlightData}
                />

                {props.error.jobStartTime && props.error.jobEndTime && (
                    <InfoField
                        content={[
                            {
                                label: 'Job ran on',
                                value: formatTimestamp(props.error.jobStartTime),
                            },
                            {
                                label: 'for',
                                value: formatDuration(
                                    props.error.jobEndTime.getTime() - props.error.jobStartTime.getTime()
                                ),
                            },
                            {
                                label: 'in',
                                value: props.error.jobRunLocation,
                                searchKey: 'jobRunLocation',
                            },
                        ]}
                        highlightData={props.highlightData}
                    />
                )}
            </Box>

            <Box className={classes.infoRow}>
                {props.error.bbdsPublish && (
                    <InfoField
                        content={{
                            label: 'Delivered at',
                            value: formatTimestamp(props.error.bbdsPublish),
                        }}
                    />
                )}

                <InfoField
                    content={{
                        label: 'BBDS Transaction Id:',
                        value: props.error.bbdsTranId,
                        searchKey: 'bbdsTranId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Run Id:',
                        value: props.error.runId,
                        searchKey: 'runId',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Root Step Id:',
                        value: props.error.provRootStepid,
                        searchKey: 'provRootStepid',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: `Error${pluralSuffix(props.error.errMsg?.length)}:`,
                        value: (
                            <>
                                {props.error.errMsg?.map((errMsg: string, idx: number) => (
                                    <>
                                        <div key={idx}>{errMsg}</div>
                                    </>
                                ))}
                            </>
                        ),
                    }}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default JobErrorSearchDocCard;
