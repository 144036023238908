import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    paddedBox: {
        padding: theme.spacing(1),
        // borderLeft: (props: { color: string }) => `3px solid ${props.color}`,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
    infoRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(0.5),
    },
    link: {
        color: theme.palette.primary.light,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    highlight: {
        color: `rgb(240, 255, 0)`,
        fontWeight: 800,
    },
    extraHighlights: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
    },
}));
