import React from 'react';
import {
    Theme,
    FormControl,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Mode, SummaryType } from '../../shared/constants';
import { Refresh as RefreshIcon } from '@mui/icons-material';

interface SummaryFiltersProps {
    summaryType: string;
    recordQuant: string;
    recordUnit: string;
    mode: string;
    from: string;
    to: string;
    jobConfigId: string;
    domain: string;
    agentId: string;
    inputEmpty: boolean;
    loadBtnClickHandler: () => void;
    typeChangedHandler: (summaryType: string) => void;
    unitChangedHandler: (recordQuant: string, recordUnit: string) => void;
    modeChangedHandler: (mode: string) => void;
    fromChangedHandler: (from: string) => void;
    jobConfigIdChangedHandler?: (jobConfigId: string) => void;
    domainChangedHandler?: (domain: string) => void;
    agentIdChangedHandler?: (agentId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    filterInput: {
        marginRight: theme.spacing(2),
        minWidth: '15em',
    },
}));

const SummaryFilters = (props: SummaryFiltersProps): JSX.Element => {
    const classes = useStyles();

    const _typeChangedHandler = (event: SelectChangeEvent<string>) => {
        props.typeChangedHandler(event.target.value as string);
    };

    const _unitChangedHandler = (event: SelectChangeEvent<string>) => {
        const [quant, unit] = (event.target.value as string).split(' ');
        props.unitChangedHandler(quant, unit);
    };

    const _modeChangedHandler = (event: SelectChangeEvent<string>) => {
        props.modeChangedHandler(event.target.value as string);
    };

    const _fromChangedHandler = (event: SelectChangeEvent<string>) => {
        props.fromChangedHandler(event.target.value as string);
    };

    const _jobConfigIdChangedHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (props.jobConfigIdChangedHandler) {
            props.jobConfigIdChangedHandler(event.target.value as string);
        }
    };

    const _domainChangedHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (props.domainChangedHandler) {
            props.domainChangedHandler(event.target.value as string);
        }
    };

    const _agentIdChangedHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (props.agentIdChangedHandler) {
            props.agentIdChangedHandler(event.target.value as string);
        }
    };

    const UnitInput = () => {
        switch (props.summaryType) {
            case SummaryType.JOB:
                return (
                    <Box className={classes.filterInput}>
                        <TextField
                            value={props.jobConfigId}
                            onChange={_jobConfigIdChangedHandler}
                            size="small"
                            error={props.inputEmpty}
                            label="Job Config ID"
                        />
                    </Box>
                );

            case SummaryType.DOMAIN:
                return (
                    <Box className={classes.filterInput}>
                        <TextField
                            value={props.domain}
                            onChange={_domainChangedHandler}
                            size="small"
                            error={props.inputEmpty}
                            label="Domain"
                        />
                    </Box>
                );

            case SummaryType.AGENT:
                return (
                    <Box className={classes.filterInput}>
                        <TextField
                            value={props.agentId}
                            onChange={_agentIdChangedHandler}
                            size="small"
                            error={props.inputEmpty}
                            label="Agent ID"
                        />
                    </Box>
                );

            default:
                return <></>;
        }
    };

    const typeOptions = [SummaryType.GLOBAL, SummaryType.ZONE, SummaryType.JOB, SummaryType.DOMAIN, SummaryType.AGENT];

    const unitOptions = [
        { unit: '30 minute', label: '30 minutes', duration: 30 },
        { unit: '1 hour', label: 'hourly', duration: 60 },
        { unit: '1 day', label: 'daily', duration: 60 * 24 },
        { unit: '1 week', label: 'weekly', duration: 60 * 24 * 7 },
        { unit: '1 month', label: 'monthly', duration: 60 * 24 * 30 },
        { unit: '1 year', label: 'yearly', duration: 60 * 24 * 365 },
    ];

    const timerangeOptions = [
        { range: 'now-3h', label: 'last 3 hours', duration: 60 * 3 },
        { range: 'now-12h', label: 'last 12 hours', duration: 60 * 12 },
        { range: 'now-24h', label: 'last 24 hours', duration: 60 * 24 },
        { range: 'now-7d', label: 'last 7 days', duration: 60 * 24 * 7 },
        { range: 'now-1M', label: 'last 1 month', duration: 60 * 24 * 30 },
        { range: 'now-1y', label: 'last 1 year', duration: 60 * 24 * 365 },
        { range: 'now-3y', label: 'last 3 years', duration: 60 * 24 * 365 * 3 },
    ];

    const modeOptions = [Mode.ALL, Mode.LIVE, Mode.TEST, Mode.DRAFT];

    return (
        <Box display="flex">
            <FormControl>
                <InputLabel id="scope-select-label">Scope</InputLabel>
                <Select
                    className={classes.filterInput}
                    value={props.summaryType}
                    labelId="scope-select-label"
                    onChange={_typeChangedHandler}
                >
                    {typeOptions.map((typeOption, idx) => (
                        <MenuItem key={idx} value={typeOption}>
                            {typeOption}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <UnitInput />

            <FormControl>
                <InputLabel id="unit-select-label">Unit</InputLabel>
                <Select
                    className={classes.filterInput}
                    value={`${props.recordQuant} ${props.recordUnit}`}
                    labelId="unit-select-label"
                    onChange={_unitChangedHandler}
                >
                    {unitOptions.map((unitOption, idx) => (
                        <MenuItem key={idx} value={unitOption.unit}>
                            {unitOption.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="mode-select-label">Job Mode</InputLabel>
                <Select
                    className={classes.filterInput}
                    value={props.mode}
                    labelId="mode-select-label"
                    onChange={_modeChangedHandler}
                >
                    {modeOptions.map((modeOption, idx) => (
                        <MenuItem key={idx} value={modeOption}>
                            {modeOption}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="timerange-select-label">Time Range</InputLabel>
                <Select
                    className={classes.filterInput}
                    value={props.from}
                    labelId="timerange-select-label"
                    onChange={_fromChangedHandler}
                >
                    {/* time range must be greater than unit  */}
                    {timerangeOptions
                        .filter((timerangeOption) => {
                            const selectedUnit = unitOptions.find(
                                (unitOption) => unitOption.unit === `${props.recordQuant} ${props.recordUnit}`
                            );
                            if (selectedUnit) {
                                return timerangeOption.duration >= selectedUnit.duration;
                            }
                            return true;
                        })
                        .map((timerangeOption, idx) => (
                            <MenuItem key={idx} value={timerangeOption.range}>
                                {timerangeOption.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>

            <Button onClick={props.loadBtnClickHandler} variant="outlined" startIcon={<RefreshIcon />} color="primary">
                Load
            </Button>
        </Box>
    );
};

export default SummaryFilters;
