import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { JobMode } from '@webacq/wa-shared-definitions';
// import PopupMenu from '../PopupMenuTemp';
import { PopupMenu } from '@webacq/wam-ui-components';
import { getAlertConfigRoute, getJobConfigRoute, launchCRWL } from '../../utils';

export const useStyles = makeStyles((theme: Theme) => ({
    header: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
}));

interface ActionPopupMenuProps {
    jobConfigId: string | undefined;
    jobMode: JobMode | undefined;
    hideAlertItem?: boolean;
    labels?: string[];
}

const ActionPopupMenu = (props: ActionPopupMenuProps): JSX.Element => {
    const history = useHistory();

    const labelOverrides = props.labels || [];

    return (
        <PopupMenu
            items={[
                {
                    label: 'Job Config Details',
                    callback: () => {
                        history.push(getJobConfigRoute(props.jobConfigId, props.jobMode));
                    },
                },
                {
                    // hidden: !props.jobConfigId,
                    label: 'Launch Job Config in CRWL',
                    callback: () => launchCRWL(props.jobConfigId),
                },
                {
                    hidden: props.hideAlertItem,
                    label: 'Job Config Alert Configuration',
                    callback: () => {
                        history.push(getAlertConfigRoute(props.jobConfigId));
                    },
                },
            ].map((item, idx) => ({
                ...item,
                label: labelOverrides[idx] || item.label,
            }))}
        />
    );
};

export default ActionPopupMenu;
