import React from 'react';
import { Link, Tooltip } from '@mui/material';
import { FilterNone as DocumentIcon } from '@mui/icons-material';
import { getObjectViewerRoute } from '../utils';

interface ObjectViewerLinkProps {
    url: string;
    showNewTabIcon?: boolean;
}

const ObjectViewerLink = (props: ObjectViewerLinkProps): JSX.Element => {
    return (
        <Tooltip title="Open delivered document from BCOS">
            <Link href={getObjectViewerRoute(props.url, !props.showNewTabIcon)} target="_blank">
                <DocumentIcon fontSize="small" />
            </Link>
        </Tooltip>
    );
};

export default ObjectViewerLink;
