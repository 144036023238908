import React from 'react';
import { Paper, Table, TableCell, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import HighlightedElement from './HighlightedElement';

export const useStyles = makeStyles((theme: Theme) => ({
    extraHighlights: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
    },
}));

interface ExtraHighlightedElementsProps {
    highlightData?: HighlightRecord;
    explicitKeys: string[];
}

const ExtraHighlightedElements = (props: ExtraHighlightedElementsProps): JSX.Element => {
    const classes = useStyles();

    const extraHighlightKeys = Object.keys(props.highlightData || {}).filter(
        (key) => !props.explicitKeys.includes(key)
    );

    if (extraHighlightKeys.length > 0) {
        return (
            <Paper elevation={2} className={classes.extraHighlights}>
                <Table>
                    {extraHighlightKeys.map((key, idx) => (
                        <TableRow key={idx}>
                            <TableCell
                                style={{ verticalAlign: 'top', fontWeight: 'bold', width: '1%' }}
                            >{`${key}:`}</TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <HighlightedElement value="" fieldName={key} highlightData={props.highlightData} />
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </Paper>
        );
    }

    return <></>;
};

export default ExtraHighlightedElements;
