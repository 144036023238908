import React from 'react';
import { Box, Theme, Typography, Tooltip, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { BbgTerminalIcon, CollapsibleCard } from '@webacq/wam-ui-components';
import { ManualRunSearchDoc } from '@webacq/wa-shared-definitions';
import { MRT_Cell, MRT_Row } from 'material-react-table';
import { AccountTree } from '@mui/icons-material';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import CardTitle from './CardTitle';
import { launchMnemonic } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface ManuaRunsCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    manualRuns: ManualRunSearchDoc[];
}

const TriggererInfo = ({
    triggererName,
    triggererBbgUuid,
    triggererUserId,
}: Pick<ManualRunSearchDoc, 'triggererName' | 'triggererBbgUuid' | 'triggererUserId'>) => (
    <Box width={400} paddingRight={1} display="flex" flexDirection="column">
        <Box color="secondary.main">
            <b>{triggererName}</b>
        </Box>
        <div>
            <b>Crawl User ID:</b> {triggererUserId}
        </div>
        <div>
            <b>BBG UUID:</b> {triggererBbgUuid}
        </div>
    </Box>
);

const ManualRunsCard = ({ collapsed, manualRuns, onCollapsedChanged }: ManuaRunsCardProps): JSX.Element => {
    const classes = useStyles();

    const columns = [
        {
            header: 'Start',
            accessorKey: 'startTime',
            filterVariant: 'date',
        },
        {
            header: 'Job Mode',
            accessorKey: 'jobMode',
        },
        {
            header: 'Config Version',
            accessorKey: 'jobConfigVersion',
        },
        {
            header: 'Triggerer',
            accessorKey: 'triggererName',
            Cell: ({ row }: { row: MRT_Row<ManualRunSearchDoc> }) => {
                const doc = row.original;
                return (
                    <Tooltip title={<TriggererInfo {...doc} />}>
                        <a
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => launchMnemonic(`UUID`, doc.triggererBbgUuid)}
                        >
                            {doc.triggererName} &nbsp;
                            <BbgTerminalIcon
                                style={{
                                    fontSize: 14,
                                }}
                            />
                        </a>
                    </Tooltip>
                );
            },
        },
        {
            header: 'Run Tree',
            id: 'runId',
            size: 20,
            enableSorting: false,
            enableColumnFilter: false,
            accessorFn: rowData => rowData.runId,

            Cell: ({ cell }: { cell: MRT_Cell<ManualRunSearchDoc> }) => (
                <RouterLink to={`/manual-run/${encodeURIComponent((cell.getValue() as string) || '')}`}>
                    <Button variant="outlined">
                        <AccountTree />
                        &nbsp; Run Tree
                    </Button>
                </RouterLink>
            ),
        },
    ] as PaginatedTableColumn<ManualRunSearchDoc>[];

    return (
        <CollapsibleCard
            collapsed={collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                onCollapsedChanged && onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Manual Runs" recordCount={manualRuns.length} collapsedMode={collapsed} />}
        >
            {manualRuns.length ? (
                <PaginatedTable
                    columns={columns}
                    rows={manualRuns}
                    initialSorting={[
                        {
                            id: 'startTime',
                            desc: true,
                        },
                    ]}
                />
            ) : (
                <Box className={classes.contentBox} fontStyle="italic">
                    <Typography variant="body2">No manual runs found</Typography>
                </Box>
            )}
        </CollapsibleCard>
    );
};

export default ManualRunsCard;
