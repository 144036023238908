import React from 'react';
import { Box, Typography } from '@mui/material';
import { CrwlIcon } from '@webacq/wam-ui-components';

interface AppInfoProps {
    appTitle: string;
    env?: string;
    version: string;
    onClick?: () => void;
}

const AppInfo = (props: AppInfoProps): JSX.Element => {
    return (
        <Box
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            <CrwlIcon
                style={{
                    width: 35,
                    marginRight: 5,
                }}
            />
            <Typography variant="h5" noWrap>
                {props.appTitle}
                {props.env && <small> ({props.env})</small>}
                &nbsp;
                <Typography variant="subtitle2" noWrap component="span">
                    v{props.version}
                </Typography>
            </Typography>
        </Box>
    );
};

export default AppInfo;
