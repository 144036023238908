import React from 'react';
import { Link, Tooltip } from '@mui/material';
import { Description as DescriptionIcon } from '@mui/icons-material';
import { getHumioLink } from '../../utils';

interface HumioLinkProps {
    logConfigName: string;
    query: string;
    startTime?: Date;
    endTime?: Date;
    logRetentionInDays: number;
}

const HumioLink = (props: HumioLinkProps): JSX.Element => {
    const now = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;

    const referenceTime = props.endTime || props.startTime;

    const tooOld = referenceTime && referenceTime.getTime() < now.getTime() - props.logRetentionInDays * msPerDay;

    return tooOld ? (
        <Tooltip title={'Logs are too old to be found in Humio'}>
            <DescriptionIcon color="disabled" fontSize="small" />
        </Tooltip>
    ) : (
        <Tooltip title={'View Humio Logs'}>
            <Link href={getHumioLink(props.logConfigName, props.query, props.startTime, props.endTime)} target="_blank">
                <DescriptionIcon fontSize="small" />
            </Link>
        </Tooltip>
    );
};

export default HumioLink;
