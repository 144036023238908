export function formatBytes(num: number): string {
    const units = ['K', 'M', 'G', 'T', 'P', 'E'];
    let unit = ' ';

    for (let i = 0; i < units.length; i++) {
        if (Math.abs(num) < 1024.0) {
            break;
        }
        num /= 1024.0;
        unit = units[i];
    }

    return `${num.toFixed(1)} ${unit}B`;
}

export function formatMilliseconds(num: number): string {
    const units = ['sec', 'min', 'hr'];
    const intervals = [1000, 60, 60];
    let unit = 'ms';

    for (let i = 0; i < units.length; i++) {
        if (Math.abs(num) < intervals[i]) {
            break;
        }
        num /= intervals[i];
        unit = units[i];
    }

    return `${num.toFixed(1)} ${unit}`;
}

export function formatFieldData(value: number, field: string): string {
    if (field.startsWith('duration')) {
        return formatMilliseconds(value);
    } else if (field.startsWith('bytesDownloaded')) {
        return formatBytes(value);
    } else {
        return value.toString();
    }
}
