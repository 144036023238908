import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import {
    Box,
    Button,
    Collapse,
    LinearProgress,
    Link,
    MenuItem,
    Paper,
    Select,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Notifications as NotificationsIcon,
    History as HistoryIcon,
    Schedule as ScheduleIcon,
    Launch as LaunchIcon,
    InfoOutlined as InfoOutlinedIcon,
    AllInbox as AllInboxIcon,
    CallSplit as CallSplitIcon,
    ErrorOutline as ErrorOutlineIcon,
    ShoppingCartOutlined as ShoppingCartOutlinedIcon,
    FilterList as FilterListIcon,
    DeveloperBoard as DeveloperBoardIcon,
    DirectionsRun as RunIcon,
} from '@mui/icons-material';
import {
    AlertNotificationSearchDoc,
    AlertNotificationSearchDocRaw,
    JobConfigSearchDoc,
    JobConfigSearchDocRaw,
    JobSearchDoc,
    JobSearchDocRaw,
    JobConfigVersionData,
    JobSummary,
    JobCfgIdWorkItem,
    ManualRunSearchDocRaw,
    ManualRunSearchDoc,
} from '@webacq/wa-shared-definitions';
import { TabScroller, CollapsibleCard } from '@webacq/wam-ui-components';
import searchService from '../services/searchService';
import { useAuth } from '../context/auth';
import Content from '../components/Content';
import JobDeliveriesCard from '../components/JobConfigDetails/JobDeliveriesCard';
import JobDuplicateDeliveriesCard from '../components/JobConfigDetails/JobDuplicateDeliveriesCard';
import PostProcessingCard from '../components/JobConfigDetails/PostProcessingCard';
import JobSchedulesCard from '../components/JobConfigDetails/JobSchedulesCard';
import JobErrorsCard from '../components/JobConfigDetails/JobErrorsCard/JobErrorsCard';
import JobWorkItemsCard from '../components/JobConfigDetails/JobWorkItemsCard';
import CrawlingHistoryCard from '../components/JobConfigDetails/CrawlingHistoryCard';
import JobAlertsCard from '../components/JobConfigDetails/JobAlertsCard';
import RoutingHistoryCard from '../components/JobConfigDetails/RoutingHistoryCard/RoutingHistoryCard';
import ErrorMessage from '../components/ErrorMessage';
import { formatTimestamp, getAlertConfigRoute, launchCRWL, launchUrl, pluralSuffix, wrapApiCall } from '../utils';
import summaryService from '../services/summaryService';
import { RecordUnit, Mode, DocTypes, QueryStrings } from '../shared/constants';
import WAMService from '../services/wamService';
import CopyToClipboard from '../components/CopyToClipboard';
import ManualRunsCard from '../components/JobConfigDetails/ManualRunsCard';

const useStyles = makeStyles((theme: Theme) => ({
    paddedBox: {
        padding: theme.spacing(1),
    },
    tabLabel: {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    tabContent: {
        paddingTop: theme.spacing(1),
    },
    halfPaddedBox: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(4),
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    crawlerInfoFieldBox: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        maxWidth: '33%',
    },
    sectionIcon: {
        fontSize: 'small',
        marginRight: theme.spacing(1),
    },
}));

interface CardState<T> {
    data?: T;
    loading?: boolean;
    collapsed?: boolean;
    errormsg?: string;
}
interface URLParams {
    jobConfigId: string;
}

const JobConfigDetails = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const authContext = useAuth();
    const history = useHistory();
    const classes = useStyles();

    const [jobConfig, setJobConfig] = useState<JobConfigSearchDoc>();
    const [isLoading, setIsLoading] = useState(false);
    const [overviewCollapsed, setOverviewCollapsed] = useState(false);
    const [errormsg, setErrormsg] = useState('');
    const [jobModes, setJobModes] = useState<string[]>([]);
    const [selectedJobMode, setSelectedJobMode] = useQueryParam(QueryStrings.JOB_MODE, withDefault(StringParam, ''));
    const [activeTab, setActiveTab] = useState(0);
    const [jobs, setJobs] = useState<JobSearchDoc[]>([]);
    const [schedulesCardState, setSchedulesCardState] = useState<CardState<void>>({});
    const [crawlingHistoryCardState, setCrawlingHistoryCardState] = useState<CardState<JobSummary[]>>({});
    const [deliveriesCardState, setDeliveriesCardState] = useState<CardState<void>>({});
    const [duplicatesCardState, setDuplicatesCardState] = useState<CardState<void>>({});
    const [postprocessingCardState, setPostprocessingCardState] = useState<CardState<void>>({});
    const [alertsCardState, setAlertsCardState] = useState<CardState<AlertNotificationSearchDoc[]>>({});
    const [routingHistoryCardState, setRoutingHistoryCardState] = useState<CardState<JobConfigVersionData>>({});
    const [jobErrorsCardState, setJobErrorsCardState] = useState<CardState<void>>({});
    const [jobWorkItemsCardState, setJobWorkItemsCardState] = useState<CardState<JobCfgIdWorkItem[]>>({});
    const [manualRunsCardState, setManualRunsCardState] = useState<CardState<ManualRunSearchDoc[]>>({});
    const [jobVersionMapping, setJobVersionMapping] = useState<Record<string, number>>({});

    const jobConfigId = props.match.params.jobConfigId;

    const updateCollapsedState = (collapsed: boolean) => {
        // this is ugly, review when there's time - MR
        setSchedulesCardState({ ...schedulesCardState, collapsed: collapsed });
        setCrawlingHistoryCardState({ ...crawlingHistoryCardState, collapsed: collapsed });
        setDeliveriesCardState({ ...deliveriesCardState, collapsed: collapsed });
        setDuplicatesCardState({ ...duplicatesCardState, collapsed: collapsed });
        setPostprocessingCardState({ ...postprocessingCardState, collapsed: collapsed });
        setAlertsCardState({ ...alertsCardState, collapsed: collapsed });
        setRoutingHistoryCardState({ ...routingHistoryCardState, collapsed: collapsed });
        setJobErrorsCardState({ ...jobErrorsCardState, collapsed: collapsed });
        setJobWorkItemsCardState({ ...jobWorkItemsCardState, collapsed: collapsed });
        setManualRunsCardState({ ...manualRunsCardState, collapsed: collapsed });
    };

    React.useEffect(() => {
        setErrormsg('');
        setIsLoading(true);
        setJobConfig(undefined);

        wrapApiCall(
            authContext,
            async () => {
                const [jobConfigs, jobs, jobConfigsTest, jobConfigsLive] = await Promise.all([
                    getJobConfigs(),
                    getJobs(), // get all modes here
                    getJobConfigs('test'),
                    getJobConfigs('live'),
                ]);

                if (jobConfigs) {
                    if (jobConfigs.length > 0) {
                        if (jobConfigsTest && jobConfigsLive) {
                            setJobVersionMapping({
                                test: jobConfigsTest?.length && jobConfigsTest[0].jobConfigVersion,
                                live: jobConfigsLive?.length && jobConfigsLive[0].jobConfigVersion,
                            });
                        }

                        setJobConfig(jobConfigs[0]);

                        setJobModes(
                            ['', ...(jobs || []).map((job) => (job.jobMode || '').toLowerCase())].filter(
                                (mode, idx, self) => self.indexOf(mode) === idx
                            )
                        );
                    } else {
                        setErrormsg(`No data found for job config id ${jobConfigId}`);
                    }
                }
            },
            (e: Error) => setErrormsg(e.message),
            () => setIsLoading(false)
        );

        setRoutingHistoryCardState({ loading: true, errormsg: '' });
        wrapApiCall(
            authContext,
            async () => {
                const routingHistory = await WAMService.getJobConfigRoutingHistory(jobConfigId);
                setRoutingHistoryCardState({ data: routingHistory });
            },
            (e: Error) => setRoutingHistoryCardState({ errormsg: e.message })
        );

        setJobWorkItemsCardState({ loading: true, errormsg: '' });
        wrapApiCall(
            authContext,
            async () => {
                const jobWorkItems = await WAMService.getJobConfigWorkitems(jobConfigId);
                setJobWorkItemsCardState({ data: jobWorkItems.workitems });
            },
            (e: Error) => setJobWorkItemsCardState({ errormsg: e.message })
        );
    }, []);

    React.useEffect(() => {
        setSchedulesCardState({ loading: true });
        wrapApiCall(
            authContext,
            async () => {
                const jobs = await getJobs(selectedJobMode);
                setJobs(jobs);
                setSchedulesCardState({ loading: false });
            },
            (e: Error) => setSchedulesCardState({ errormsg: e.message })
        );

        setCrawlingHistoryCardState({ loading: true, errormsg: '' });
        wrapApiCall(
            authContext,
            async () => {
                const summary = await getSummary(selectedJobMode);
                setCrawlingHistoryCardState({ data: summary });
            },
            (e: Error) => setCrawlingHistoryCardState({ errormsg: e.message })
        );

        setAlertsCardState({ loading: true, errormsg: '' });
        wrapApiCall(
            authContext,
            async () => {
                const alerts = await getAlerts(selectedJobMode);
                setAlertsCardState({ data: alerts });
            },
            (e: Error) => setAlertsCardState({ errormsg: e.message })
        );
        wrapApiCall(
            authContext,
            async () => {
                const docs = await getManualRuns();
                setManualRunsCardState({ data: docs });
            },
            (e: Error) => setManualRunsCardState({ errormsg: e.message })
        );
    }, [selectedJobMode]);

    const getJobConfigs = async (jobMode = '') => {
        console.log(`${jobConfigId}${jobMode && ' AND job_mode_s:' + jobMode}`);
        const jobConfigs = await searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
            `${jobConfigId}${jobMode ? ' AND job_mode_s:' + jobMode : ''}`,
            DocTypes.JOB_CONFIG
        );

        return jobConfigs.data?.docs;
    };

    const getJobs = async (jobMode = '') => {
        const jobs = await searchService.search<JobSearchDocRaw, JobSearchDoc>(
            `${jobConfigId}${jobMode ? ' AND job_mode_s:' + jobMode : ''}`,
            DocTypes.JOB
        );

        return (jobs.data?.docs || []).filter((job) => job.jobStatus !== 'deleted');
    };

    const getAlerts = async (jobMode: string) => {
        const alerts = await searchService.search<AlertNotificationSearchDocRaw, AlertNotificationSearchDoc>(
            `${jobConfigId}${jobMode ? ' AND job_mode_s:' + jobMode : ''}`,
            DocTypes.ALERT_NOTIF
        );
        return alerts.data?.docs;
    };

    const getManualRuns = async () => {
        const results = await searchService.search<ManualRunSearchDocRaw, ManualRunSearchDoc>(
            `${jobConfigId} AND type_s:MANUAL_RUN`,
            DocTypes.MANUAL_RUN
        );
        return results.data?.docs;
    };

    const getSummary = async (mode: string) => {
        const now = new Date();
        const nowMinus30Days = new Date();
        nowMinus30Days.setDate(nowMinus30Days.getDate() - 30);
        const queryMode = mode === '' ? Mode.ALL : mode;
        const summary = await summaryService.getJobSummaryDatapoints(
            nowMinus30Days,
            now,
            1,
            RecordUnit.HOUR,
            jobConfigId,
            queryMode
        );
        return summary.filter(
            (s) => s.summaryType.job.mode !== Mode.DRAFT && (!mode || s.summaryType.job.mode === mode)
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    const createCard = <T extends unknown>(
        label: React.ReactElement | string,
        cardElement: React.ReactElement,
        cardState: CardState<T>,
        setCardState: React.Dispatch<React.SetStateAction<CardState<T>>>
    ) => {
        return {
            label: (
                <span className={classes.tabLabel} onClick={() => setCardState({ ...cardState, collapsed: false })}>
                    {label}
                </span>
            ),
            content: (
                <div className={classes.tabContent}>
                    {cardElement}
                    {cardState.loading && <LinearProgress />}
                    {cardState.errormsg && (
                        <ErrorMessage
                            message={cardState.errormsg}
                            onClose={() => setCardState({ ...cardState, errormsg: '' })}
                        />
                    )}
                </div>
            ),
        };
    };

    const getCrawlerInfoFieldBox = (caption: string, content: string | React.ReactElement | undefined): JSX.Element => {
        return (
            <Box className={classes.crawlerInfoFieldBox}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                    {caption}
                </Typography>
                {React.isValidElement(content) ? <div>{content}</div> : <Typography>{content || '-'}</Typography>}
            </Box>
        );
    };

    const CrawlerInformationOverview = (): JSX.Element => {
        return (
            <CollapsibleCard
                square
                collapsed={overviewCollapsed}
                onCollapsedChanged={setOverviewCollapsed}
                title={
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h6" color="textPrimary" component="span">
                            Crawler Information
                        </Typography>

                        {getCrawlerInfoFieldBox(
                            'Job Config Id',
                            <Typography>
                                {jobConfig?.jobConfigId}
                                &nbsp;
                                <CopyToClipboard
                                    tooltip="Copy Job Config Id"
                                    text2Copy={jobConfig?.jobConfigId || ''}
                                    size="inherit"
                                />
                            </Typography>
                        )}
                        {getCrawlerInfoFieldBox('Job Name', jobConfig?.jobConfigName)}
                        {getCrawlerInfoFieldBox(
                            `Domain${pluralSuffix(jobConfig?.domains?.length)}`,
                            jobConfig?.domains?.join(', ')
                        )}
                        <Select
                            displayEmpty
                            style={{ marginLeft: '6ch', minWidth: '16ch' }}
                            value={
                                jobModes.includes(selectedJobMode.toLowerCase()) ? selectedJobMode.toLowerCase() : ''
                            }
                            onChange={(event) => {
                                const mode = event.target.value as string;
                                setSelectedJobMode(mode);
                            }}
                        >
                            {jobModes.map((jobMode, idx) => (
                                <MenuItem key={idx} value={jobMode}>
                                    {`Mode: ${jobMode || 'All'}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            style={{ marginLeft: '6ch' }}
                            startIcon={<LaunchIcon />}
                            size="small"
                            color="primary"
                            onClick={() => launchCRWL(jobConfig?.jobConfigId)}
                        >
                            Open in CRWL
                        </Button>
                    </Box>
                }
            />
        );
    };

    const CrawlerInformationDetails = (): JSX.Element => {
        return (
            <div>
                <Collapse in={!overviewCollapsed} timeout="auto" unmountOnExit>
                    <Paper elevation={1} className={classes.halfPaddedBox}>
                        <Box display="flex" flexDirection="row">
                            {getCrawlerInfoFieldBox('Job Description', jobConfig?.jobConfigDesc)}
                            {getCrawlerInfoFieldBox('Agent Id', jobConfig?.agentId)}
                            {getCrawlerInfoFieldBox(
                                'Template',
                                jobConfig?.templateParameters && (
                                    <>
                                        <Typography display="inline">{jobConfig?.jobConfigTemplateName}</Typography>
                                        {jobConfig?.templateDescription && (
                                            <Tooltip title={jobConfig?.templateDescription}>
                                                <InfoOutlinedIcon
                                                    style={{
                                                        verticalAlign: 'center',
                                                        width: 16,
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </>
                                )
                            )}
                            {getCrawlerInfoFieldBox('Created By', jobConfig?.jcCrByName || jobConfig?.jcCrByGuid)}
                            {getCrawlerInfoFieldBox(
                                'Modfied Last On',
                                `${formatTimestamp(jobConfig?.jcUpAt)} by ${
                                    jobConfig?.jcUpByName || jobConfig?.jcUpByGuid
                                }`
                            )}
                            {getCrawlerInfoFieldBox(
                                `Starting URL${pluralSuffix(jobConfig?.jobStartUrl?.length)}`,
                                <Typography>
                                    {jobConfig?.jobStartUrl?.map((url: string, idx: number) => (
                                        <React.Fragment key={idx}>
                                            <Tooltip title="Open Starting URL in a new browser">
                                                <Link
                                                    className={classes.link}
                                                    onClick={(): void => {
                                                        launchUrl(url);
                                                    }}
                                                >
                                                    {url}
                                                </Link>
                                            </Tooltip>
                                            {idx < jobConfig?.jobStartUrl.length && <br />}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Collapse>
            </div>
        );
    };

    const getContent = (): JSX.Element => {
        return (
            <>
                {errormsg && <ErrorMessage message={errormsg} onClose={() => setErrormsg('')} />}

                {isLoading && <LinearProgress />}

                {jobConfig && (
                    <Box display="flex" flexDirection="row" height="100%">
                        <TabScroller
                            fixedRows={1}
                            activeTab={activeTab}
                            onActiveTabChanged={setActiveTab}
                            content={[
                                {
                                    label: '', // no label since it's a fixed row
                                    content: <CrawlerInformationOverview />,
                                },
                                {
                                    label: (
                                        <span className={classes.tabLabel} onClick={() => setOverviewCollapsed(false)}>
                                            <InfoOutlinedIcon fontSize="small" className={classes.sectionIcon} />
                                            Crawler Overview
                                        </span>
                                    ),
                                    content: <CrawlerInformationDetails />,
                                },

                                createCard(
                                    <>
                                        <HistoryIcon fontSize="small" className={classes.sectionIcon} />
                                        Crawling History
                                    </>,
                                    <CrawlingHistoryCard
                                        collapsed={!!crawlingHistoryCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setCrawlingHistoryCardState({
                                                    ...crawlingHistoryCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                        summary={crawlingHistoryCardState.data}
                                    />,
                                    crawlingHistoryCardState,
                                    setCrawlingHistoryCardState
                                ),
                                createCard(
                                    <>
                                        <AllInboxIcon fontSize="small" className={classes.sectionIcon} />
                                        Deliveries
                                    </>,
                                    <JobDeliveriesCard
                                        collapsed={!!deliveriesCardState.collapsed}
                                        jobConfigId={jobConfigId}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setDeliveriesCardState({
                                                    ...deliveriesCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                        onUrlClick={launchUrl}
                                    />,
                                    deliveriesCardState,
                                    setDeliveriesCardState
                                ),
                                createCard(
                                    <>
                                        <FilterListIcon fontSize="small" className={classes.sectionIcon} />
                                        Duplicates
                                    </>,
                                    <JobDuplicateDeliveriesCard
                                        jobConfigId={jobConfigId}
                                        collapsed={!!duplicatesCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setDuplicatesCardState({
                                                    ...duplicatesCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                    />,
                                    duplicatesCardState,
                                    setDuplicatesCardState
                                ),
                                createCard(
                                    <>
                                        <DeveloperBoardIcon fontSize="small" className={classes.sectionIcon} />
                                        Post - Processing
                                    </>,
                                    <PostProcessingCard
                                        jobConfigId={jobConfigId}
                                        collapsed={!!postprocessingCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setPostprocessingCardState({
                                                    ...postprocessingCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                    />,
                                    postprocessingCardState,
                                    setPostprocessingCardState
                                ),
                                createCard(
                                    <>
                                        <ScheduleIcon fontSize="small" className={classes.sectionIcon} />
                                        Schedule
                                    </>,
                                    <JobSchedulesCard
                                        collapsed={!!schedulesCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setSchedulesCardState({ ...schedulesCardState, collapsed: collapsed });
                                            }
                                        }}
                                        jobs={jobs}
                                        jobVersionMapping={jobVersionMapping}
                                    />,
                                    schedulesCardState,
                                    setSchedulesCardState
                                ),
                                createCard(
                                    <>
                                        <NotificationsIcon fontSize="small" className={classes.sectionIcon} />
                                        Alerts
                                    </>,
                                    <JobAlertsCard
                                        collapsed={!!alertsCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setAlertsCardState({ ...alertsCardState, collapsed: collapsed });
                                            }
                                        }}
                                        alertNotifications={alertsCardState.data}
                                        onEditButtonClicked={() => history.push(getAlertConfigRoute(jobConfigId))}
                                    />,
                                    alertsCardState,
                                    setAlertsCardState
                                ),
                                createCard(
                                    <>
                                        <CallSplitIcon
                                            fontSize="small"
                                            className={classes.sectionIcon}
                                            sx={{ transform: 'rotate(180deg)' }}
                                        />
                                        Routing History
                                    </>,
                                    <RoutingHistoryCard
                                        collapsed={!!routingHistoryCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setRoutingHistoryCardState({
                                                    ...routingHistoryCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                        jobConfigId={jobConfigId}
                                        routingHistory={routingHistoryCardState.data}
                                    />,
                                    routingHistoryCardState,
                                    setRoutingHistoryCardState
                                ),
                                createCard(
                                    <>
                                        <ErrorOutlineIcon fontSize="small" className={classes.sectionIcon} />
                                        Errors
                                    </>,
                                    <JobErrorsCard
                                        collapsed={!!jobErrorsCardState.collapsed}
                                        jobConfigId={jobConfigId}
                                        jobMode={selectedJobMode}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setJobErrorsCardState({
                                                    ...jobErrorsCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                    />,
                                    jobErrorsCardState,
                                    setJobErrorsCardState
                                ),
                                createCard(
                                    <>
                                        <ShoppingCartOutlinedIcon fontSize="small" className={classes.sectionIcon} />
                                        Work Items
                                    </>,
                                    <JobWorkItemsCard
                                        collapsed={!!jobWorkItemsCardState.collapsed}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setJobWorkItemsCardState({
                                                    ...jobWorkItemsCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                        jobConfigId={jobConfigId}
                                        workItems={jobWorkItemsCardState.data}
                                    />,
                                    jobWorkItemsCardState,
                                    setJobWorkItemsCardState
                                ),
                                createCard(
                                    <>
                                        <RunIcon fontSize="small" className={classes.sectionIcon} />
                                        Manual Runs
                                    </>,
                                    <ManualRunsCard
                                        collapsed={!!manualRunsCardState.collapsed}
                                        manualRuns={manualRunsCardState.data ?? []}
                                        onCollapsedChanged={(collapsed, ctrlKey) => {
                                            if (ctrlKey) {
                                                updateCollapsedState(collapsed);
                                            } else {
                                                setManualRunsCardState({
                                                    ...manualRunsCardState,
                                                    collapsed: collapsed,
                                                });
                                            }
                                        }}
                                    />,
                                    manualRunsCardState,
                                    setManualRunsCardState
                                ),
                            ]}
                        />
                    </Box>
                )}
            </>
        );
    };

    return (
        <Content subtitle="Job Configuration" quickLinkTail={`/JC ${encodeURIComponent(jobConfigId)}`}>
            {getContent()}
        </Content>
    );
};

export default JobConfigDetails;
