import { config } from '../config';
import Api from './api';
import { AbstractApi } from '@webacq/wa-shared-servicewrappers';
import {
    UserInfo,
    JobConfigVersionData,
    JobCfgIdWorkItems,
    ProvenanceRecord,
    AlysAlertConfig,
} from '@webacq/wa-shared-definitions';
import axios, { ResponseType as AxiosResponseType } from 'axios';
import packageJson from '../../package.json';

export interface PermissionInfo {
    action: string;
    permission: 'allowed' | 'denied';
    message?: string;
}

export interface CheckAuthResponseType {
    isAuthenticated: boolean;
    userId?: string;
    firstName?: string;
    lastName?: string;
    permissions?: PermissionInfo[];
}

interface BBUserInfo {
    uuid: number;
    name: string;
    firm: string;
}

export interface QueryBBUserIdsResponseType {
    matches: BBUserInfo[];
    message?: string;
}

export interface ResolveBBUserIdsResponseType {
    users: BBUserInfo[];
    message?: string;
}

// export interface HTMLDiffResultType {
//     html: string;
//     numInserts: number;
//     numDeletes: number;
//     numUpdates: number;
//     hasInvisibleChanges: boolean;
// }

// export type HTMLDiffResultType = string;

export interface ErrorResponseType {
    message: string;
}

const apiUrl = config.apiUrl;

class WAMServiceWrapper {
    constructor(apiWrapper: AbstractApi) {
        this.apiWrapper = apiWrapper;
    }

    public apiWrapper: AbstractApi;

    public authenticate(referrer: string): void {
        window.location.replace(`${apiUrl}/auth/login/bloomberg?referrer=${encodeURIComponent(referrer)}`);
    }

    public async checkAuth(): Promise<CheckAuthResponseType> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const status = await this.apiWrapper.get<any>(
                `wam/ui/authstatus?appName=hub&appVersion=${packageJson.version}`
            );
            return {
                isAuthenticated: true,
                userId: status.userId,
                firstName: status.firstName,
                lastName: status.lastName,
                permissions: status.permissions,
            };
        } catch (e) {
            return {
                isAuthenticated: false,
            };
        }
    }

    public async logout(): Promise<void> {
        await this.apiWrapper.get('auth/logout');
    }

    public async getUserName(userGuid: string): Promise<UserInfo> {
        const url = `wam/api/store/user/${userGuid}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const userInfo = await this.apiWrapper.get<any>(url);
        if (userInfo && userInfo.user) {
            return {
                firstName: userInfo.user.firstName,
                lastName: userInfo.user.lastName,
            };
        }

        return {};
    }

    public async queryProvenance(params: Record<string, string>): Promise<{ records: ProvenanceRecord[] }> {
        let url = 'wam/api/provenance/query';
        let separator = '?';

        for (const param in params) {
            url += `${separator}${encodeURIComponent(param)}=${encodeURIComponent(params[param] as string)}`;
            separator = '&';
        }

        console.log(url);

        const result = await this.apiWrapper.get<{ records: ProvenanceRecord[] }>(url);
        return result;
    }

    public async getJobConfigRoutingHistory(jobConfigId: string): Promise<JobConfigVersionData> {
        const url = `wam/api/store/jobs/${jobConfigId}/routing-history`;
        const routingInfo = await this.apiWrapper.get<JobConfigVersionData>(url);

        if (routingInfo) {
            // convert Date elements into their proper types
            // this is ugly. should find a better way! - MR
            Object.values(routingInfo.history).forEach((r1) =>
                Object.values(r1).forEach((r2) => {
                    if (r2.createdOn) {
                        r2.createdOn = new Date(r2.createdOn);
                    }
                    if (r2.start) {
                        r2.start = new Date(r2.start);
                    }
                    if (r2.end) {
                        r2.end = new Date(r2.end);
                    }
                    if (r2.scheduleUpdates) {
                        r2.scheduleUpdates.forEach((r3) => {
                            if (r3.actionOn) {
                                r3.actionOn = new Date(r3.actionOn);
                            }
                        });
                    }
                })
            );
        }

        return routingInfo;
    }

    public async getObject(
        objectUrl: string,
        responseDataType?: AxiosResponseType,
        type = 'bcos'
    ): Promise<{ headers: Record<string, string>; status: number; content: unknown }> {
        // Use axios directly instead of the API wrapper as we need to return the response headers
        const url = `${apiUrl}/wam/ui/object?url=${window.encodeURIComponent(objectUrl)}&type=${type}`;
        const response = await axios.get(url, {
            withCredentials: true,
            validateStatus: () => true,
            responseType: responseDataType,
        });

        return {
            headers: response.headers,
            status: response.status,
            content: response.data,
        };
    }


    public fetchBcosContent = async (url: string, base64?: boolean): Promise<{ headers: Record<string, string>; content: unknown } | undefined> => {
        const contentToBase64 = (byteContent: ArrayBuffer) => {
            return Buffer.from(byteContent).toString('base64');
        };

        try {
            const responseDataType = base64 ? 'arraybuffer' : undefined;

            const response = await this.getObject(url, responseDataType);

            return {
                headers: response.headers,
                content: base64 ? contentToBase64(response.content as ArrayBuffer) : response.content,
            };
        } catch (e) {
            console.error(e);
            return undefined;
        }
    };


    public async getJobConfigWorkitems(jobConfigId: string): Promise<JobCfgIdWorkItems> {
        const url = `wam/api/store/jobs/${jobConfigId}/workitems`;
        const workitems = await this.apiWrapper.get<JobCfgIdWorkItems>(url);

        return workitems;
    }

    public async queryBBUserIds(query: string, maxResults: number): Promise<QueryBBUserIdsResponseType> {
        const url = `wam/ui/bb-uuid-query?query=${window.encodeURIComponent(query)}&maxResults=${maxResults}`;
        const result = await this.apiWrapper.get<QueryBBUserIdsResponseType>(url);

        return result;
    }

    public async resolveBBUserIds(uuids: number[]): Promise<ResolveBBUserIdsResponseType> {
        const url = `wam/ui/resolve-bb-uuids?uuids=${window.encodeURIComponent(JSON.stringify(uuids))}`;
        const result = await this.apiWrapper.get<ResolveBBUserIdsResponseType>(url);

        return result;
    }

    public getHTMLDiffUrl(bcosUrl1: string, bcosUrl2: string, basehref?: string, showMarker = false, strict = false) {
        const apiEndpoint = `crawl/ui/html-diff`;

        let url = `${apiUrl}/${apiEndpoint}?url1=${encodeURIComponent(bcosUrl1)}&url2=${encodeURIComponent(bcosUrl2)}`;

        if (basehref) {
            url += `&basehref=${encodeURIComponent(basehref)}`;
        }

        if (strict) {
            url += `&strict=true`;
        }

        if (showMarker) {
            url += `&showmarker=true`;
        }

        return url;
    }

    public async getHTMLDiff(
        bcosUrl1: string,
        bcosUrl2: string,
        basehref?: string,
        showMarker = false,
        strict = false
    ) {
        const apiEndpoint = `crawl/ui/html-diff`;

        let url = `${apiEndpoint}?url1=${encodeURIComponent(bcosUrl1)}&url2=${encodeURIComponent(bcosUrl2)}`;

        if (strict) {
            url += `&strict=true`;
        }

        if (showMarker) {
            url += `&showmarker=true`;
        }

        if (basehref) {
            url += `&basehref=${encodeURIComponent(basehref)}`;
        }

        return await this.apiWrapper.get<string>(url);
    }

    public async getAlertTemplates(): Promise<AlysAlertConfig[]> {
        const url = `crawl/api/store/alert-templates`;
        const result = await this.apiWrapper.get<AlysAlertConfig[] | ErrorResponseType>(url);

        if ('message' in result) {
            throw Error(result['message']);
        }

        if ('alertTemplates' in result) {
            const alysAlertConfigs = result['alertTemplates'] as AlysAlertConfig[];
            return alysAlertConfigs;
        }

        return [];
    }
}

const wamService = new WAMServiceWrapper(new Api(apiUrl));
export default wamService;
