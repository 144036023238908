import React from 'react';
import { Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { Edit as EditIcon } from '@mui/icons-material';
import { AlertNotificationSearchDoc } from '@webacq/wa-shared-definitions';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import CardTitle from './CardTitle';

const useStyles = makeStyles((theme: Theme) => ({
    buttonBox: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface JobAlertsCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    alertNotifications?: AlertNotificationSearchDoc[];
    onEditButtonClicked?: () => void;
}

const JobAlertsCard = (props: JobAlertsCardProps): JSX.Element => {
    const classes = useStyles();

    const getAlertConditions = (alertNotification: AlertNotificationSearchDoc): React.ReactElement => {
        const numConditions = Math.min(
            alertNotification.alertMetricName?.length || 0,
            alertNotification.alertOperatorName?.length || 0,
            alertNotification.alertOperands?.length || 0,
            alertNotification.alertMetricValue?.length || 0
        );

        const rows: React.ReactElement[] = [];

        for (let idx = 0; idx < numConditions; idx++) {
            const metric = (alertNotification.alertMetricName || [])[idx];
            const operator = (alertNotification.alertOperatorName || [])[idx];
            const operands = JSON.parse((alertNotification.alertOperands || [])[idx]) as number[];
            const value = (alertNotification.alertMetricValue || [])[idx];

            rows.push(
                <Typography key={idx} variant="body2" color="textSecondary">
                    {`${metric} (${value}) is ${operator.toLowerCase().replace('_', ' ')} ${
                        operands.length > 0
                            ? // format as "op1, op2, op3 ... and opN"
                              operands
                                  .map((val) => '' + val)
                                  .reduce(
                                      (val, currentVal, idx, src) =>
                                          '' + val + (idx < src.length - 1 ? ', ' : ' and ') + currentVal
                                  )
                            : ''
                    }`}
                </Typography>
            );
        }
        return (
            <Box display="flex" flexDirection="column">
                {rows}
            </Box>
        );
    };

    const columns = [
        {
            header: 'Job Mode',
            accessorKey: 'jobMode',
        },
        {
            header: 'Run Location',
            accessorKey: 'jobRunLocation',
        },
        {
            header: 'Type',
            accessorKey: 'alertNotifType',
        },
        {
            header: 'End of Observation Period',
            accessorKey: 'alertNotifAqEnd',
            filterVariant: 'date',
        },
        {
            header: 'Conditions',
            id: 'conditions',
            accessorFn: (rowData) => getAlertConditions(rowData),
        },
    ] as PaginatedTableColumn<AlertNotificationSearchDoc>[];

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={
                <CardTitle
                    title="Alerts"
                    recordCount={props.alertNotifications?.length || 0}
                    collapsedMode={props.collapsed}
                    extraElement={
                        !props.collapsed ? (
                            <Box display="flex" className={classes.buttonBox} flexGrow={1} justifyContent="flex-end">
                                <Button
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    onClick={() => {
                                        if (props.onEditButtonClicked) {
                                            props.onEditButtonClicked();
                                        }
                                    }}
                                >
                                    Edit Alert Configuration
                                </Button>
                            </Box>
                        ) : undefined
                    }
                />
            }
        >
            {props.alertNotifications && props.alertNotifications.length > 0 ? (
                <PaginatedTable
                    columns={columns}
                    rows={props.alertNotifications || []}
                    initialSorting={[
                        {
                            id: 'alertNotifAqEnd',
                            desc: true,
                        },
                    ]}
                />
            ) : (
                <Box className={classes.contentBox} fontStyle="italic">
                    <Typography variant="body2">No alert notifications found</Typography>
                </Box>
            )}
        </CollapsibleCard>
    );
};

export default JobAlertsCard;
