import React from 'react';
import { PermissionInfo } from '../services/wamService';

export interface AuthDataType {
    isAuthenticated: boolean;
    userId?: string;
    userName?: string;
    permissions?: PermissionInfo[];
}

const defaultAuthData: AuthDataType = {
    isAuthenticated: true,
};

export interface AuthContextType {
    authData: AuthDataType;
    setAuthData: (value: AuthDataType) => void;
}

const AuthContext = React.createContext<AuthContextType>({
    authData: defaultAuthData,
    setAuthData: (data: AuthDataType) => console.debug(data),
});

export const useAuth = (): AuthContextType => React.useContext(AuthContext);

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider = (props: AuthProviderProps): JSX.Element => {
    const [authData, setAuthData] = React.useState<AuthDataType>(defaultAuthData);

    React.useEffect(() => {
        setAuthData(defaultAuthData);
    }, []);

    return <AuthContext.Provider value={{ authData, setAuthData }}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
