import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DocTypeName, DocTypes, DocTypeColors } from '../../shared/constants';
import DocTypeIcon from '../DocTypeIcon';

const useStyles = (color: string) =>
    makeStyles(() => ({
        wrapper: {
            color,
        },
        icon: {
            marginRight: 4,
        },
    }));

interface CardTypeHeaderProps {
    type: DocTypes;
    showIcon?: boolean;
}

const CardTypeHeader = (props: CardTypeHeaderProps): JSX.Element => {
    const classes = useStyles(DocTypeColors[props.type])();

    return (
        <Box fontStyle="italic">
            <Typography variant="body2" color="textSecondary" gutterBottom component="span" className={classes.wrapper}>
                {props.showIcon && (
                    <span className={classes.icon}>
                        <DocTypeIcon type={props.type} />
                    </span>
                )}
                {DocTypeName(props.type)}
            </Typography>
        </Box>
    );
};

export default CardTypeHeader;
