import React from 'react';
import { Card, CardContent, CardActions, Typography, ButtonBase, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SummaryArrayType } from '@webacq/wa-shared-definitions';
import { formatFieldData } from './SummaryUtil';
import { SummaryColors } from '../../shared/constants';

interface SummaryCardsProps {
    summaries: SummaryArrayType;
    cardSelectedHandler: (field: string, color: string, fieldAlias: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: theme.spacing(40),
        height: theme.spacing(6),
        // marginBottom: theme.spacing(1),
    },
    cardContent: {
        width: '100%',
    },
    buttonBase: {
        width: '100%',
        height: '100%',
    },
    cardActions: {
        height: '100%',
        padding: 0,
    },
}));

const cardNameMapping = {
    numErrors: '# of Errors',
    numRuns: '# of Runs',
    numUrlFollowed: 'Avg # of Urls Followed',
    numUrlSeen: 'Avg # of Urls Seen',
    numDeliveries: 'Avg # of Deliveries',
    bytesDownloaded: 'Bytes downloaded',
    duration: 'Avg. Duration',
};

const cardBorderColorMapping = {
    numDeliveries: SummaryColors.NumDeliveries,
    numErrors: SummaryColors.NumErrors,
    numRuns: SummaryColors.NumRuns,
    numUrlFollowed: SummaryColors.NumUrlsFollowed,
    numUrlSeen: SummaryColors.NumUrlsSeen,
    bytesDownloaded: SummaryColors.BytesDownloaded,
    duration: SummaryColors.Duration,
};

const avgfields = ['numUrlFollowed', 'numUrlSeen', 'numDeliveries', 'duration'];

const SummaryCards = (props: SummaryCardsProps): JSX.Element => {
    const classes = useStyles();

    const cardsValues = React.useMemo(() => {
        if (props.summaries.length == 0) {
            return {};
        } else {
            const summaryType = props.summaries[0].summaryType.type;
            let fields;
            if (summaryType === 'global') {
                fields = ['numRuns', 'numErrors', 'numUrlSeen', 'numUrlFollowed', 'numDeliveries'];
            } else if (summaryType === 'zone') {
                fields = ['numRuns', 'numErrors', 'numUrlSeen', 'numUrlFollowed', 'numDeliveries'];
            } else if (summaryType === 'job') {
                fields = ['numRuns', 'numErrors', 'numUrlSeen', 'numUrlFollowed', 'numDeliveries', 'duration'];
            } else if (summaryType === 'domain') {
                fields = ['numRuns', 'numErrors', 'numUrlSeen', 'numUrlFollowed', 'bytesDownloaded'];
            } else if (summaryType === 'agent') {
                fields = ['numRuns', 'numErrors', 'numUrlSeen', 'numUrlFollowed', 'numDeliveries'];
            } else {
                throw Error(`Invalid summary type`);
            }

            const cards = {};

            for (const summary of props.summaries) {
                for (const field of fields) {
                    if (cards[field] === undefined) {
                        cards[field] = 0;
                    }

                    cards[field] +=
                        typeof summary.metadata[field] === 'string'
                            ? parseInt(summary.metadata[field])
                            : summary.metadata[field];
                }
            }

            for (const field of Object.keys(cards)) {
                if (avgfields.includes(field)) {
                    cards[field] = Math.round(cards[field] / cards['numRuns']) || 0;
                }
            }

            return cards;
        }
    }, [props.summaries]);

    const onCardClickedHandler = (field: string) => {
        props.cardSelectedHandler(field, cardBorderColorMapping[field], cardNameMapping[field]);
    };

    return (
        <>
            <Box display="flex" flexDirection="column" sx={{ justifyContent: 'space-between' }}>
                {Object.keys(cardsValues).map((field) => (
                    <Card
                        key={field}
                        className={classes.card}
                        style={{ border: '1px solid ' + cardBorderColorMapping[field] }}
                    >
                        <CardActions className={classes.cardActions}>
                            <ButtonBase className={classes.buttonBase} onClick={() => onCardClickedHandler(field)}>
                                <CardContent className={classes.cardContent}>
                                    <Box display="flex">
                                        <Typography noWrap variant="body1" align="left" flexGrow={1}>
                                            {cardNameMapping[field]}
                                        </Typography>
                                        <Typography noWrap variant="body1">
                                            {formatFieldData(cardsValues[field], field)}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </ButtonBase>
                        </CardActions>
                    </Card>
                ))}
            </Box>
        </>
    );
};

export default SummaryCards;
