import React, { useEffect, useState } from 'react';
import {
    Paper,
    Table,
    Tabs,
    Tab,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
    Box,
    Link,
} from '@mui/material';
import Content from '../components/Content';
import { Launch as LaunchIcon, Settings as GearIcon } from '@mui/icons-material';
import axios from 'axios';
import { TabContext, TabPanel } from '@mui/lab';

interface BpaasRelease {
    name: string;
    state: string;
    link: string;
    tag: string;
    tagInformation: { description: string };
}

interface ExternalLink {
    link: string;
    title: string;
}

interface BpaasApp {
    name: string;
    link: string;
    externalLinks: ExternalLink[];
    releases?: BpaasRelease[];
}

const UI_URL_BASE = 'https://ui.bpaas.prod.bloomberg.com/#';
const API_URL_BASE = 'https://bpaas2-api-prod.bpaas-cas.admin.bloomberg.com/public/usersapi/v1';

const Deployment = (): JSX.Element => {
    const [bpaasApps, setBpaasApps] = useState<BpaasApp[]>([]);
    const [selectedTab, setSelectedTab] = useState('BPaaS');
    const [cachedTagDescriptions, setCachedTagDescriptions] = useState<Record<string, string>>({});

    useEffect(() => {
        (async () => {
            try {
                const url = `https://bpaas2-api-prod.bpaas-cas.admin.bloomberg.com/public/usersapi/v1/tenancies/webacq/applications?size=40`;
                const { data } = await axios(url);
                const apps = data.properties?.applications as BpaasApp[];
                apps.sort((a, b) => a.name.localeCompare(b.name));

                setBpaasApps(apps);
            } catch (e) {
                console.error('Error getting BPaaS applications:', e);
            }
        })();
    }, []);

    const makeUILink = (apiLink: string) => apiLink?.replace(API_URL_BASE, UI_URL_BASE);

    const getCommitMessage = async (app: BpaasApp, stage: string) => {
        const key = `${app.name}:${stage}`;
        if (cachedTagDescriptions[key]) {
            return cachedTagDescriptions[key];
        }
        try {
            const resp = await axios(app.link + '/releases');
            // console.log('resp', resp);
            const release = resp.data?.properties?.releases.find((r: BpaasRelease) => r.name === stage);
            if (release) {
                const description = window.atob(release.tagInformation.description);
                setCachedTagDescriptions((cache) => ({
                    ...cache,
                    [`${app.name}:${stage}`]: description,
                }));
                return description;
            }
        } catch (e) {
            console.error(e);
        }
        return '';
    };
    const getHashFromTag = (tag: string) => tag.split('-').shift();

    const makeReleaseCell = (app: BpaasApp, release?: BpaasRelease, pendingDeployment?: boolean) => {
        if (!release) return <div />;

        const stage = release.name;
        const tag = release.tag;
        const hash = getHashFromTag(tag);
        const bbghUrl = app.externalLinks?.find((l) => l.title === 'BBGitHub')?.link ?? '';
        const commitUrl = bbghUrl ? `${bbghUrl}/commit/${hash}` : '#';
        const editReleaseLink = makeUILink(app.link) + `/releases/${stage}/settings/edit`;

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                    placement="left"
                    title={<h2>{cachedTagDescriptions[`${app.name}:${stage}`]}</h2>}
                    onOpen={() => getCommitMessage(app, stage)}
                >
                    <Link
                        href={commitUrl}
                        target="_blank"
                        rel="noreferrer"
                        color={pendingDeployment ? '#84c7ff' : 'textPrimary'}
                    >
                        {tag}
                    </Link>
                </Tooltip>
                {pendingDeployment && (
                    <Tooltip title="Edit release" placement="right">
                        <IconButton
                            size="small"
                            style={{ padding: '0 6px 0' }}
                            onClick={() => window.open(editReleaseLink, 'blank')}
                            color="primary"
                        >
                            <GearIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    };

    const makeStackerLink = (name: string, envId: number) => {
        const url = `https://stacker.dev.bloomberg.com/environments/${envId}/apps`;
        return (
            <TableCell>
                <IconButton size="small" onClick={() => window.open(url, 'blank')}>
                    <LaunchIcon />
                </IconButton>
                &nbsp;&nbsp;
                <Link color="inherit" href={url} target="_blank">
                    <b>{name}</b>
                </Link>
            </TableCell>
        );
    };

    const stages = [/dev/, /alpha/, /beta/, /prod-(?!s2b)/, /prod-s2b/];

    return (
        <Content subtitle="Webacq Deployment Dashboard">
            <Box display="flex" flexDirection="column" height="100%" overflow="auto" padding="1em">
                <TabContext value={selectedTab}>
                    <Paper>
                        <Tabs value={selectedTab} onChange={(ev, tab) => setSelectedTab(tab)}>
                            <Tab label="BPaaS" value="BPaaS" tabIndex={0} />
                            <Tab label="Stacker" value="Stacker" tabIndex={1} />
                        </Tabs>
                    </Paper>

                    <TabPanel value={'BPaaS'} tabIndex={0}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ minWidth: 250 }}>App</TableCell>
                                        <TableCell>dev-sn2a</TableCell>
                                        <TableCell>alpha-sn1a</TableCell>
                                        <TableCell>beta-s0a</TableCell>
                                        <TableCell>prod-s2a</TableCell>
                                        <TableCell>prod-s2b</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bpaasApps.map((app) => {
                                        const dev = app.releases?.find((r) => r.name.includes('dev-sn2a'));
                                        const devHash = getHashFromTag(dev?.tag ?? '');
                                        const pendingDeployments =
                                            app.releases?.findIndex((r) => !!r && getHashFromTag(r.tag) !== devHash) ||
                                            -1;

                                        return (
                                            <TableRow
                                                key={app.name}
                                                style={{ background: pendingDeployments > 0 ? '#192740' : 'inherit' }}
                                            >
                                                <TableCell>
                                                    <IconButton
                                                        size="small"
                                                        style={{ color: '#84c7ff' }}
                                                        onClick={() => window.open(makeUILink(app.link), 'blank')}
                                                    >
                                                        <LaunchIcon />
                                                    </IconButton>
                                                    &nbsp;&nbsp;
                                                    <Link color="#84c7ff" href={makeUILink(app.link)} target="_blank">
                                                        <b>{app.name}</b>
                                                    </Link>
                                                </TableCell>
                                                {stages.map((stage, i) => {
                                                    const devStage = (i > 0 && stages[0]) || undefined;
                                                    const releases = app.releases?.filter((r) => stage.test(r.name));
                                                    const previousReleases =
                                                        devStage && app.releases?.filter((r) => devStage.test(r.name));

                                                    if (!releases || !releases.length) return <TableCell key={i} />;

                                                    return (
                                                        <TableCell key={i}>
                                                            {releases.map((release, j) => {
                                                                const pendingDeployment =
                                                                    previousReleases?.length &&
                                                                    previousReleases.some(
                                                                        (pr) =>
                                                                            getHashFromTag(release.tag) !==
                                                                            getHashFromTag(pr.tag)
                                                                    );
                                                                return (
                                                                    <div
                                                                        key={j}
                                                                        style={{ padding: '4px 0', display: 'flex' }}
                                                                    >
                                                                        {releases.length > 1 && (
                                                                            <span style={{ color: '#ffa028' }}>
                                                                                {release.name}:&nbsp;
                                                                            </span>
                                                                        )}
                                                                        {makeReleaseCell(
                                                                            app,
                                                                            release,
                                                                            !!pendingDeployment
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    <TabPanel value={'Stacker'} tabIndex={1}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Cluster group</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {makeStackerLink('wam-dev', 2881)}

                                        <TableCell>general-dev</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {makeStackerLink('wam-beta', 3009)}

                                        <TableCell>general-beta</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {makeStackerLink('wam-beta-dmz', 2981)}

                                        <TableCell>dmz-beta</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {makeStackerLink('wam-prod', 4298)}

                                        <TableCell>general-prod</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {makeStackerLink('wam-prod-dmz', 4326)}

                                        <TableCell>dmz-prod</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </TabContext>
            </Box>
        </Content>
    );
};

export default Deployment;
