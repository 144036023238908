import React, { useState } from 'react';
import RoutingHistoryGraph from './RoutingHistoryGraph';
import {
    RRuleFreq,
    JobConfigModeData,
    ScheduleStatusLocation,
    JobConfigVersionData,
} from '@webacq/wa-shared-definitions';
import { RoutingHistoryRow, GraphData } from './RoutingHistoryGraphInterfaces';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { Box, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RunLocationMapping } from '../../../shared/constants';
import CardTitle from '../CardTitle';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(0),
    },
}));

interface RoutingHistoryCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    jobConfigId: string;
    routingHistory?: JobConfigVersionData;
}

export default function RoutingHistoryCard(props: RoutingHistoryCardProps): JSX.Element {
    const classes = useStyles();

    const [tableData, setTableData] = useState<RoutingHistoryRow[]>([]);
    const [graphData, setGraphData] = useState<GraphData>({ nodes: [], edges: [] });

    React.useEffect(() => {
        if (props.routingHistory) {
            getGraphData(props.routingHistory);
            loadTableInfo(props.routingHistory);
        }
    }, [props.routingHistory]);

    const getGraphData = (rdata: JobConfigVersionData) => {
        const node_spacing = 30;
        const height = -30;
        const sub_node_spacing = 10;
        const between_version_spacing = 15;
        /*
        for every test node
        */
        const nodes = [];
        const edges = [];
        let current_test_node_pos = 0;
        let current_live_node_pos = 0;
        const live_indicator_start = -node_spacing;
        let live_indicator_start_added = false;
        let previousLiveVersion = null;
        let previousTestVersion = null;
        if (rdata) {
            for (const version of Object.keys(rdata.history)) {
                const testModeData: JobConfigModeData = rdata.history[version].test;
                let id = `${version}-T`;
                const label = `V${version}`;
                if (testModeData) {
                    if (current_test_node_pos > 0) {
                        current_test_node_pos += between_version_spacing;
                    }

                    const endAt = current_test_node_pos + node_spacing;

                    nodes.push({
                        data: { id: id, label: label, type: 'version' },
                        position: { x: current_test_node_pos, y: 0 },
                        locked: true,
                        grabbable: false,
                    });
                    if (current_test_node_pos == 0) {
                        nodes.push({
                            data: { id: 'test', label: 'TEST', type: 'start-node' },
                            position: { x: -node_spacing, y: 0 },
                            locked: true,
                            grabbable: false,
                        });
                        edges.push({ data: { source: 'test', target: id, label: '', type: 'C' } });
                    } else {
                        const prevVersion = `${previousTestVersion}-T-E`;
                        edges.push({ data: { source: prevVersion, target: id, label: '', type: 'C' } });
                    }

                    if (testModeData.scheduleUpdates && testModeData.scheduleUpdates.length) {
                        current_test_node_pos += sub_node_spacing;
                        nodes.push({
                            data: { id: `${id}-Sch`, label: 'S', type: 'schedule' },
                            position: { x: current_test_node_pos, y: 0 },
                            //color: '#AED6F1',
                            locked: true,
                            grabbable: false,
                        });
                        edges.push({ data: { source: id, target: `${id}-Sch`, label: '' } });
                        edges.push({ data: { source: `${id}-Sch`, target: `${id}-E`, label: '' } });
                    } else {
                        edges.push({ data: { source: id, target: `${id}-E`, label: '' } });
                    }
                    current_test_node_pos = endAt;
                    nodes.push({
                        data: { id: `${id}-E`, label: '', type: 'version-e' },
                        position: { x: current_test_node_pos, y: 0 },
                        locked: true,
                        grabbable: false,
                    });
                    previousTestVersion = parseInt(version);
                }
                const routingStart = `${id}-E`;
                const liveModeData: JobConfigModeData = rdata.history[version].live;
                current_live_node_pos = current_test_node_pos;

                if (liveModeData && Object.keys(liveModeData).length) {
                    id = `${version}-P`;
                    const endAt = current_live_node_pos + node_spacing;
                    nodes.push({
                        data: { id: id, label: label, type: 'version' },
                        position: { x: current_live_node_pos, y: height },
                        locked: true,
                        grabbable: false,
                    });
                    if (live_indicator_start_added == false) {
                        nodes.push({
                            data: { id: 'live', label: 'LIVE', type: 'start-node' },
                            position: { x: live_indicator_start, y: height },
                            locked: true,
                            grabbable: false,
                        });
                        edges.push({ data: { source: 'live', target: id, label: '', type: 'C' } });
                        live_indicator_start_added = true;
                    } else {
                        const prevVersion = `${previousLiveVersion}-P-E`;
                        edges.push({ data: { source: prevVersion, target: id, label: '', type: 'C' } });
                    }
                    if (liveModeData.scheduleUpdates && liveModeData.scheduleUpdates.length) {
                        current_live_node_pos += sub_node_spacing;
                        nodes.push({
                            data: { id: `${id}-Sch`, label: 'S', type: 'schedule' },
                            position: { x: current_live_node_pos, y: height },
                            locked: true,
                            grabbable: false,
                        });
                        edges.push({ data: { source: id, target: `${id}-Sch`, label: '' } });
                        edges.push({ data: { source: `${id}-Sch`, target: `${id}-E`, label: '' } });
                    } else {
                        edges.push({ data: { source: id, target: `${id}-E`, label: '' } });
                    }
                    current_live_node_pos = endAt;
                    nodes.push({
                        data: { id: `${id}-E`, label: '', type: 'version-e' },
                        position: { x: current_live_node_pos, y: height },
                        locked: true,
                        grabbable: false,
                    });

                    //join the end of test to start of live mode
                    edges.push({ data: { source: routingStart, target: `${id}`, label: '', type: 'R' } });
                    previousLiveVersion = parseInt(version);
                }
            }
        }

        setGraphData({ nodes: nodes, edges: edges });
    };

    const loadTableInfo = (rdata: JobConfigVersionData) => {
        if (rdata) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const getDescription = (iCalRrule: any): string => {
                if (!iCalRrule) {
                    return '[invalid schedule]';
                }

                const { freq, interval } = iCalRrule;

                switch (freq) {
                    case RRuleFreq.HOURLY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} hour${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.MINUTELY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} minute${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.SECONDLY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} second${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.YEARLY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} year${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.MONTHLY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} month${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.WEEKLY:
                        return `Runs every ${interval === 1 ? '' : interval || ''} week${interval !== 1 ? 's' : ''}`;

                    case RRuleFreq.DAILY:
                    default:
                        return 'Runs daily';
                }
            };

            const gridData: RoutingHistoryRow[] = [];

            for (const version of Object.keys(rdata.history)) {
                for (const mode of Object.keys(rdata.history[version])) {
                    const modeData = rdata.history[version][mode];
                    if (Object.keys(modeData).length && modeData.locationSchedule) {
                        const gRow: RoutingHistoryRow = {
                            version: version,
                            mode: mode,
                            createdBy: modeData.createdByName,
                            createdOn: new Date(modeData.createdOn),
                            start: new Date(modeData.start),
                            end: new Date(modeData.end),
                        };
                        for (const location of Object.keys(modeData.locationSchedule)) {
                            if (modeData.locationSchedule[location].schedule) {
                                const schedule = JSON.parse(modeData.locationSchedule[location].schedule);
                                modeData.locationSchedule[location].description = getDescription(
                                    schedule.timepoint?.icalendar_rrule
                                );
                                (gRow.location = RunLocationMapping[location || ''] || location),
                                    (gRow.schedule = modeData.locationSchedule[location].description);
                                gRow.status = modeData.locationSchedule[location].status;
                            }
                        }
                        modeData.scheduleUpdates.forEach((sl: ScheduleStatusLocation) => {
                            if (sl.schedule) {
                                const schedule = JSON.parse(sl.schedule);
                                sl.description = getDescription(schedule.timepoint?.icalendar_rrule);
                            }
                        });
                        gRow.numOfScheduleUpdates = modeData.scheduleUpdates ? modeData.scheduleUpdates.length : 0;
                        gridData.push(gRow);
                    }
                }

                setTableData(gridData);
            }
        }
    };

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Routing History" recordCount={tableData.length} collapsedMode={props.collapsed} />}
        >
            {props.routingHistory ? (
                <Box className={classes.contentBox}>
                    <RoutingHistoryGraph data={props.routingHistory} graphData={graphData} tableData={tableData} />
                </Box>
            ) : (
                <Box className={classes.contentBox} fontStyle="italic">
                    <Typography variant="body2">No routing history information found</Typography>
                </Box>
            )}
        </CollapsibleCard>
    );
}
