import React from 'react';
import { Paper, Box } from '@mui/material';
import { JobFilteredDuplicateSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatTimestamp } from '../../utils';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import CardTitleHeader from './CardTitleHeader';
import InfoField from './InfoField';
import ActionPopupMenu from './ActionPopupMenu';
import ExtraHighlightedElements from './ExtraHighlightedElements';

const explicitKeys = ['jobConfigName', 'jobConfigId', 'jobMode', 'bbdsTranId', 'runId', 'provRootStepid'];

export type JobFilteredDuplicateSearchDocCardProps = {
    duplicate: JobFilteredDuplicateSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const JobFilteredDuplicateSearchDocCard = (props: JobFilteredDuplicateSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} filtered-duplicate-search-card`}>
            <CardTypeHeader type={DocTypes.JOB_DUP} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.duplicate.jobConfigName}
                jobConfigVersion={props.duplicate.jobConfigVersion}
                jobConfigId={props.duplicate.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu
                        jobConfigId={props.duplicate.jobConfigId}
                        jobMode={props.duplicate.jobMode}
                        labels={['Details', 'Open in CRWL', 'Alert Configuration']}
                    />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                {props.duplicate.jobMode && (
                    <InfoField
                        content={{ label: 'Mode:', value: props.duplicate.jobMode, searchKey: 'jobMode' }}
                        highlightData={props.highlightData}
                    />
                )}

                {props.duplicate.jobStartTime && (
                    <InfoField
                        content={[
                            { label: 'Job ran on', value: formatTimestamp(props.duplicate.jobStartTime) },
                            { label: 'in', value: props.duplicate.jobRunLocation },
                        ]}
                    />
                )}
            </Box>

            <Box className={classes.infoRow}>
                {props.duplicate.jobAcqTime && (
                    <InfoField
                        content={{
                            label: 'Acquired on:',
                            value: formatTimestamp(props.duplicate.jobAcqTime),
                        }}
                    />
                )}

                {props.duplicate.bbdsPublish && (
                    <InfoField
                        content={{ label: 'Delivered at:', value: formatTimestamp(props.duplicate.bbdsPublish) }}
                    />
                )}

                <InfoField
                    content={{
                        label: 'BBDS Transaction Id:',
                        value: props.duplicate.bbdsTranId,
                        searchKey: 'bbdsTranId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Run Id:',
                        value: props.duplicate.runId,
                        searchKey: 'runId',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Root Step Id:',
                        value: props.duplicate.provRootStepid,
                        searchKey: 'provRootStepid',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default JobFilteredDuplicateSearchDocCard;
