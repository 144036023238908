import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

interface URLParams {
    jobId: string;
}

const JobInfo = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const jobId = props.match.params.jobId;
    return <Redirect to={`/?search=${jobId}`} />;
};

export default JobInfo;
