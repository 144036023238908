import React from 'react';
import { Chip } from '@mui/material';
import { JobStatus as JobStatusValues } from '@webacq/wa-shared-definitions';

interface JobStatusProps {
    status?: JobStatusValues;
}

const JobStatus = (props: JobStatusProps): JSX.Element => {
    const getColor = () => {
        switch (props.status) {
            case 'enabled':
                return 'green';
            case 'disabled':
                return 'orange';
            case 'deleted':
                return 'red';
            default:
                return '';
        }
    };

    return <Chip variant="outlined" size="small" label={props.status} style={{ color: getColor() }} />;
};

export default JobStatus;
