import React from 'react';
import { AppBar, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
}));

interface FooterProps {
    year: number;
}

const Footer = (props: FooterProps): JSX.Element => {
    const classes = useStyles();

    return (
        <AppBar position="static" style={{ backgroundColor: '#000000' }} className={classes.main}>
            <Typography variant="body2" style={{ color: '#a5a5a5' }}>
                © {props.year} Bloomberg L.P. All rights reserved.
            </Typography>
        </AppBar>
    );
};

export default Footer;
