import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DocTypes } from '../../shared/constants';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(2),
    },
    resultList: {
        width: '100%',
        overflow: 'auto',
    },
    resultRow: {
        display: 'flex',
    },
    italic: {
        fontStyle: 'italic',
    },
    margin1: {
        marginLeft: '1ch',
    },
    margin2: {
        marginLeft: '2ch',
    },
}));

export interface Facet {
    key: DocTypes;
    label: string | React.ReactElement;
}

interface FacetCheckboxProps {
    facet: Facet;
    checked: boolean;
    onChange?: (checked: boolean, ctrlPressed: boolean) => void;
}

const FacetCheckbox = (props: FacetCheckboxProps): JSX.Element => {
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (props.onChange) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.onChange(checked, (event.nativeEvent as any).ctrlKey);
        }
    };

    return (
        <FormControlLabel
            control={<Checkbox checked={props.checked} onChange={onChangeHandler} />}
            label={props.facet.label}
        />
    );
};

interface FacetSelectorProps {
    title: string;
    facets: Facet[];
    deselectedFacets?: string[];
    onFacetSelectionChanged?: (clickedFacetKey: string, selection: Record<string, boolean>) => void;
}

const FacetSelector = (props: FacetSelectorProps): JSX.Element => {
    const classes = useStyles();

    const onChangeHandler = (facetKey: string, checked: boolean, ctrlPressed: boolean) => {
        if (props.onFacetSelectionChanged) {
            const selection: Record<string, boolean> = {};

            if (ctrlPressed) {
                props.facets.forEach((f) => {
                    selection[f.key] = f.key === facetKey;
                });
            } else {
                props.facets.forEach((f) => {
                    selection[f.key] = f.key === facetKey ? checked : !props.deselectedFacets?.includes(f.key);
                });
            }

            props.onFacetSelectionChanged(facetKey, selection);
        }
    };

    return (
        <FormControl className={classes.formControl}>
            <FormLabel>{props.title}</FormLabel>
            <FormHelperText error={!props.facets.some((facet) => !(props.deselectedFacets || []).includes(facet.key))}>
                Select at least one
            </FormHelperText>
            <FormGroup>
                {props.facets.map((facet, idx) => (
                    <FacetCheckbox
                        key={idx}
                        facet={facet}
                        checked={!(props.deselectedFacets || []).includes(facet.key)}
                        onChange={(checked: boolean, ctrlPressed: boolean) =>
                            onChangeHandler(facet.key, checked, ctrlPressed)
                        }
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default FacetSelector;
