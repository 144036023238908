import React from 'react';
import { Paper, Box } from '@mui/material';
import { JobSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatTimestamp, jobSchedule2text } from '../../utils';
import JobStatus from '../JobStatus';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';

const explicitKeys = ['jobConfigName', 'jobConfigId', 'jobId', 'jobMode', 'locationName', 'jobCrByName', 'jobUpByName'];

export type JobSearchDocCardProps = {
    job: JobSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const JobSearchDocCard = (props: JobSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper className={`${classes.paddedBox} job-search-card`} elevation={1}>
            <CardTypeHeader type={DocTypes.JOB} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.job.jobConfigName}
                jobConfigId={props.job.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu jobConfigId={props.job.jobConfigId} jobMode={props.job.jobMode} />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Mode:',
                        value: props.job.jobMode,
                        searchKey: 'jobMode',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField content={{ label: 'Status:', value: <JobStatus status={props.job.jobStatus} /> }} />

                <InfoField
                    content={[
                        {
                            label: 'Runs',
                            value: jobSchedule2text(props.job),
                        },
                        {
                            label: 'in',
                            value: props.job.locationName,
                            searchKey: 'locationName',
                        },
                    ]}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Job Id:',
                        value: props.job.jobId,
                        searchKey: 'jobId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={[
                        {
                            label: 'Created on:',
                            value: formatTimestamp(props.job.jobCrAt),
                        },
                        {
                            label: 'by',
                            value: props.job.jobCrByName || props.job.jobCrByGuid,
                            searchKey: props.job.jobCrByName ? 'jobCrByName' : 'jobCrByGuid',
                        },
                    ]}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={[
                        { label: 'Modified last on', value: formatTimestamp(props.job.jobUpAt) },
                        {
                            label: 'by',
                            value: props.job.jobUpByName || props.job.jobUpByGuid,
                            searchKey: props.job.jobUpByName ? 'jobUpByName' : 'jobUpByGuid',
                        },
                    ]}
                    highlightData={props.highlightData}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default JobSearchDocCard;
