import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pluralSuffix } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
    horizSpacer: {
        paddingLeft: theme.spacing(2),
    },
}));

interface CardTitleProps {
    title: string;
    recordCount: number;
    collapsedMode: boolean;
    extraElement?: React.ReactElement;
}

const CardTitle = (props: CardTitleProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Typography variant="h6" color="textPrimary" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center">
                {props.title}
                {props.collapsedMode && (
                    <Typography variant="body2" color="textSecondary">
                        <Box className={classes.horizSpacer} component="span" fontStyle="italic">{`(${
                            props.recordCount || 'No'
                        } record${pluralSuffix(props.recordCount)})`}</Box>
                    </Typography>
                )}
                {props.extraElement}
            </Box>
        </Typography>
    );
};

export default CardTitle;
