import React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    TooltipProps,
    ResponsiveContainer,
    Label,
} from 'recharts';
import moment from 'moment';
import { SummaryArrayType } from '@webacq/wa-shared-definitions';
import { formatFieldData } from './SummaryUtil';
import { formatTimestamp } from '../../utils';

interface SummaryGraphProps {
    summaries: SummaryArrayType;
    field: string;
    color: string;
    fieldAlias: string;
}

interface GraphDataPoint {
    startTime: string;
    endTime: string;
    fieldValue: number;
    formattedFieldValue: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        color: theme.palette.primary.main,
    },
}));

const SummaryGraph = (props: SummaryGraphProps): JSX.Element => {
    const classes = useStyles();

    const [data, setData] = React.useState<GraphDataPoint[]>([]);

    React.useEffect(() => {
        const data = props.summaries
            .sort((s1, s2) => {
                return moment(s1.endTime).isAfter(s2.endTime) ? 1 : -1;
            })
            .map((summary) => {
                const fieldValue =
                    typeof summary.metadata[props.field] === 'string'
                        ? parseInt(summary.metadata[props.field])
                        : summary.metadata[props.field];
                return {
                    startTime: formatTimestamp(summary.startTime),
                    endTime: formatTimestamp(summary.endTime),
                    fieldValue: fieldValue,
                    formattedFieldValue: formatFieldData(fieldValue, props.field),
                };
            });

        setData(data);
    }, [props.field, props.summaries]);

    const renderCustomXAxisTick = () => {
        return <></>;
    };

    const formatYAxis = (tickItem: number) => {
        return formatFieldData(tickItem, props.field);
    };

    const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <Typography noWrap variant="body1" component="h2" className={classes.tooltip}>
                        {`Start time : ${payload[0].payload.startTime}`}
                    </Typography>
                    <Typography noWrap variant="body1" component="h2" className={classes.tooltip}>
                        {`End time : ${payload[0].payload.endTime}`}
                    </Typography>
                    <Typography noWrap variant="body1" component="h2" className={classes.tooltip}>
                        {`${props.fieldAlias} : ${payload[0].payload.formattedFieldValue}`}
                    </Typography>
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <Line type="monotone" dataKey="fieldValue" stroke={props.color} />
                <CartesianGrid strokeDasharray="1 3" stroke="grey" vertical={false} />
                <XAxis dataKey="endTime" tick={renderCustomXAxisTick}>
                    <Label
                        value={data.length === 0 ? '' : `${data[0].startTime} - ${data[data.length - 1].endTime}`}
                        offset={5}
                        position="insideBottom"
                        style={{ textAnchor: 'middle', fontSize: '100%', fill: 'white' }}
                    />
                </XAxis>
                <YAxis dataKey="fieldValue" tickFormatter={formatYAxis} interval={0} stroke="white" />
                <Tooltip content={<CustomTooltip />} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SummaryGraph;
