import React from 'react';
import { Tooltip, Paper, Box } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { JobConfigSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatTimestamp, launchUrl, pluralSuffix } from '../../utils';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';
import HighlightedElement from './HighlightedElement';
import ExtraHighlightedElements from './ExtraHighlightedElements';

const explicitKeys = [
    'jobConfigName',
    'jobConfigId',
    'jobMode',
    'jobConfigTemplateName',
    'jobStartUrl',
    'agentId',
    'jobConfigDesc',
    'jcCrByName',
    'jcUpByName',
];

export type JobConfigSearchDocCardProps = {
    jobConfig: JobConfigSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const JobConfigSearchDocCard = (props: JobConfigSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} job-config-search-card`}>
            <CardTypeHeader type={DocTypes.JOB_CONFIG} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.jobConfig.jobConfigName}
                jobConfigVersion={props.jobConfig.jobConfigVersion}
                jobConfigId={props.jobConfig.jobConfigId}
                jobConfigDesc={props.jobConfig.jobConfigDesc}
                renderActions={() => (
                    <ActionPopupMenu
                        jobConfigId={props.jobConfig.jobConfigId}
                        jobMode={props.jobConfig.jobMode}
                        labels={['Details', 'Open in CRWL', 'Alert Configuration']}
                    />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Agent ID:',
                        value: props.jobConfig.agentId,
                        searchKey: 'agentId',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Job Mode:',
                        value: props.jobConfig.jobMode,
                        searchKey: 'jobMode',
                    }}
                    highlightData={props.highlightData}
                />

                {props.jobConfig.templateParameters && (
                    <InfoField
                        content={[
                            {
                                label: 'Template:',
                                value: props.jobConfig.jobConfigTemplateName,
                                searchKey: 'jobConfigTemplateName',
                            },
                            {
                                value: (
                                    <Tooltip title={props.jobConfig.templateDescription}>
                                        <InfoOutlinedIcon
                                            style={{
                                                width: '1ch',
                                                height: '1ch',
                                                marginLeft: 3,
                                            }}
                                        />
                                    </Tooltip>
                                ),
                                nospace: true,
                            },
                        ]}
                        highlightData={props.highlightData}
                    />
                )}

                <InfoField
                    content={{
                        label: `Starting URL${pluralSuffix(props.jobConfig?.jobStartUrl?.length)}:`,
                        value: (
                            <>
                                {props.jobConfig.jobStartUrl?.map((url: string, i: number) => (
                                    <span key={i} style={{ marginLeft: i > 0 ? '2em' : 0 }}>
                                        <a
                                            className={classes.link}
                                            onClick={(): void => {
                                                launchUrl(url);
                                            }}
                                        >
                                            <HighlightedElement
                                                value={url}
                                                fieldName="jobStartUrl"
                                                highlightData={props.highlightData}
                                            />
                                        </a>
                                        {i < props.jobConfig.jobStartUrl?.length - 1 && ', '}
                                    </span>
                                ))}
                            </>
                        ),
                    }}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={[
                        {
                            label: 'Created on',
                            value: formatTimestamp(props.jobConfig.jcCrAt),
                        },
                        {
                            label: 'by',
                            value: props.jobConfig.jcCrByName || props.jobConfig.jcCrByGuid,
                            searchKey: props.jobConfig.jcCrByName ? 'jcCrByName' : 'jcCrByGuid',
                        },
                    ]}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={[
                        {
                            label: 'Modified last on',
                            value: formatTimestamp(props.jobConfig.jcUpAt),
                        },
                        {
                            label: 'by',
                            value: props.jobConfig.jcUpByName || props.jobConfig.jcUpByGuid,
                            searchKey: props.jobConfig.jcUpByName ? 'jcUpByName' : 'jcUpByGuid',
                        },
                    ]}
                    highlightData={props.highlightData}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default JobConfigSearchDocCard;
