import React from 'react';
import { Paper, Box } from '@mui/material';
import { Build as BuildIcon, MailOutline as MailOutlineIcon } from '@mui/icons-material';
import { AlertNotificationSearchDoc } from '@webacq/wa-shared-definitions';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { useStyles } from './commonStyles';
import { formatTimestamp } from '../../utils';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';

const explicitKeys = ['jobConfigName', 'jobMode', 'location', 'alertConfigId', 'agentId'];

export type AlertNotificationSearchDocCardProps = {
    alertNotification: AlertNotificationSearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const AlertNotificationSearchDocCard = (props: AlertNotificationSearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    const getAlertConditions = (alertNotification: AlertNotificationSearchDoc): React.ReactElement => {
        const numConditions = Math.min(
            alertNotification.alertMetricName?.length || 0,
            alertNotification.alertOperatorName?.length || 0,
            alertNotification.alertOperands?.length || 0,
            alertNotification.alertMetricValue?.length || 0
        );

        const rows: React.ReactElement[] = [];

        for (let idx = 0; idx < numConditions; idx++) {
            const metric = (alertNotification.alertMetricName || [])[idx];
            const operator = (alertNotification.alertOperatorName || [])[idx];
            const operands = JSON.parse((alertNotification.alertOperands || [])[idx]) as number[];
            const value = (alertNotification.alertMetricValue || [])[idx];

            rows.push(
                <div>
                    {`${metric} (${value}) is ${operator} ${
                        operands.length > 0
                            ? // format as "op1, op2, op3 ... and opN"
                              operands
                                  .map((val) => '' + val)
                                  .reduce(
                                      (val, currentVal, idx, src) =>
                                          '' + val + (idx < src.length - 1 ? ', ' : ' and ') + currentVal
                                  )
                            : ''
                    }`}
                </div>
            );
        }

        return (
            <Box display="flex" flexDirection="column">
                {rows}
            </Box>
        );
    };

    return (
        <Paper elevation={1} className={`${classes.paddedBox} alert-notification-search-card`}>
            <CardTypeHeader type={DocTypes.ALERT_NOTIF} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.alertNotification.jobConfigName}
                jobConfigId={props.alertNotification.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu
                        jobConfigId={props.alertNotification.jobConfigId}
                        jobMode={props.alertNotification.jobMode}
                    />
                )}
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                <InfoField
                    content={[
                        {
                            label: 'Type:',
                            value: (
                                <>
                                    {props.alertNotification.alertNotifType === 'email' && (
                                        <MailOutlineIcon fontSize="small" style={{ verticalAlign: 'bottom' }} />
                                    )}
                                    {props.alertNotification.alertNotifType === 'workitem' && (
                                        <BuildIcon fontSize="small" style={{ verticalAlign: 'bottom' }} />
                                    )}
                                </>
                            ),
                        },
                        {
                            value: props.alertNotification.alertNotifType,
                            searchKey: 'alertNotifType',
                        },
                    ]}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{ label: 'Mode:', value: props.alertNotification.jobMode, searchKey: 'jobMode' }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Location:',
                        value: props.alertNotification.jobRunLocation,
                        searchKey: 'jobRunLocation',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Agent ID:',
                        value: props.alertNotification.agentId,
                        searchKey: 'agentId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Alert Config Id:',
                        value: props.alertNotification.alertConfigId,
                        searchKey: 'alertConfigId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Conditions:',
                        value: getAlertConditions(props.alertNotification),
                    }}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={[
                        {
                            label: 'Datapoints acquired between',
                            value: formatTimestamp(props.alertNotification.alertNotifAqStart),
                        },
                        {
                            label: 'and',
                            value: formatTimestamp(props.alertNotification.alertNotifAqEnd),
                        },
                    ]}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default AlertNotificationSearchDocCard;
