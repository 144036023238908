import React, { useRef, useEffect } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import { JobConfigVersionData } from '@webacq/wa-shared-definitions';
import { Box, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import popper from 'cytoscape-popper';
import ReactDOM from 'react-dom';
import { SelectedNodeData, RoutingHistoryRow } from './RoutingHistoryGraphInterfaces';
import RoutingHistoryNodeInfo from './RoutingHistoryNodeInfo';
import RoutingHistoryTable from './RoutingHistoryTable';

cytoscape.use(popper);

const useStyles = makeStyles((theme: Theme) => ({
    graphContainer: {
        backgroundColor: theme.palette.grey[600],
        marginBottom: theme.spacing(1),
    },
}));

interface RoutingHistoryGraphProps {
    data?: JobConfigVersionData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphData: any;
    tableData: RoutingHistoryRow[];
}

export default function RoutingHistoryGraph(props: RoutingHistoryGraphProps): JSX.Element {
    const classes = useStyles();

    const width = '100%';
    const height = '400px';
    const cyRef = React.useRef<cytoscape.Core>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cyPopperRef = useRef<any>(null);

    const styleSheet: cytoscape.Stylesheet[] = [
        {
            selector: 'node',
            style: {
                backgroundColor: '#4a56a6',
                width: 5,
                height: 5,
                label: 'data(label)',
            },
        },
        {
            selector: 'label',
            style: {
                'font-size': '0.25em',
                color: 'white',
            },
        },
        {
            selector: "node[type='schedule']",
            style: {
                shape: 'rectangle',
                width: 3,
                height: 3,
                'background-color': '#27AE60',
            },
        },
        {
            selector: "node[type='version-e']",
            style: {
                width: 0.5,
                height: 0.5,
            },
        },
        {
            selector: "node[type='start-node']",
            style: {
                width: 0.5,
                height: 0.5,
            },
        },
        {
            selector: 'edge',
            style: {
                width: 1,
                'line-color': '#AAD8FF',
                'curve-style': 'straight',
            },
        },
        {
            selector: "edge[type='C']",
            style: {
                width: 0.5,
                'line-color': '#B2BABB',
                'line-style': 'dotted',
                'curve-style': 'straight',
            },
        },
        {
            selector: "edge[type='R']",
            style: {
                width: 1,
                'line-color': '#AAD8FF',
                'target-arrow-color': '#bbb',
                'target-arrow-shape': 'triangle',
                'arrow-scale': 0.3,
                'curve-style': 'straight',
            },
        },
    ];

    const layout = {
        name: 'preset',
        fit: true,
        directed: true,
        padding: 50,
        animate: true,
        animationDuration: 1000,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: false,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let dummyDomEle: any = null;
    const createContentFromComponent = (component: JSX.Element) => {
        dummyDomEle = document.createElement('div');
        dummyDomEle.style.zIndex = '1000';
        ReactDOM.render(component, dummyDomEle);
        document.body.appendChild(dummyDomEle);
        return dummyDomEle;
    };

    useEffect(() => {
        const cy = cyRef.current;
        if (cy) {
            cy.nodes().on('mouseover', (evt) => {
                if (props.data) {
                    const data = props.data;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const node: any = evt.target;

                    const id = node.data().id.split('-');
                    if (id.length > 1) {
                        const nData: SelectedNodeData = {
                            version: id[0],
                            type: node.data().type,
                            mode: id[1] == 'T' ? 'test' : 'live',
                            nodeData: id[1] == 'T' ? data.history[id[0]]['test'] : data.history[id[0]]['live'],
                        };

                        cyPopperRef.current = evt.target.popper({
                            content: createContentFromComponent(
                                <RoutingHistoryNodeInfo
                                    version={nData.version}
                                    type={nData.type}
                                    mode={nData.mode}
                                    nodeData={nData.nodeData}
                                />
                            ),
                            popper: {
                                placement: 'bottom',
                                removeOnDestroy: true,
                            },
                        });
                    }
                }
            });

            cy.nodes().unbind('mouseout');
            cy.nodes().bind('mouseout', () => {
                if (cyPopperRef && cyPopperRef.current) {
                    cyPopperRef.current.destroy();
                    document.body.removeChild(dummyDomEle);
                }
            });

            cy.on('pan zoom resize position', () => {
                if (cyPopperRef && cyPopperRef.current) {
                    cyPopperRef.current.destroy();
                }
            });
        }
        return () => {
            cy && cy.destroy();
            if (cyPopperRef && cyPopperRef.current) {
                cyPopperRef.current.destroy();
            }
        };
    }, [cyRef.current]);

    return (
        <Box display="flex" flexDirection="column" height="100%">
            {props.graphData.nodes.length > 0 && (
                <Box flexGrow={0} overflow="auto" minHeight="50%">
                    <Paper elevation={4} variant="outlined" className={classes.graphContainer}>
                        <CytoscapeComponent
                            elements={CytoscapeComponent.normalizeElements(props.graphData)}
                            // pan={{ x: 200, y: 200 }}
                            style={{ width: width, height: height }}
                            zoomingEnabled={true}
                            maxZoom={3}
                            minZoom={0.1}
                            autounselectify={false}
                            boxSelectionEnabled={true}
                            layout={layout}
                            stylesheet={styleSheet}
                            cy={(cy) => {
                                cyRef.current = cy;
                                cy.autoungrabify(false);
                                cy.userZoomingEnabled(false);
                            }}
                        />
                    </Paper>
                </Box>
            )}
            <Box flexGrow={0} overflow="auto" minHeight="50%">
                <RoutingHistoryTable history={props.tableData} />
            </Box>
        </Box>
    );
}
