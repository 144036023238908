/* eslint-disable @typescript-eslint/ban-types */
// import { Column, QueryResult } from 'material-table';
import { MRT_ColumnDef, MRT_ColumnFilterFnsState, MRT_PaginationState } from 'material-react-table';

export type PaginatedTableColumn<T extends object> = MRT_ColumnDef<T>;

export enum PaginatedTableFilterDateOperator {
    Before = 'before',
    On = 'on',
    After = 'after',
}
export type PaginatedTableDateFilterValue = {
    date?: Date;
    operator?: PaginatedTableFilterDateOperator;
};

export type PaginatedTableFilterSpec = {
    field: string;
    type: 'string' | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency';
    value: string | boolean | number | Date;
}[];

export type PageableTableCustomFilterProps<T extends object> = {
    columnDef: PaginatedTableColumn<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFilterChanged: (rowId: string, value: any) => void;
};
export type PaginatedTableColumnFilterState = MRT_ColumnFilterFnsState;

export type PaginatedTableSortingSpec = {
    field: string;
    direction: 'asc' | 'desc';
}[];

export type PaginatedTablePaginationState = MRT_PaginationState;

export type PaginatedTableQueryResult<T extends object> = {
    data: T[];
    page: number;
    totalCount: number;
    filters: PaginatedTableColumnFilterState[];
    sorting: PaginatedTableSortingSpec[];
};

export type PaginatedTableDataQueryCallback<T extends object, U = void> = (
    page: number,
    pageSize: number,
    sort?: PaginatedTableSortingSpec,
    filter?: PaginatedTableColumnFilterState,
    userData?: U
) => Promise<PaginatedTableQueryResult<T>>;
