import axios, { AxiosResponse } from 'axios';
import { AbstractApi } from '@webacq/wa-shared-servicewrappers';

export class ServiceError extends Error {
    code: number;

    constructor(code: number, message: string | undefined) {
        super(message);
        this.code = code;
    }
}

const DEFAULT_ERROR_MESSAGE = 'An unknown error has occured. Please try again or contact an administrator.';

const DefaultErrorProcessor = (response: AxiosResponse): ServiceError => {
    const error = new ServiceError(response.status, undefined);

    if (response.data) {
        if (typeof response.data === 'string') {
            error.message = response.data;
        } else {
            error.message = JSON.stringify(response.data);
        }
    } else {
        error.message = DEFAULT_ERROR_MESSAGE;
    }

    return error;
};

class Api implements AbstractApi {
    apiUrl = '';
    errorProcessor = DefaultErrorProcessor;

    constructor(apiUrl: string, errorProcessor?: (error: AxiosResponse) => ServiceError) {
        this.apiUrl = apiUrl;
        if (errorProcessor) {
            this.errorProcessor = errorProcessor;
        }
    }

    get<ReturnType>(endpoint: string): Promise<ReturnType> {
        const options = {
            withCredentials: true,
            validateStatus: () => true,
        };

        console.log('get', `${this.apiUrl}/${endpoint}`);
        return axios.get(`${this.apiUrl}/${endpoint}`, options).then((response: AxiosResponse) => {
            if (response.status !== 200) {
                console.log(response);
                throw this.errorProcessor(response);
            }

            console.log(response);
            return response.data;
        });
    }

    post<ReturnType>(endpoint: string, body?: Record<string, unknown>): Promise<ReturnType> {
        const options = {
            withCredentials: true,
            validateStatus: () => true,
        };

        console.log('post', endpoint, body);
        return axios.post(`${this.apiUrl}/${endpoint}`, body, options).then((response: AxiosResponse) => {
            if (response.status !== 200 && response.status != 201) {
                console.log(response);
                throw this.errorProcessor(response);
            }

            console.log(response);
            return response.data;
        });
    }

    put<ReturnType>(endpoint: string, body?: Record<string, unknown>): Promise<ReturnType> {
        const options = {
            withCredentials: true,
            validateStatus: () => true,
        };

        console.log('put', endpoint, body);
        return axios.put(`${this.apiUrl}/${endpoint}`, body, options).then((response: AxiosResponse) => {
            if (response.status !== 200) {
                console.log(response);
                throw this.errorProcessor(response);
            }

            console.log(response);
            return response.data;
        });
    }

    patch<ReturnType>(endpoint: string, body?: Record<string, unknown>): Promise<ReturnType> {
        const options = {
            withCredentials: true,
            validateStatus: () => true,
        };

        console.log('patch', endpoint, body);
        return axios.patch(`${this.apiUrl}/${endpoint}`, body, options).then((response: AxiosResponse) => {
            if (response.status !== 200) {
                console.log(response);
                throw this.errorProcessor(response);
            }

            console.log(response);
            return response.data;
        });
    }

    delete<ReturnType>(endpoint: string): Promise<ReturnType> {
        const options = {
            withCredentials: true,
            validateStatus: () => true,
        };

        console.log('delete', endpoint);
        return axios.delete(`${this.apiUrl}/${endpoint}`, options).then((response: AxiosResponse) => {
            if (response.status !== 200 && response.status !== 204) {
                console.log(response);
                throw this.errorProcessor(response);
            }

            console.log(response);
            return response.data;
        });
    }
}

export default Api;
