import React from 'react';
import { config } from '../config';

import WAMService from '../services/wamService';

const hubUrl = config.hubUrl;

const Login = (): JSX.Element => {
    React.useEffect(() => {
        WAMService.authenticate(`${hubUrl}/Auth`);
    });

    return <div />;
};

export default Login;
