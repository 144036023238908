import React from 'react';
import { Box, Typography } from '@mui/material';
import { RoutingHistoryRow } from './RoutingHistoryGraphInterfaces';
import JobStatus from '../../JobStatus';
import PaginatedTable from '../../PaginatedTable/PaginatedTable';
import { PaginatedTableColumn } from '../../PaginatedTable/types';

interface RoutingHistoryTableData {
    history?: RoutingHistoryRow[];
}

export default function RoutingHistoryTable({ history }: RoutingHistoryTableData): JSX.Element {
    const columns = [
        {
            header: 'Job Config Version',
            accessorKey: 'version',
            width: '6%',
        },
        {
            header: 'Job Mode',
            accessorKey: 'mode',
            width: '7%',
        },
        {
            header: 'Run Location',
            accessorKey: 'location',
        },
        {
            header: 'Schedule',
            accessorKey: 'schedule',
        },
        {
            header: 'Status',
            accessorKey: 'status',
            accessorFn: function getStatusChip(rowData) {
                return <JobStatus status={rowData.status} />;
            },
        },
        {
            header: 'Created By',
            accessorKey: 'createdBy',
        },
        {
            header: 'Created',
            accessorKey: 'createdOn',
            filterVariant: 'date',
        },
        {
            header: 'Schedule Updates',
            accessorKey: 'numOfScheduleUpdates',
        },
        {
            header: 'Start',
            accessorKey: 'start',
            filterVariant: 'date',
        },
        {
            header: 'End',
            accessorKey: 'end',
            filterVariant: 'date',
        },
    ] as PaginatedTableColumn<RoutingHistoryRow>[];

    return (
        <div>
            {history &&
                (history.length > 0 ? (
                    <Box>
                        <PaginatedTable
                            columns={columns}
                            rows={history}
                            initialSorting={[{ id: 'createdOn', desc: true }]}
                        />
                    </Box>
                ) : (
                    <Box fontStyle="italic">
                        <Typography variant="body2">No routing information found</Typography>
                    </Box>
                ))}
        </div>
    );
}
