import React from 'react';
import { Box, Theme, Typography, Link, Tooltip, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { JobCfgIdWorkItem } from '@webacq/wa-shared-definitions';
import { config } from '../../config';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import { launchMnemonic } from '../../utils';
import CardTitle from './CardTitle';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface JobWorkItemsCardProps {
    collapsed: boolean;
    jobConfigId: string;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    workItems?: JobCfgIdWorkItem[];
}

const JobWorkItemsCard = (props: JobWorkItemsCardProps): JSX.Element => {
    const classes = useStyles();

    const columns = [
        {
            header: 'Work Item Id',
            accessorKey: 'workItemId',
        },
        {
            header: 'Owner Name',
            accessorKey: 'ownerName',
        },
        {
            header: 'Status',
            accessorKidey: 'status',

            accessorFn: (rowData) => (
                <Chip variant="outlined" size="small" disabled={!rowData.status} label={rowData.status} />
            ),
        },
        {
            header: 'State',
            accessorKey: 'stateName',
        },
        {
            header: 'Problem Type',
            accessorKey: 'problemType',
        },
        {
            header: 'Created',
            accessorKey: 'createdOn',
            filterVariant: 'date',
        },
        {
            header: 'View In BOTC',
            accessorKey: 'workItemId',
            accessorFn: (rowData) => {
                let mnemonic: { func: string; tail?: string } | undefined = undefined;

                switch (config.env) {
                    case 'PROD':
                        mnemonic = {
                            func: 'BOTC',
                            tail: `WF W ${rowData.workItemId}`,
                        };
                        break;
                    case 'DEV':
                    case 'LOCAL':
                        mnemonic = {
                            func: 'RRRR',
                            tail: `BOTC 220 WF W ${rowData.workItemId}`,
                        };
                }

                return (
                    <span key={rowData.workItemId}>
                        {mnemonic && (
                            <Tooltip title={`Launch ${mnemonic.func} ${mnemonic.tail}`} arrow>
                                <Link
                                    href="#"
                                    onClick={(): void => launchMnemonic(mnemonic?.func || '', mnemonic?.tail)}
                                    variant="body2"
                                >
                                    {`${mnemonic.func} ${mnemonic.tail}`}
                                </Link>
                            </Tooltip>
                        )}
                    </span>
                );
            },
        },
    ] as PaginatedTableColumn<JobCfgIdWorkItem>[];

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={
                <CardTitle
                    title="Work Items"
                    recordCount={props.workItems?.length || 0}
                    collapsedMode={props.collapsed}
                />
            }
        >
            {props.workItems &&
                (props.workItems.length > 0 ? (
                    <PaginatedTable
                        columns={columns}
                        rows={props.workItems}
                        initialSorting={[
                            {
                                id: 'createdOn',
                                desc: true,
                            },
                        ]}
                    />
                ) : (
                    <Box className={classes.contentBox} fontStyle="italic">
                        <Typography variant="body2">No work items found</Typography>
                    </Box>
                ))}
        </CollapsibleCard>
    );
};

export default JobWorkItemsCard;
