import React from 'react';
import { Box, TextField, Autocomplete } from '@mui/material';
import { DeliveryInfo } from './types';

interface DeliverySelectorProps {
    label: string;
    options: DeliveryInfo[];
    selectedOptionIdx?: number;
    onChange: (selectedOptionIdx: number) => void;
}

const DeliverySelector = (props: DeliverySelectorProps): JSX.Element => {
    const getDisplayValue = (option: number) =>
        0 <= option && option < props.options.length ? props.options[option].acquisitionTime.toLocaleString() : '';

    return (
        <Box>
            <Autocomplete
                fullWidth
                autoHighlight
                options={props.options.map((_, idx) => idx)}
                renderOption={(props, option) => <li {...props}>{getDisplayValue(option)}</li>}
                getOptionLabel={(option) => getDisplayValue(option)}
                value={props.selectedOptionIdx !== undefined ? props.selectedOptionIdx : -1}
                renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
                onChange={(_, newValue) => props.onChange(newValue || 0)}
            />
        </Box>
    );
};

export default DeliverySelector;
