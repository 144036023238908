/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Switch, Tooltip, Typography, FormControlLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard, FullTreeIcon, BranchIcon } from '@webacq/wam-ui-components';
import { JobDeliverySearchDoc, JobDeliverySearchDocRaw } from '@webacq/wa-shared-definitions';
import { SearchResult } from '@webacq/wa-shared-servicewrappers';
import HumioLink from './HumioLink';
import CardTitle from './CardTitle';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import RunTreeLink from './RunTreeLink';
import { Link as RouterLink } from 'react-router-dom';
import { ChromeReaderMode as HTMLDiffIcon } from '@mui/icons-material';
import { addEllipsis, formatTimestamp, getHTMLDiffRoute, pluralSuffix } from '../../utils';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DocTypes, QueryStrings } from '../../shared/constants';
import { usePaginatedQuery } from '../PaginatedTable/usePaginatedQuery';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import ObjectViewerLink from '../ObjectViewerLink';

const useStyles = makeStyles((theme: Theme) => ({
    switchBox: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

export interface TableRowDataType extends JobDeliverySearchDoc {
    urlHasMultipleDeliveries?: boolean; // do we have more than one delivery for the same url?
}

interface JobDeliveriesCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    onUrlClick?: (url: string) => void;
    jobConfigId: string;
}

const JobDeliveriesCard = (props: JobDeliveriesCardProps): JSX.Element => {
    const classes = useStyles();

    const [selectedJobMode] = useQueryParam(QueryStrings.JOB_MODE, withDefault(StringParam, ''));

    const [includeCompleteRuns, setIncludeCompleteRuns] = useState(false);
    const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'bbdsPublish', desc: true }]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    function processDocs(doc: TableRowDataType, result: SearchResult<TableRowDataType>) {
        doc.urlHasMultipleDeliveries = false; // reset before calculating

        // we cannot currently determine whether we have multiple branch deliveries
        // for the same url if complete runs are included
        if (!includeCompleteRuns) {
            if (!doc.isComplete && doc.urls && doc.urls[0] && result?.data?.facetCounts) {
                const facetFields = result.data?.facetCounts.facet_fields as Record<string, unknown>;
                if (facetFields && facetFields['urls_ss']) {
                    const facetCounts = facetFields['urls_ss'] as Record<string, number>;

                    if (facetCounts && facetCounts[doc.urls[0]] > 1) {
                        doc.urlHasMultipleDeliveries = true;
                    }
                }
            }
        }

        return doc;
    }

    const query = `job_config_id_s:${props.jobConfigId} ${selectedJobMode ? 'AND job_mode_s:' + selectedJobMode : ''} ${
        !includeCompleteRuns ? 'AND is_complete_b:false' : ''
    }`;

    const { data, numFound, noDataAvailable, response, isBusy } = usePaginatedQuery<
        JobDeliverySearchDocRaw,
        TableRowDataType
    >(
        query,
        DocTypes.JOB_DLV,
        filters,
        sorting,
        pagination,
        // we only care for urls delivered more than once
        { 'facet.field': 'urls_ss', 'facet.mincount': '2' }
    );

    const rows = data.map((d) => processDocs(d, response));

    const columns = [
        {
            header: '',
            size: 20,
            id: 'branchType',
            muiTableHeadCellProps: {
                sx: {
                    fontSize: 11,
                },
            },
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: (rowData) =>
                rowData.isComplete ? (
                    <Tooltip title="Complete Run">
                        <span>
                            <FullTreeIcon />
                        </span>
                    </Tooltip>
                ) : (
                    <Tooltip title="Branch Delivery">
                        <span>
                            <BranchIcon />
                        </span>
                    </Tooltip>
                ),
        },
        {
            header: 'Job Config Version',
            accessorKey: 'jobConfigVersion',
            size: 20,
        },
        { header: 'Job Mode', accessorKey: 'jobMode', size: 40 },
        { header: 'Run Location', accessorKey: 'jobRunLocation' },
        { header: 'BBDS Transaction Id', accessorKey: 'bbdsTranId' },
        {
            header: 'Published',
            accessorKey: 'bbdsPublish',
            filterVariant: 'date',
            accessorFn: (rowData) => formatTimestamp(rowData.bbdsPublish),
        },
        {
            header: 'URL',
            accessorKey: 'urls',
            Cell: ({ row }) => {
                const rowData = row.original;
                if (rowData.isComplete) {
                    return (
                        <i>{`(${rowData.deliveries?.length || 0} deliver${pluralSuffix(
                            rowData.deliveries?.length,
                            'ies',
                            'y'
                        )})`}</i>
                    );
                } else {
                    const url = (rowData.urls && rowData.urls[0]) || '';

                    return url ? (
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Tooltip title={url}>
                                    <span>{addEllipsis(url, 120)}</span>
                                </Tooltip>
                            </Box>

                            {rowData.deliveries && (
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    {!!rowData.urlHasMultipleDeliveries && (
                                        <Tooltip title="HTML Diff">
                                            <RouterLink
                                                to={getHTMLDiffRoute(
                                                    rowData.jobConfigId || '',
                                                    rowData.urls[0],
                                                    rowData.jobAcqTime
                                                )}
                                            >
                                                <HTMLDiffIcon fontSize="small" />
                                            </RouterLink>
                                        </Tooltip>
                                    )}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <ObjectViewerLink url={rowData.deliveries[0] || ''} />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <></>
                    );
                }
            },
        },
        {
            header: 'Run Tree',
            id: 'runTree',
            size: 20,
            enableSorting: false,
            enableColumnFilter: false,
            accessorFn: (rowData) => rowData.bbdsTranId,
            muiTableBodyCellProps: {
                sx: {
                    textAlign: 'center',
                    padding: '4px',
                },
            },
            Cell: ({ cell }) => <RunTreeLink id={cell.getValue<string>()} />,
        },
        {
            header: 'Humio',
            size: 20,
            id: 'humioLink',
            enableSorting: false,
            enableColumnFilter: false,
            accessorFn: function humioLinkRender(rowData) {
                return (
                    <HumioLink
                        logConfigName="crawlWorker"
                        query={rowData.runId || ''}
                        startTime={rowData.jobStartTime}
                        logRetentionInDays={30}
                    />
                );
            },
        },
    ] as PaginatedTableColumn<TableRowDataType>[];

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={
                <CardTitle
                    title="Deliveries"
                    recordCount={numFound}
                    collapsedMode={props.collapsed}
                    extraElement={
                        !props.collapsed ? (
                            <Box display="flex" className={classes.switchBox} flexGrow={1} justifyContent="flex-end">
                                <FormControlLabel
                                    className={classes.switchBox}
                                    control={
                                        <Switch
                                            size="small"
                                            checked={includeCompleteRuns}
                                            onChange={(event) => {
                                                setIncludeCompleteRuns(event.currentTarget.checked);
                                                setPagination({ pageIndex: 0, pageSize: 10 });
                                            }}
                                        />
                                    }
                                    label="Include Complete Runs"
                                />
                            </Box>
                        ) : undefined
                    }
                />
            }
        >
            <Box hidden={noDataAvailable}>
                <PaginatedTable
                    columns={columns}
                    rows={rows}
                    totalCount={numFound}
                    filters={filters}
                    sorting={sorting}
                    pagination={pagination}
                    onUpdateFilters={(filters) => {
                        setFilters(filters);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdateSorting={(sorting) => {
                        setSorting(sorting);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdatePagination={setPagination}
                    controlled={true}
                    isBusy={isBusy}
                    hideLastPageButton
                />
            </Box>

            <Box hidden={!noDataAvailable} className={classes.contentBox} fontStyle="italic">
                <Typography variant="body2">No delivery information found</Typography>
            </Box>
        </CollapsibleCard>
    );
};

export default JobDeliveriesCard;
