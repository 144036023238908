import { MRT_ColumnFiltersState, MRT_SortingState } from 'material-react-table';
import { PaginatedTableFilterSpec, PaginatedTableSortingSpec } from './types';

export function solrToMRTSort(sort: PaginatedTableSortingSpec): MRT_SortingState {
    return sort.map((s) => ({
        id: s.field,
        desc: s.direction === 'desc',
    }));
}

export function mrtToSolrSort(sort: MRT_SortingState): PaginatedTableSortingSpec {
    return sort.map((s) => ({
        field: s.id,
        direction: s.desc ? 'desc' : 'asc',
    }));
}

export function solrToMRTFilters(filters: PaginatedTableFilterSpec): MRT_ColumnFiltersState {
    return filters.map((f) => ({
        id: f.field,
        type: f.type,
        value: f.value,
    }));
}
export function mrtToSolrFilters(filters: MRT_ColumnFiltersState): PaginatedTableFilterSpec {
    return filters.map((f) => ({
        field: f.id,
        // TODO: update me
        type: 'string',
        value: f.value as string,
    }));
}
