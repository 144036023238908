import React, { useEffect } from 'react';
import { RouterProps, useHistory, withRouter } from 'react-router-dom';
import { Box, LinearProgress, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { SummaryArrayType } from '@webacq/wa-shared-definitions';
import { Toast, ToastPosition } from '@webacq/wam-ui-components';

import Content from '../components/Content';
import { wrapApiCall } from '../utils';
import { useAuth } from '../context/auth';

import summaryService from '../services/summaryService';
import { SummaryQueryStrings, SummaryType, RecordUnit, Mode, SummaryColors } from '../shared/constants';
import SummaryFilters from '../components/Summary/SummaryFilters';
import SummaryTable from '../components/Summary/SummaryTable';
import SummaryGraph from '../components/Summary/SummaryGraph';
import SummaryCards from '../components/Summary/SummaryCards';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flexDirection: 'column',
        height: '100%',
    },
    filters: {
        marginBottom: theme.spacing(2),
    },
    graph: {
        width: '100%',
        height: '25%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    message: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(6),
    },
    horizSpacer: {
        marginRight: theme.spacing(2),
    },
}));

const parseTimePeriod = (from: string, to: string): [Date, Date] => {
    let toDatetime;
    if (to === 'now') {
        toDatetime = moment();
    } else {
        throw Error(`Invalid 'to'`);
    }

    const [first, second] = from.split('-');
    if (first !== 'now') {
        throw Error(`Invalid 'from'`);
    }

    const quant = Number(second.slice(0, -1));
    const unit = second.slice(-1);

    const fromDatetime = moment().subtract(quant, unit as moment.DurationInputArg2);

    return [fromDatetime.toDate(), toDatetime.toDate()];
};

const Summary = (props: RouterProps): JSX.Element => {
    const authContext = useAuth();
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const queryParams = new URLSearchParams(props.history.location.search);
    const summaryTypeParam = queryParams.get(SummaryQueryStrings.SUMMART_TYPE) || SummaryType.GLOBAL;
    const recordQuantParam = queryParams.get(SummaryQueryStrings.RECORD_QUANT) || '1';
    const recordUnitParam = queryParams.get(SummaryQueryStrings.RECORD_UNIT) || RecordUnit.HOUR;
    const fromParam = queryParams.get(SummaryQueryStrings.FROM) || 'now-24h';
    const toParam = queryParams.get(SummaryQueryStrings.TO) || 'now';
    const modeParam = queryParams.get(SummaryQueryStrings.MODE) || Mode.LIVE;
    const jobConfigIdParam = queryParams.get(SummaryQueryStrings.JOB_CONFIG_ID) || '';
    const agentIdParam = queryParams.get(SummaryQueryStrings.AGENT_ID) || '';
    const domainParam = queryParams.get(SummaryQueryStrings.DOMAIN) || '';

    const [summaryType, setSummaryType] = React.useState(summaryTypeParam);
    const [recordQuant, setRecordQuant] = React.useState(recordQuantParam);
    const [recordUnit, setRecordUnit] = React.useState(recordUnitParam);
    const [from, setFrom] = React.useState(fromParam);
    const to = toParam;
    const [fromTimestamp, toTimestamp] = parseTimePeriod(from, to);
    const [mode, setMode] = React.useState(modeParam);
    const [jobConfigId, setJobConfigId] = React.useState(jobConfigIdParam);
    const [domain, setDomain] = React.useState(domainParam);
    const [agentId, setAgentId] = React.useState(agentIdParam);
    const [inputEmpty, setInputEmpty] = React.useState(false);

    const [summaries, setSummaries] = React.useState<SummaryArrayType>([]);
    const [visibleSummaries, setVisibleSummaries] = React.useState<SummaryArrayType>([]);
    const [cardField, setCardField] = React.useState<string>('numRuns');
    const [cardColor, setCardColor] = React.useState<string>(SummaryColors.NumRuns);
    const [cardFieldAlias, setCardFieldAlias] = React.useState<string>('# of runs');

    const tableFilterChangedHandler = (visibleSummaries: SummaryArrayType) => {
        setVisibleSummaries(visibleSummaries);
    };

    const cardSelectedHandler = (
        cardFieldSelected: string,
        cardColorSelected: string,
        cardFieldSelectedAlias: string
    ) => {
        setCardField(cardFieldSelected);
        setCardColor(cardColorSelected);
        setCardFieldAlias(cardFieldSelectedAlias);
    };

    const setSummaryData = (summaries: SummaryArrayType) => {
        setSummaries(summaries);
        setVisibleSummaries(summaries);
        setCardField('numRuns');
        setCardColor(SummaryColors.NumRuns);
        setCardFieldAlias('# of runs');

        setIsLoading(false);
    };

    const wrapSummaryApiCall = function (requestPromise: Promise<SummaryArrayType>) {
        wrapApiCall(
            authContext,
            async () => {
                const response = await requestPromise;
                setSummaryData(response);
            },
            (e: Error) => setErrorMessage(e.message),
            () => setIsLoading(false)
        );
    };

    const renderWithData = () => {
        setIsLoading(true);
        setErrorMessage('');

        if (summaryType === SummaryType.GLOBAL) {
            wrapSummaryApiCall(
                summaryService.getGlobalSummaryDatapoints(
                    fromTimestamp,
                    toTimestamp,
                    parseInt(recordQuant),
                    recordUnit,
                    mode
                )
            );
        } else if (summaryType === SummaryType.ZONE) {
            wrapSummaryApiCall(
                summaryService.getZoneSummaryDatapoints(
                    fromTimestamp,
                    toTimestamp,
                    parseInt(recordQuant),
                    recordUnit,
                    mode
                )
            );
        } else if (summaryType === SummaryType.JOB) {
            wrapSummaryApiCall(
                summaryService.getJobSummaryDatapoints(
                    fromTimestamp,
                    toTimestamp,
                    parseInt(recordQuant),
                    recordUnit,
                    jobConfigId,
                    mode
                )
            );
        } else if (summaryType === SummaryType.DOMAIN) {
            wrapSummaryApiCall(
                summaryService.getDomainSummaryDatapoints(
                    fromTimestamp,
                    toTimestamp,
                    parseInt(recordQuant),
                    recordUnit,
                    domain,
                    mode
                )
            );
        } else if (summaryType === SummaryType.AGENT) {
            wrapSummaryApiCall(
                summaryService.getAgentSummaryDatapoints(
                    fromTimestamp,
                    toTimestamp,
                    parseInt(recordQuant),
                    recordUnit,
                    agentId,
                    mode
                )
            );
        } else {
            throw Error(`Invalid summary type`);
        }
    };

    useEffect(() => {
        renderWithData();
    }, []);

    // summary filter
    const _loadBtnClickHandler = () => {
        if (summaryType === SummaryType.GLOBAL) {
            history.push(
                `/Summary?summary_type=${SummaryType.GLOBAL}&from=${from}&to=${to}&record_quant=${recordQuant}&record_unit=${recordUnit}&mode=${mode}`
            );
        } else if (summaryType === SummaryType.ZONE) {
            history.push(
                `/Summary?summary_type=${SummaryType.ZONE}&from=${from}&to=${to}&record_quant=${recordQuant}&record_unit=${recordUnit}&mode=${mode}`
            );
        } else if (summaryType === SummaryType.JOB) {
            if (jobConfigId === '') {
                setInputEmpty(true);
                return;
            }
            history.push(
                `/Summary?summary_type=${SummaryType.JOB}&from=${from}&to=${to}&record_quant=${recordQuant}&record_unit=${recordUnit}&job_config_id=${jobConfigId}&mode=${mode}`
            );
        } else if (summaryType === SummaryType.DOMAIN) {
            if (domain === '') {
                setInputEmpty(true);
                return;
            }
            history.push(
                `/Summary?summary_type=${SummaryType.DOMAIN}&from=${from}&to=${to}&record_quant=${recordQuant}&record_unit=${recordUnit}&domain=${domain}&mode=${mode}`
            );
        } else if (summaryType === SummaryType.AGENT) {
            if (agentId === '') {
                setInputEmpty(true);
                return;
            }
            history.push(
                `/Summary?summary_type=${SummaryType.AGENT}&from=${from}&to=${to}&record_quant=${recordQuant}&record_unit=${recordUnit}&agent_id=${agentId}&mode=${mode}`
            );
        } else {
            throw Error(`Invalid summary type`);
        }
        setInputEmpty(false);
        renderWithData();
    };

    const _typeChangedHandler = (summaryType: string) => {
        setSummaryType(summaryType);
        setJobConfigId('');
        setAgentId('');
        setDomain('');
        setInputEmpty(false);
    };

    const _unitChangedHandler = (recordQuant: string, recordUnit: string) => {
        setRecordQuant(recordQuant);
        setRecordUnit(recordUnit);
    };

    const _modeChangedHandler = (mode: string) => {
        setMode(mode);
    };

    const _fromChangedHandler = (from: string) => {
        setFrom(from);
    };

    const _jobConfigIdChangedHandler = (jobConfigId: string) => {
        setJobConfigId(jobConfigId);
    };

    const _domainChangedHandler = (domain: string) => {
        setDomain(domain);
    };

    const _agentIdChangedHandler = (agentId: string) => {
        setAgentId(agentId);
    };

    return (
        <Content subtitle="Summary">
            <Toast
                open={!!errorMessage}
                message={errorMessage}
                severity="error"
                position={ToastPosition.TOP_CENTER}
                onClose={() => setErrorMessage('')}
            />

            <Box className={classes.root}>
                <Box className={classes.filters}>
                    <SummaryFilters
                        summaryType={summaryType}
                        recordQuant={recordQuant}
                        recordUnit={recordUnit}
                        mode={mode}
                        from={from}
                        to={to}
                        jobConfigId={jobConfigId}
                        domain={domain}
                        agentId={agentId}
                        inputEmpty={inputEmpty}
                        loadBtnClickHandler={() => _loadBtnClickHandler()}
                        typeChangedHandler={(summaryType: string) => _typeChangedHandler(summaryType)}
                        unitChangedHandler={(recordQuant: string, recordUnit: string) =>
                            _unitChangedHandler(recordQuant, recordUnit)
                        }
                        modeChangedHandler={(mode: string) => _modeChangedHandler(mode)}
                        fromChangedHandler={(from: string) => _fromChangedHandler(from)}
                        jobConfigIdChangedHandler={(jobConfigId: string) => _jobConfigIdChangedHandler(jobConfigId)}
                        domainChangedHandler={(domain: string) => _domainChangedHandler(domain)}
                        agentIdChangedHandler={(agentId: string) => _agentIdChangedHandler(agentId)}
                    />
                </Box>

                {isLoading && <LinearProgress />}

                {summaries?.length > 0 ? (
                    <>
                        <Box display="flex" className={classes.graph}>
                            <SummaryGraph
                                summaries={visibleSummaries}
                                field={cardField}
                                color={cardColor}
                                fieldAlias={cardFieldAlias}
                            />
                            <Box className={classes.horizSpacer} />
                            <SummaryCards summaries={visibleSummaries} cardSelectedHandler={cardSelectedHandler} />
                        </Box>
                        <Box flexGrow={1}>
                            <SummaryTable summaries={summaries} tableFilterChangedHandler={tableFilterChangedHandler} />
                        </Box>
                    </>
                ) : (
                    <Box fontStyle="italic" className={classes.message}>
                        <Typography variant="h5" color="textSecondary">
                            No summary data found for these parameters
                        </Typography>
                    </Box>
                )}
            </Box>
        </Content>
    );
};

export default withRouter(Summary);
