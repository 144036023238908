import React from 'react';
import { Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Content from '../components/Content';
import { CrwlIcon } from '@webacq/wam-ui-components';
import { Link } from 'react-router-dom';
import { getSearchRoute } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
    prompt: {
        fontSize: 42,
        margin: theme.spacing(6),
    },
}));

const NotFound = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Content>
            <Box className={classes.prompt} display="flex">
                <CrwlIcon fontSize="inherit" style={{ verticalAlign: 'text-top', marginRight: '0.3em' }} />
                <Box>
                    {'_> The requested page does not exist.'}
                    <br />
                    <br />
                    <Box fontSize="60%">
                        You can check out our &nbsp;
                        <Link to={getSearchRoute('')}>Homepage</Link>
                        &nbsp; instead.
                    </Box>
                </Box>
            </Box>
        </Content>
    );
};

export default NotFound;
