/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Checkbox, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { JobSummary } from '@webacq/wa-shared-definitions';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    TooltipProps,
    Tooltip as ReChartTooltip,
    XAxis,
} from 'recharts';
import { formatTimestamp } from '../../utils';
import { SummaryColors } from '../../shared/constants';
import CardTitle from './CardTitle';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { MRT_Cell } from 'material-react-table';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        opacity: '90%',
    },
}));

interface CrawlingHistoryCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    summary?: JobSummary[];
}

const FieldMappings = {
    avgDuration: {
        name: 'Avg. Duration',
        formatter: (value: number): string => `${value.toFixed(1)} ms`,
    },
    'metadata.numErrors': { name: 'Errors' },
    'metadata.numUrlSeen': { name: 'URLs Seen' },
    'metadata.numUrlFollowed': { name: 'URLs Followed' },
    'metadata.numDeliveries': { name: 'Deliveries' },
};

const CrawlingHistoryCard = (props: CrawlingHistoryCardProps): JSX.Element => {
    const classes = useStyles();

    const [showDurationInChart, setShowDurationInChart] = React.useState(true);
    const [showNumErrorsInChart, setShowNumErrorsInChart] = React.useState(true);
    const [showNumURLsSeenInChart, setShowNumURLsSeenInChart] = React.useState(true);
    const [showNumURLsFollowedInChart, setShowNumURLsFollowedInChart] = React.useState(true);
    const [showNumDeliveriesInChart, setShowNumDeliveriesInChart] = React.useState(true);

    const getAvgDuration = (rowData: JobSummary) => rowData.metadata.duration / rowData.metadata.numRuns;

    const makeNumberCell = (color: string) => {
        return function Cell({ cell }: { cell: MRT_Cell<JobSummary> }) {
            return (
                <Box
                    component="span"
                    sx={() => ({
                        textAlign: 'right',
                        color,
                    })}
                >
                    {cell.getValue<number>()}
                </Box>
            );
        };
    };

    const headerStyle = (color: string) => ({
        sx: {
            color,
        },
    });

    // const centerAlign = {
    const muiTableBodyCellProps = {
        style: { paddingLeft: '3em' },
    };

    const CheckableColumnHeader = (props: {
        label: string;
        checked: boolean;
        onCheckedChanged: (value: React.SetStateAction<boolean>) => void;
    }): JSX.Element => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                <Tooltip title="Show in Graph">
                    <Checkbox
                        size="small"
                        defaultChecked={props.checked}
                        style={{ padding: `0 6px` }}
                        onClick={(event) => {
                            event.stopPropagation();
                            props.onCheckedChanged((checked) => !checked);
                        }}
                    />
                </Tooltip>
                {props.label}
            </Box>
        );
    };

    const columns = [
        {
            header: 'Start',
            accessorKey: 'startTime',
            filterVariant: 'date',
            filterFn: 'greaterThan',
        },
        {
            header: 'End',
            accessorKey: 'endTime',
            filterVariant: 'date',
            filterFn: 'greaterThan',
        },
        {
            header: 'Job Mode',
            accessorKey: 'summaryType.job.mode',
        },
        {
            header: 'Run Location',
            accessorKey: 'summaryType.job.jobRunLocation',
        },
        {
            header: 'Runs',
            accessorKey: 'metadata.numRuns',
        },
        {
            header: (
                <CheckableColumnHeader
                    label="Avg. Duration"
                    checked={showDurationInChart}
                    onCheckedChanged={setShowDurationInChart}
                />
            ),
            accessorKey: 'metadata.duration',
            accessorFn: (row: JobSummary) => `${getAvgDuration(row).toFixed(1)} ms`,
            Cell: makeNumberCell(SummaryColors.Duration),
            muiTableHeadCellProps: headerStyle(SummaryColors.Duration),
            muiTableBodyCellProps,
            // customSort: (lhs, rhs) => {
            //     const lhsAvg = getAvgDuration(lhs);
            //     const rhsAvg = getAvgDuration(rhs);

            //     if (lhsAvg < rhsAvg) {
            //         return -1;
            //     } else if (lhsAvg > rhsAvg) {
            //         return 1;
            //     }
            //     return 0;
            // },
        },
        {
            header: (
                <CheckableColumnHeader
                    label="# Errors"
                    checked={showNumErrorsInChart}
                    onCheckedChanged={setShowNumErrorsInChart}
                />
            ),
            accessorKey: 'metadata.numErrors',
            Cell: makeNumberCell(SummaryColors.NumErrors),
            muiTableHeadCellProps: headerStyle(SummaryColors.NumErrors),
            muiTableBodyCellProps,
        },
        {
            header: (
                <CheckableColumnHeader
                    label="# URLs seen"
                    checked={showNumURLsSeenInChart}
                    onCheckedChanged={setShowNumURLsSeenInChart}
                />
            ),
            accessorKey: 'metadata.numUrlSeen',
            Cell: makeNumberCell(SummaryColors.NumUrlsSeen),
            muiTableHeadCellProps: headerStyle(SummaryColors.NumUrlsSeen),
            muiTableBodyCellProps,
        },
        {
            header: (
                <CheckableColumnHeader
                    label="# URLs followed"
                    checked={showNumURLsFollowedInChart}
                    onCheckedChanged={setShowNumURLsFollowedInChart}
                />
            ),
            accessorKey: 'metadata.numUrlFollowed',
            Cell: makeNumberCell(SummaryColors.NumUrlsFollowed),
            muiTableHeadCellProps: headerStyle(SummaryColors.NumUrlsFollowed),
            muiTableBodyCellProps,
        },
        {
            header: (
                <CheckableColumnHeader
                    label="# Deliveries"
                    checked={showNumDeliveriesInChart}
                    onCheckedChanged={setShowNumDeliveriesInChart}
                />
            ),
            accessorKey: 'metadata.numDeliveries',
            Cell: makeNumberCell(SummaryColors.NumDeliveries),
            muiTableHeadCellProps: headerStyle(SummaryColors.NumDeliveries),
            muiTableBodyCellProps,
        },
    ] as PaginatedTableColumn<JobSummary>[];

    const getChart = (summary: JobSummary[] | undefined) => {
        const compare = (lhs: JobSummary, rhs: JobSummary) => {
            if (lhs.startTime < rhs.startTime) {
                return -1;
            }
            if (lhs.startTime > rhs.startTime) {
                return 1;
            }
            return 0;
        };

        const datapoints = summary ? [...summary].sort(compare) : summary;

        return (
            <ResponsiveContainer width="100%" aspect={10.0 / 1.0}>
                <LineChart
                    data={
                        datapoints &&
                        datapoints.map((s) => ({
                            ...s,
                            startTime: formatTimestamp(s.startTime),
                            endTime: formatTimestamp(s.endTime),
                            avgDuration: s.metadata.duration / s.metadata.numRuns,
                        }))
                    }
                >
                    <XAxis dataKey="endTime" />

                    <ReChartTooltip content={<CustomTooltip />} />
                    <CartesianGrid strokeDasharray="1 3" stroke="grey" />
                    {showDurationInChart && (
                        <Line type="monotone" dataKey="avgDuration" stroke={SummaryColors.Duration} yAxisId={0} />
                    )}
                    {showNumErrorsInChart && (
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="metadata.numErrors"
                            stroke={SummaryColors.NumErrors}
                            yAxisId={1}
                        />
                    )}
                    {showNumURLsSeenInChart && (
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="metadata.numUrlSeen"
                            stroke={SummaryColors.NumUrlsSeen}
                            yAxisId={2}
                        />
                    )}
                    {showNumURLsFollowedInChart && (
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="metadata.numUrlFollowed"
                            stroke={SummaryColors.NumUrlsFollowed}
                            yAxisId={3}
                        />
                    )}
                    {showNumDeliveriesInChart && (
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="metadata.numDeliveries"
                            stroke={SummaryColors.NumDeliveries}
                            yAxisId={4}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        );
    };

    const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.tooltip}>
                    <Typography noWrap variant="body2" component="h2" gutterBottom>
                        {`${payload[0].payload.startTime} - ${payload[0].payload.endTime}`}
                    </Typography>
                    {payload.map((p, idx) => {
                        const fieldMapping = FieldMappings[p.dataKey || ''];
                        return (
                            <Typography key={idx} noWrap variant="body2" style={{ color: p.color }}>
                                {`${fieldMapping.name}: ${
                                    fieldMapping.formatter ? fieldMapping.formatter(p.value) : p.value
                                }`}
                            </Typography>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={
                <CardTitle
                    title="Crawling History"
                    recordCount={props.summary?.length || 0}
                    collapsedMode={props.collapsed}
                />
            }
        >
            {props.summary &&
                (props.summary.length > 0 ? (
                    <Box>
                        <PaginatedTable
                            columns={columns}
                            rows={props.summary}
                            initialSorting={[{ id: 'startTime', desc: true }]}
                        />
                        {getChart(props.summary)}
                    </Box>
                ) : (
                    <Box className={classes.contentBox} fontStyle="italic">
                        <Typography variant="body2">No crawling history found</Typography>
                    </Box>
                ))}
        </CollapsibleCard>
    );
};

export default CrawlingHistoryCard;
