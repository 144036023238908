/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Tooltip, Typography, Theme, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { PostProcessingSearchDoc, PostProcessingSearchDocRaw } from '@webacq/wa-shared-definitions';
import CardTitle from './CardTitle';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { addEllipsis, formatTimestamp, launchNSN, launchUrl } from '../../utils';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DocTypes, QueryStrings } from '../../shared/constants';
import { usePaginatedQuery } from '../PaginatedTable/usePaginatedQuery';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

interface PostProcessingCardProps {
    jobConfigId: string;
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    onUrlClick?: (url: string) => void;
}

const PostProcessingCard = (props: PostProcessingCardProps): JSX.Element => {
    const classes = useStyles();

    const [selectedJobMode] = useQueryParam(QueryStrings.JOB_MODE, withDefault(StringParam, ''));

    const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'storyAcqTime', desc: true }]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const query = `job_config_id_s:${props.jobConfigId} ${selectedJobMode ? 'AND job_mode_s:' + selectedJobMode : ''}`;

    const {
        data: rows,
        numFound,
        noDataAvailable,
        isBusy,
    } = usePaginatedQuery<PostProcessingSearchDocRaw, PostProcessingSearchDoc>(
        query,
        DocTypes.POST_PROCESSING,
        filters,
        sorting,
        pagination
    );

    const columns = [
        {
            header: 'SUID',
            accessorKey: 'storySuid',
            Cell: ({ row }) => {
                const storySuid = row.original.storySuid;
                return (
                    <Button size="small" color="primary" onClick={() => launchNSN(storySuid)}>
                        {storySuid}
                    </Button>
                );
            },
        },
        {
            header: 'Acquired at',
            accessorKey: 'storyAcqTime',
            filterVariant: 'date',
            accessorFn: (rowData: PostProcessingSearchDoc) => formatTimestamp(rowData.storyAcqTime),
        },
        {
            header: 'Story URL',
            accessorKey: 'storySourceUrl',
            Cell: ({ row }) => {
                const storySourceUrl = row.original.storySourceUrl;
                return (
                    <Tooltip title={storySourceUrl}>
                        <Link
                            className={classes.link}
                            onClick={(): void => {
                                launchUrl(storySourceUrl);
                            }}
                        >
                            {addEllipsis(storySourceUrl, 60)}
                        </Link>
                    </Tooltip>
                );
            },
        },
        {
            header: 'Headline',
            accessorKey: 'storyHeadline',
            Cell: ({ row }) => {
                const storyHeadline = row.original.storyHeadline;
                return (
                    <Tooltip title={storyHeadline}>
                        <span>{addEllipsis(storyHeadline, 60)}</span>
                    </Tooltip>
                );
            },
        },
        {
            header: 'Body',
            accessorKey: 'storyBody',
        },
    ] as PaginatedTableColumn<PostProcessingSearchDoc>[];

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Post-Processing" recordCount={numFound} collapsedMode={props.collapsed} />}
        >
            <Box hidden={noDataAvailable}>
                <PaginatedTable
                    columns={columns}
                    rows={rows}
                    totalCount={numFound}
                    filters={filters}
                    sorting={sorting}
                    pagination={pagination}
                    onUpdateFilters={(filters) => {
                        setFilters(filters);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdateSorting={(sorting) => {
                        setSorting(sorting);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdatePagination={setPagination}
                    controlled={true}
                    isBusy={isBusy}
                    hideLastPageButton
                />
            </Box>

            <Box hidden={!noDataAvailable} className={classes.contentBox} fontStyle="italic">
                <Typography variant="body2">No post-processing information found</Typography>
            </Box>
        </CollapsibleCard>
    );
};

export default PostProcessingCard;
