import React from 'react';
import { useAuth } from '../context/auth';
import { Redirect } from 'react-router-dom';

const Auth = (): JSX.Element => {
    const authentication = useAuth();

    React.useEffect(() => {
        authentication.setAuthData({ isAuthenticated: true });
    });

    return <Redirect to="/" />;
};

export default Auth;
