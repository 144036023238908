import React from 'react';
import { Theme, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    padding: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

interface ErrorMessageProps {
    message: string;
    onClose: () => void;
}

const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Alert severity="error" className={classes.padding} onClose={props.onClose}>
            {props.message}
        </Alert>
    );
};

export default ErrorMessage;
