import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import HighlightedElement from './HighlightedElement';

const useStyles = makeStyles((theme: Theme) => ({
    infoField: {
        marginRight: theme.spacing(6),
        display: 'flex',
        alignItems: "flex-end"
    },
    singleSpace: {
        marginLeft: theme.spacing(0.5),
    },
}));

export interface FieldProps {
    label?: string;
    value: string | React.ReactElement | undefined;
    searchKey?: string;
    nospace?: boolean;
}

interface InfoFieldProps {
    content: FieldProps | FieldProps[];
    highlightData?: HighlightRecord;
    fullWidth?: boolean;
}

const InfoField = (props: InfoFieldProps): JSX.Element => {
    const classes = useStyles();

    const content = Array.isArray(props.content) ? props.content : [props.content];

    return (
        <Box className={classes.infoField}>
            {content.map((field, idx) => (
                <>
                    {field.label && (
                        <Box fontWeight="bold" className={!field.nospace && idx > 0 ? classes.singleSpace : ''}>
                            {field.label}
                        </Box>
                    )}
                    <Box className={!field.nospace ? classes.singleSpace : ''}>
                        {field.searchKey && typeof field.value === 'string' ? (
                            <HighlightedElement
                                value={field.value}
                                fieldName={field.searchKey}
                                highlightData={props.highlightData}
                            />
                        ) : (
                            field.value
                        )}
                    </Box>
                </>
            ))}
        </Box>
    );
};

export default InfoField;
