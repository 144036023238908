import React, { useEffect, useState, useCallback } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import { RunTree, FullTreeIcon, BranchIcon } from '@webacq/wam-ui-components';
import Content from '../components/Content';
import searchService from '../services/searchService';
import { getJobConfigRoute, getJobRunRoute, wrapApiCall } from '../utils';
import { useAuth } from '../context/auth';
import {
    GeckoContextTree,
    GeckoModel,
    JobConfigSearchDocRaw,
    JobConfigSearchDoc,
    ProvenanceRecord,
    JobDeliverySearchDocRaw,
    JobDeliverySearchDoc,
} from '@webacq/wa-shared-definitions';
import wamService from '../services/wamService';
import { Alert, Button, LinearProgress, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { config } from '../config';

const useStyles = makeStyles((theme: Theme) => ({
    horizSpacer: {
        marginLeft: theme.spacing(2),
    },
}));

interface URLParams {
    id: string;
}

const JobRunInfo = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [geckoGraph, setGeckoGraph] = useState<GeckoModel | null>(null);
    const [contextTree, setContextTree] = useState<GeckoContextTree | null>(null);
    const [provRecords, setProvRecords] = useState<ProvenanceRecord[]>([]);
    const [jobConfigId, setJobConfigId] = useState('');
    const [jobConfigName, setJobConfigName] = useState('');
    const [runId, setRunId] = useState('');
    const [isBranch, setIsBranch] = useState(false);
    const [showCompleteTreeBtn, setShowCompleteTreeBtn] = useState(false);

    const id = props.match.params.id.split(':')[0];

    const authContext = useAuth();

    const checkIfCompleteTreeExists = async (runId: string) => {
        try {
            const result = await searchService.search<JobDeliverySearchDocRaw, JobDeliverySearchDoc>(
                `run_id_s:${runId} AND is_complete_b:true`,
                'JOB_DLV',
                1
            );
            const doc = result?.data?.docs.length ? result.data.docs[0] : undefined;
            if (doc?.ctxTreeLoc) {
                setShowCompleteTreeBtn(true);
                return;
            }
        } catch(e) {
            console.error(e);
        }
    };

    useEffect(() => {
        wrapApiCall(
            authContext,
            async () => {
                setIsLoading(true);

                const query =
                    uuidValidate(id) && uuidVersion(id) === 4
                        ? `run_id_s:${id} AND is_complete_b:true`
                        : `bbds_tran_id_s:${id}`;

                const result = await searchService.search<JobDeliverySearchDocRaw, JobDeliverySearchDoc>(
                    query,
                    'JOB_DLV',
                    1
                );

                const doc = result?.data?.docs.length ? result.data.docs[0] : undefined;
                if (!doc || !doc.ctxTreeLoc) {
                    setErrorMessage('Could not find context tree.');
                    return;
                }

                const ctxTree = await wamService.fetchBcosContent(doc.ctxTreeLoc).then(
                    (res) => res?.content as GeckoContextTree
                );
                if (!ctxTree) {
                    setErrorMessage(`Could not load context tree from BCOS, BCOS url: ${doc.ctxTreeLoc}`);
                    return;
                }

                const configId = doc.jobConfigId;
                const configName = doc.jobConfigName || '';
                const rootStepId = doc.provRootStepid;
                const jobStartTime = doc.jobStartTime;

                if (!configId || !rootStepId || !jobStartTime) {
                    setErrorMessage('Could not load data fields.');
                    return;
                }

                setJobConfigId(configId);
                setJobConfigName(configName);
                setIsBranch(!doc.isComplete);
                setRunId(doc.runId || '');

                try {
                    const children = [ctxTree];
                    while (children.length) {
                        const node = children.pop();
                        if (node?.['body'] && typeof node['body'] === 'string') {
                            node['body'] = JSON.parse(node['body']);
                        }
                        if (node?.children) {
                            children.push(...node.children);
                        }
                    }
                    setContextTree(ctxTree);
                } catch (e) {
                    console.error('Error parsing contextTree result', e);
                    setContextTree(null);
                }

                const jobConfigResult = await searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                    configId,
                    'JOB_CFG',
                    1
                );
                if (!jobConfigResult.data?.docs.length) {
                    setErrorMessage(`No data found for job config id ${configId}`);
                    return;
                }

                try {
                    const parsed = JSON.parse(jobConfigResult.data.docs[0].graph);
                    setGeckoGraph(parsed as GeckoModel);
                } catch (e) {
                    console.error(`Error parsing gecko graph for ${configId}`);
                    setGeckoGraph(null);
                }

                const records = await getProvenanceRecords(rootStepId, jobStartTime);
                setProvRecords(records);
                setErrorMessage('');

                if (!doc.isComplete && doc.runId) {
                    // Don't await this, it can be done in the background
                    checkIfCompleteTreeExists(doc.runId)
                }
            },
            (e: Error) => {
                setErrorMessage('Error loading data');
                setContextTree(null);
                setGeckoGraph(null);
                setProvRecords([]);
                console.error(e);
            },
            () => setIsLoading(false)
        );
    }, [id]);

    const getProvenanceRecords = useCallback(
        async (rootStepId: string, jobStartTime: Date): Promise<ProvenanceRecord[]> => {
            try {
                const start = jobStartTime.toISOString();
                const resp = await wamService.queryProvenance({ rootStepId, start });
                return resp.records;
            } catch (e) {
                console.error(e);
            }
            return [];
        },
        []
    );

    const subtitle = (
        <>
            Run Tree:
            {contextTree && geckoGraph && (
                <>
                    <span className={classes.horizSpacer} />
                    <Tooltip title="View job config details">
                        <Link to={getJobConfigRoute(jobConfigId)}>{jobConfigName || jobConfigId}</Link>
                    </Tooltip>
                    <span className={classes.horizSpacer} />
                    <span>
                        {isBranch ? (
                            <>
                                <Tooltip title="Branch Delivery">
                                    <span style={{ verticalAlign: 'bottom' }}>
                                        <BranchIcon />
                                    </span>
                                </Tooltip>
                                <span className={classes.horizSpacer} />
                                {showCompleteTreeBtn && (
                                    <Button
                                        variant="outlined"
                                        endIcon={<FullTreeIcon />}
                                        onClick={() => history.push(getJobRunRoute(runId))}
                                    >
                                        Switch to Complete Tree
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Tooltip title="Complete Run">
                                <span>
                                    <FullTreeIcon />
                                </span>
                            </Tooltip>
                        )}
                    </span>
                </>
            )}
        </>
    );

    return (
        <Content subtitle={subtitle} quickLinkTail={`/R ${id}`}>
            {isLoading && <LinearProgress />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {contextTree && geckoGraph && (
                <RunTree
                    contextTree={contextTree as GeckoContextTree}
                    geckoGraph={geckoGraph}
                    provenanceRecords={provRecords}
                    onFetchContent={wamService.fetchBcosContent}
                    getBcosEndpoint={(bcosUrl: string) =>
                        `${config.apiUrl}/wam/ui/object?url=${window.encodeURIComponent(bcosUrl)}`
                    }
                />
            )}
        </Content>
    );
};

export default JobRunInfo;
